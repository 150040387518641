import React, {useContext, useRef, useState} from 'react';

//components
import UserContext from "../../common/UserContext";
import {ColumnMenu, NoWrapCell} from "../../common/Grid";
import GenericSelectSortGrid from "../../common/AssignHost/GenericSelectSortGrid";

//multilingual
import {
    descriptionKey,
    mainMessages,
    productKey,
    qtyKey,
} from "../../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";

//kendo
import {GridColumn} from "@progress/kendo-react-grid";

export default function UnassignedProductsGrid(props) {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const {
        unassigned,
        setUnassigned,
        sortByDisableValue,
        selectHeaderCheck,
        setSelectHeaderCheck,
        enableSortByDisable,
        setEnableSortByDisable,
    } = props

    const uniqueID = 'license_id'
    const sortByDisableID = 'host_id_type'

    //States and functions for pagination
    const initialGridState = {
        take: 10, skip: 0,
    };
    const [gridState, setGridState] = useState(initialGridState);
    const gridStateRef = useRef(initialGridState)

    return (
        <GenericSelectSortGrid
            className={'unassigned-transport-grid'}
            items={unassigned}
            setItems={setUnassigned}
            uniqueID={uniqueID}
            initialGridState={initialGridState}
            gridState={gridState}
            setGridState={setGridState}
            gridStateRef={gridStateRef}
            selectHeaderCheck={selectHeaderCheck}
            setSelectHeaderCheck={setSelectHeaderCheck}
            sortByDisableID={sortByDisableID}
            sortByDisableValue={sortByDisableValue}
            enableSortByDisable={enableSortByDisable}
            setEnableSortByDisable={setEnableSortByDisable}
        >
            <GridColumn
                field="prod_num"
                title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                columnMenu={ColumnMenu}
                cell={NoWrapCell}
            />
            <GridColumn
                field="prod_desc"
                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                columnMenu={ColumnMenu}
            />
            <GridColumn
                field="qty_redeemed"
                title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                columnMenu={ColumnMenu}
                filter={'numeric'}
            />
        </GenericSelectSortGrid>
)
}