import React, {useCallback, useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext.js';

// react router
import {useHistory} from 'react-router-dom';

// css
import '../../assets/css/ViewExpiringLicenseSupport.css';

// components
import {ColumnMenu, DateIsExpiredCell, TextAlignMiddleCell, TextCapitalizeCell} from '../common/Grid.js';

// kendo react
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {DatePickerPropsContext} from '@progress/kendo-react-dateinputs';
import {process} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    activationCodeKey,
    daysLeftKey,
    descriptionKey,
    hostIdKey,
    licenseExpirationDateKey,
    licenseTypeKey,
    mainMessages, noDataAvailableKey,
    orderCertificateKey,
    productKey,
    qtyKey,
    supportExpirationDateKey,
    termKey
} from '../../assets/text/MultilingualText.js';

// consts for expiring license support grid
const initialGridState = {
    skip: 0,
    take: 20,
    sort: [
        {
            field: 'days_left',
            dir: 'desc'
        },
        {
            field: 'license_expiration_date',
            dir: 'desc'
        },
        {
            field: 'support_expiration_date',
            dir: 'desc'
        }
    ]
};
const ExpiringLicenseSupportGrid = (props) => {
    const {licenseSupports} = props;
    const {siteLanguageDefault} = useContext(UserContext)
    const localization = useLocalization();
    let history = useHistory();

    // product subscription
    const ProductSubscriptionCell = (props) => {
        let field = props.dataItem[props.field].split("\n");
        let productNumber = field[0];
        let subscription = field[1];
        return (
            <td>
                {productNumber ? productNumber : null}
                {subscription ? <>
                    <br/>
                    {subscription}
                </> : null}
            </td>
        );
    };

    // order number certificate cell
    const OrderNumberCertificateCell = (props) => {
        let orderId = props.dataItem.order_id;
        let orderNumber = props.dataItem.order_number;
        let certNumber = props.dataItem.o_certificate_id;
        let disabled = !orderId && !orderNumber && !certNumber;

        /*
         * onOrderNumberCertificateClick() navigates to the view certificate page
        */
        const onOrderNumberCertificateClick = () => {
            if (!disabled) {
                let params = '?orderId=' + orderId + '&orderNumber=' + orderNumber + '&certNumber=' + certNumber;
                history.push('/view-certificate' + params);
            }
        }

        return (
            <td>
               <span
                   className={!disabled ? "order-number-certificate" : ""}
                   onClick={onOrderNumberCertificateClick}
               >
                   {props.dataItem[props.field]}
               </span>
            </td>
        )
    }

    const [gridState, setGridState] = useState(initialGridState);
    const [dataState, setDataState] = useState(
        process(
            licenseSupports.map((item) => ({...item,})),
            initialGridState)
    );

    const onDataStateChange = useCallback((event) => {
        const newDataState = process(
            licenseSupports.map((item) => ({...item})),
            event.dataState
        );
        setGridState(event.dataState);
        setDataState(newDataState);
    }, [licenseSupports]);

    useEffect(() => {
        const newDataState = process(
            licenseSupports.map((item) => ({...item})),
            gridState
        );
        setDataState(newDataState);
    }, [licenseSupports]); // eslint-disable-line react-hooks/exhaustive-deps

    // initialize expiring license supports table
    useEffect(() => {
        if (licenseSupports.length) {
            setDataState(process(licenseSupports, gridState));
        } else {
            setDataState(process([], gridState));
        }
    }, [licenseSupports]); // eslint-disable-line react-hooks/exhaustive-deps

    const dateFilterFormat = useCallback(
        (calendarProps) => ({
            ...calendarProps,
            format: 'dd-MMM-yyyy',
        }),
        []
    );

    return (
        <DatePickerPropsContext.Provider value={dateFilterFormat}>
            <Grid
                className={'expiring-license-supports-grid'}
                scrollable={"none"}
                sortable={true}
                pageable={(licenseSupports.length <= initialGridState.take) ? false : {
                    buttonCount: 5, pageSizes: [10, 20, 50, 100],
                }}
                total={dataState.total}
                data={dataState}
                onDataStateChange={onDataStateChange}
                {...gridState}
            >
                <GridNoRecords>
                    {localization.toLanguageString(noDataAvailableKey, mainMessages[siteLanguageDefault][noDataAvailableKey])}
                </GridNoRecords>
                <GridColumn
                    field="product_number_subscription"
                    title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                    columnMenu={ColumnMenu}
                    cell={ProductSubscriptionCell}
                />
                <GridColumn
                    field="activation_code"
                    title={localization.toLanguageString(activationCodeKey, mainMessages[siteLanguageDefault][activationCodeKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="description"
                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="license_term"
                    title={localization.toLanguageString(termKey, mainMessages[siteLanguageDefault][termKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="license_type"
                    title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                    columnMenu={ColumnMenu}
                    cell={TextCapitalizeCell}
                />
                <GridColumn
                    field="qty"
                    title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                    columnMenu={ColumnMenu}
                    filter="numeric"
                    cell={TextAlignMiddleCell}
                />
                <GridColumn
                    field="license_expiration_date"
                    title={localization.toLanguageString(licenseExpirationDateKey, mainMessages[siteLanguageDefault][licenseExpirationDateKey])}
                    columnMenu={ColumnMenu}
                    filter="date"
                    cell={DateIsExpiredCell}
                />
                <GridColumn
                    field="support_expiration_date"
                    title={localization.toLanguageString(supportExpirationDateKey, mainMessages[siteLanguageDefault][supportExpirationDateKey])}
                    columnMenu={ColumnMenu}
                    filter="date"
                    cell={DateIsExpiredCell}
                />
                <GridColumn
                    field="days_left"
                    title={localization.toLanguageString(daysLeftKey, mainMessages[siteLanguageDefault][daysLeftKey])}
                    columnMenu={ColumnMenu}
                    filter="numeric"
                    cell={TextAlignMiddleCell}
                />
                <GridColumn
                    field="host_id"
                    title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="order_number_certificate"
                    title={localization.toLanguageString(orderCertificateKey, mainMessages[siteLanguageDefault][orderCertificateKey])}
                    columnMenu={ColumnMenu}
                    cell={OrderNumberCertificateCell}
                />
            </Grid>
        </DatePickerPropsContext.Provider>
    )
}

export default ExpiringLicenseSupportGrid;