import React, {useContext, useState, useEffect} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// react router
import {useHistory} from 'react-router-dom';

// components
import {
    ColumnMenu,
    DateIsExpiredCell,
    NoWrapCell,
    DisableRowRender,
    TextAlignMiddleCell,
    TextCapitalizeCell
} from '../common/Grid.js';
import Spinner from '../common/Spinner.js';
import {downloadFile} from '../common/utilities.js';
import {ActionIconCell, InfoIconCell, HostOrderNumberCel, PurchaseOrderNumberCell} from "./Cells";

// kendo react
import {Grid, GridColumn as Column, GridNoRecords} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';
import {
    plusIcon,
    minusIcon,
    chevronDownIcon,
    chevronUpIcon,
} from '@progress/kendo-svg-icons';
import { IconsContext } from '@progress/kendo-react-common';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    certificateKey,
    hostIdKey,
    availableLicenseBrowserKey,
    cloudLicenseKey,
    licenseExpKey,
    licKey,
    downloadLicenseKey,
    orderNumberKey,
    requestAvailableLicensesOrderKey,
    requestAvailableLicensesProductKey,
    poNumberKey,
    qtyKey,
    supportExpKey,
    assignMoreHostsKey,
    descriptionKey,
    licenseTypeKey,
    productNumberKey,
    qtyAssignedKey,
    qtyUnassignedKey,
    termKey,
    loadingPeriodsKey,
    genericEmptyGridKey,
    mainMessages
} from '../../assets/text/MultilingualText.js';
import {DownloadIcon, HyperLinkIcon} from "../common/icons";

const initialGridState = {
    take: 20,
    skip: 0,
};

export default function ViewByProduct(props) {
    const {
        productsData,
        eViewTransactionID,
        searchOption,
        searchValue
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const history = useHistory();
    const [loading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [hostsData, setHostsData] = useState([]);
    const [isHostLoading, setIsHostLoading] = useState(false);
    const [productGridState, setProductGridState] = useState(initialGridState);
    const [selectedProduct, setSelectedProduct] = useState();
    const [hostGridState, setHostGridState] = useState({});
    const [productState, setProductState] = useState(
        process(productsData, initialGridState)
    );
    const [hostState, setHostState] = useState(
        process(hostsData, {})
    );

    // products localization labels 
    const viewByProductProductTitle = localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey]);
    const viewByproductDescTitle = localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey]);
    const viewByProductTermTitle = localization.toLanguageString(termKey, mainMessages[siteLanguageDefault][termKey]);
    const viewByProductQTYAssignedTitle = localization.toLanguageString(qtyAssignedKey, mainMessages[siteLanguageDefault][qtyAssignedKey]);
    const viewByProductQTYUNAssignedTitle = localization.toLanguageString(qtyUnassignedKey, mainMessages[siteLanguageDefault][qtyUnassignedKey]);
    const viewByProductLicensTitle = localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey]);
    const viewByProductAssignMoreHostTitle = localization.toLanguageString(assignMoreHostsKey, mainMessages[siteLanguageDefault][assignMoreHostsKey]);
    const viewByProductAssignMoreHostToolTipTitle = localization.toLanguageString(requestAvailableLicensesProductKey, mainMessages[siteLanguageDefault][requestAvailableLicensesProductKey]);

    // host localization labels
    const viewByHostIdTitle = localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey]);
    const viewByHostQtyTitle = localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey]);
    const viewByHostLicenseExpTitle = localization.toLanguageString(licenseExpKey, mainMessages[siteLanguageDefault][licenseExpKey]);
    const viewByHostSupportExpTitle = localization.toLanguageString(supportExpKey, mainMessages[siteLanguageDefault][supportExpKey]);
    const viewByHostOrderNumberTitle = localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey]);
    const viewByHostCertificateTitle = localization.toLanguageString(certificateKey, mainMessages[siteLanguageDefault][certificateKey]);
    const viewByHostPurchaseOrderTitle = localization.toLanguageString(poNumberKey, mainMessages[siteLanguageDefault][poNumberKey]);
    const viewByHostLicenseTitle = localization.toLanguageString(licKey, mainMessages[siteLanguageDefault][licKey]);
    const viewByHostLicenseTooltipTitle = localization.toLanguageString(downloadLicenseKey, mainMessages[siteLanguageDefault][downloadLicenseKey]);
    const viewByHostInfoTooltipTitle = localization.toLanguageString(cloudLicenseKey, mainMessages[siteLanguageDefault][cloudLicenseKey]);
    const viewByHostInfoTooltipSubTitle = localization.toLanguageString(availableLicenseBrowserKey, mainMessages[siteLanguageDefault][availableLicenseBrowserKey]);
    const viewByHostOrderNumberTooltioTitle = localization.toLanguageString(requestAvailableLicensesOrderKey, mainMessages[siteLanguageDefault][requestAvailableLicensesOrderKey]);
    const defaultGridMessage = localization.toLanguageString(genericEmptyGridKey, mainMessages[siteLanguageDefault][genericEmptyGridKey]);
    const defaultLoadingMessage = localization.toLanguageString(loadingPeriodsKey, mainMessages[siteLanguageDefault][loadingPeriodsKey]);

    const filterHostData = (hosts) => {
        return hosts?.map((host) => {
            if (host.license_expiration_date) {
                host.license_expiration_date_display = new Date(host.license_expiration_date.replace(/-/g, "/"));
            }
            if (host.support_expiration_date) {
                host.support_expiration_date_display = new Date(host.support_expiration_date.replace(/-/g, "/"));
            }
            return host;
        })
    }

    // get hosts data for inner grid based on product id
    function getHostsByProduct(id, term) {
        setIsHostLoading(true);
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let data = {
            transaction_id: eViewTransactionID,
            product_id: id,
            license_term: term,
            product_number: "",
            product_desc: "",
            order_number: "",
            po_number: ""
        };
        data[searchOption['id']] = searchValue.trim()

        axios.post(
            config.view_by_product.PRODUCT_ID_SUMMARY_LIST,
            data,
            {headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = filterHostData(response.data || [])
                    setHostsData(data);
                    setHostState(process(data, {}));
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to  Data", error);
            })
            .finally(() => {
                setIsHostLoading(false);
            });
    }

    //handle download license file
    async function handleDownloadLicense(host) {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let payload = {
            "product_id": selectedProduct?.product_id,
            "host_id": host?.host_id,
            "license_expiration_date": host?.license_expiration_date,
            "support_expiration_date": host?.support_expiration_date,
            "order_id": host?.order_id,
            "order_number": host?.order_number,
            "o_certificate_id": host?.o_certificate_id,
            "purchase_order": host?.purchase_order
        };
        setDownloadLoading(true);
        axios.post(
            config.view_by_product.DOWNLOAD_LICENSE,
            {...payload},
            {headers}
        )
            .then(async ({data}) => {
                const TIMEOUT = 2000;
                let licenses = data?.license_file_links ?? [];
                if (licenses?.length) {
                    for (let idx = 0; idx < licenses?.length; idx++) {
                        try {
                            await downloadFile(licenses[idx]);
                            await new Promise((res) => setTimeout(() => {
                                res();
                            }, TIMEOUT));
                        } catch (error) {
                            alert('Error occured while download ..', licenses[idx]);
                        }
                    }
                }
            })
            .finally(() => {
                setDownloadLoading(false);
            });
    }

    // show action icon if unassigned quantity present
    const showActionIcon = (props) => {
        if (props?.dataItem?.qty_unassigned > 0 && props?.dataItem?.license_route_flag > 0) {
            return true;
        }
        return false;
    };

    // ensure cell expands when clicks on toggle
    const expandChange = (event) => {
        if (!event.dataItem.expanded) {
            setSelectedProduct(event.dataItem);
            setHostsData([]);
            setHostState(process([], {}));
            getHostsByProduct(event.dataItem.product_id, event.dataItem.license_term);
        }
        const filterProductState = productState?.data?.reduce((agg, cur) => {
            if (`${cur?.product_id}${cur?.license_term}` === `${event.dataItem.product_id}${event.dataItem.license_term}`) {
                return [
                    ...agg,
                    {
                        ...cur,
                        expanded: !event.dataItem.expanded
                    }
                ];
            } else {
                return [
                    ...agg,
                    {
                        ...cur,
                        expanded: false
                    }
                ];
            }
        }, []);
        setProductState({
            data: filterProductState,
            total: productState.total
        });
    };

    // sort hosts data
    const onHostStateChange = (event) => {
        // ensure host id type sort is priority
        if (event.dataState.sort === undefined) {
            event.dataState.sort = [];
        }
        event.dataState.sort.unshift({
            field: 'disabled',
            dir: 'asc'
        });

        const newHostState = process(
            hostsData,
            event.dataState
        );

        setHostGridState(event.dataState);
        setHostState(newHostState);
    };

    // sort producs data
    const onProductStateChange = (event) => {
        if (event.dataState.sort === undefined) {
            event.dataState.sort = [];
        }
        event.dataState.sort.unshift({
            field: 'disabled',
            dir: 'asc'
        });

        const newProductState = process(
            productsData,
            event.dataState
        );

        setProductGridState(event.dataState);
        setProductState(newProductState);
    };


    // Inner Grid component view
    const HostDetailComponent = (props) => {
        return <>
            {loading && <Spinner/>}
            <div className='k-mt-4 k-mb-4 k-p-0'>
                <Grid
                    scrollable={"none"}
                    sortable={{
                        allowUnsort: true,
                        mode: "single",
                    }}
                    data={hostState}
                    onDataStateChange={onHostStateChange}
                    rowRender={DisableRowRender}
                    className='view-by-product-child-grid'
                    {...hostGridState}
                >
                    <GridNoRecords>
                        {isHostLoading ? defaultLoadingMessage : defaultGridMessage}
                    </GridNoRecords>
                    <Column
                        field="host_id"
                        title={viewByHostIdTitle}
                        columnMenu={ColumnMenu}
                        cell={NoWrapCell}
                        width="120px"
                    />
                    <Column
                        field="qty"
                        title={viewByHostQtyTitle}
                        columnMenu={ColumnMenu}
                        cell={TextAlignMiddleCell}
                        width="80px"
                        filter="numeric"
                    />
                    <Column
                        field="license_expiration_date_display"
                        title={viewByHostLicenseExpTitle}
                        columnMenu={ColumnMenu}
                        cell={DateIsExpiredCell}
                        width="150px"
                        filter="date"
                    />
                    <Column
                        field="support_expiration_date_display"
                        title={viewByHostSupportExpTitle}
                        cell={DateIsExpiredCell}
                        columnMenu={ColumnMenu}
                        width="150px"
                        filter="date"
                    />
                    <Column
                        field="order_number"
                        title={viewByHostOrderNumberTitle}
                        cell={(props) => <HostOrderNumberCel
                            Icon={HyperLinkIcon}
                            showActionIcon={props?.dataItem?.['license_route_flag'] > 0}
                            onHandleClick={() => {
                                history.push('/request-license?orderID=' + props.dataItem.order_id);
                            }}
                            truncate={true}
                            title={viewByHostOrderNumberTooltioTitle}
                            {...props}
                        />}
                        width="230px"
                        columnMenu={ColumnMenu}
                    />
                    <Column
                        field="o_certificate_id"
                        title={viewByHostCertificateTitle}
                        columnMenu={ColumnMenu}
                        width="140px"
                    />
                    <Column
                        field="purchase_order"
                        title={viewByHostPurchaseOrderTitle}
                        columnMenu={ColumnMenu}
                        cell={(props) => <PurchaseOrderNumberCell
                            truncate={true}
                            {...props}
                        />}
                    />
                    <Column
                        field="license_file_id"
                        title={viewByHostLicenseTitle}
                        width="40px"
                        sortable={false}
                        cell={(props) => {
                            if (props.dataItem['license_type'] === 'CLOUD') {
                                return <InfoIconCell
                                    title={viewByHostInfoTooltipTitle}
                                    subtitle={viewByHostInfoTooltipSubTitle}
                                />;
                            }
                            return <ActionIconCell
                                Icon={DownloadIcon}
                                showActionIcon={props.dataItem['license_type']}
                                onHandleClick={async () => {
                                    await handleDownloadLicense(props.dataItem);
                                }}
                                title={viewByHostLicenseTooltipTitle}
                                {...props}
                            />;
                        }}
                    />
                </Grid>
            </div>
        </>
    };

    return (
        <>
            {downloadLoading && <Spinner/>}
            <IconsContext.Provider
                value={{
                    type: 'svg',
                    icons: {
                        [plusIcon.name]: chevronDownIcon,
                        [minusIcon.name]: chevronUpIcon,
                    },
                }}
            >
                <Grid
                    className='view-by-product-grid'
                    scrollable={"none"}
                    sortable={{
                        allowUnsort: true,
                        mode: "single"
                    }}
                    total={productState.total}
                    pageable={productsData?.length <= initialGridState.take ? false : {
                        info: true,
                        previousNext: true,
                        pageSizes: [10, 20, 50, 100]
                    }}
                    onDataStateChange={onProductStateChange}
                    rowRender={DisableRowRender}
                    data={productState}
                    detail={HostDetailComponent}
                    expandField="expanded"
                    onExpandChange={expandChange}
                    {...productGridState}
                >
                    <GridNoRecords>
                        {defaultGridMessage}
                    </GridNoRecords>
                    <Column
                        field="prod_num"
                        title={viewByProductProductTitle}
                        columnMenu={ColumnMenu}
                        cell={NoWrapCell}
                        width="158px"
                    />
                    <Column
                        field="description"
                        title={viewByproductDescTitle}
                        columnMenu={ColumnMenu}
                    />
                    <Column
                        field="license_term"
                        title={viewByProductTermTitle}
                        columnMenu={ColumnMenu}
                        width="110px"
                        cell={TextCapitalizeCell}
                    />
                    <Column
                        field="locking_type"
                        title={viewByProductLicensTitle}
                        columnMenu={ColumnMenu}
                        width="160px"
                        cell={TextCapitalizeCell}
                    />
                    <Column
                        field="qty_assigned"
                        title={viewByProductQTYAssignedTitle}
                        filter="numeric"
                        cell={TextAlignMiddleCell}
                        columnMenu={ColumnMenu}
                        width="138px"
                    />
                    <Column
                        field="qty_unassigned"
                        title={viewByProductQTYUNAssignedTitle}
                        cell={TextAlignMiddleCell}
                        columnMenu={ColumnMenu}
                        filter="numeric"
                        width="155px"
                    />
                    <Column
                        field="assign_more_hosts"
                        title={viewByProductAssignMoreHostTitle}
                        cell={(props) =>
                            <ActionIconCell
                                Icon={HyperLinkIcon}
                                showActionIcon={showActionIcon(props)}
                                onHandleClick={() => {
                                    history.push('/request-license?productID=' + props.dataItem.product_id);
                                }}
                                title={viewByProductAssignMoreHostToolTipTitle}
                                {...props}
                            />
                        }
                        sortable={false}
                        width="175px"
                    />
                </Grid>
            </IconsContext.Provider>
        </>
    );
}