export const contactUsKey = 'contactUsKey'
export const loginKey = 'loginKey';
export const registerKey = 'registerKey';
export const morePeriodsKey = 'morePeriodsKey';
export const welcomeKey = 'welcomeKey';
export const myProfileKey = 'myProfileKey';
export const logoutKey = 'logoutKey';
export const keysightSoftwareManagerKey = "keysightSoftwareManagerKey";
export const helpKey = "helpKey";
export const exploreKey = 'exploreKey';
export const productServicesKey = 'productServicesKey';
export const solutionsKey = 'solutionsKey';
export const industriesKey = 'industriesKey';
export const eventsKey = 'eventsKey';
export const keysightUniversityKey = 'keysightUniversityKey';
export const insightsKey = 'insightsKey';
export const discoverInsightsKey = 'discoverInsightsKey';
export const successStoriesKey = 'successStoriesKey';
export const resourcesKey = 'resourcesKey';
export const blogKey = 'blogKey';
export const communityKey = 'communityKey';
export const partnersKey = 'partnersKey';
export const supportKey = 'supportKey';
export const keysightProductSupportKey = 'keysightProductSupportKey';
export const ixiaProductSupportKey = 'ixiaProductSupportKey';
export const manageSoftwareLicensesKey = 'manageSoftwareLicensesKey';
export const productOrderStatusKey = 'productOrderStatusKey';
export const partsKey = 'partsKey';
export const aboutKeysightKey = 'aboutKeysightKey';
export const newsroomKey = 'newsroomKey';
export const investorRelationsKey = 'investorRelationsKey';
export const corporateSocialKey = 'corporateSocialKey';
export const diversityEquityKey = 'diversityEquityKey';
export const modernSlaveryActKey = 'modernSlaveryActKey';
export const careersKey = 'careersKey';
export const privacyKey = 'privacyKey';
export const termsKey = 'termsKey';
export const feedbackKey = 'feedbackKey';
export const aboutLicensingKey = 'aboutLicensingKey';
export const facebookLinkKey = 'facebookLinkKey';
export const twitterLinkKey = 'twitterLinkKey';
export const youtubeLinkKey = 'youtubeLinkKey';
export const facebookConnectKey = 'facebookConnectKey';
export const linkedInConnectKey = 'linkedInConnectKey';
export const twitterConnectKey = 'twitterConnectKey';
export const youtubeConnectKey = 'youtubeConnectKey';
export const weChatConnectKey = 'weChatConnectKey';
export const systemMaintenanceKey = 'systemMaintenanceKey';
export const downtimeTextKey = 'downtimeTextKey';
export const downtimeStartKey = 'downtimeStartKey';
export const downtimeEndKey = 'downtimeEndKey';
export const returningUsersKey = 'returningUsersKey';
export const pleaseLoginKey = 'pleaseLoginKey';
export const newUserKey = 'newUserKey';
export const firstTimeUsersKey = 'firstTimeUsersKey';
export const pleaseRegisterKey = 'pleaseRegisterKey';
export const quickReferenceGuideKey = 'quickReferenceGuideKey';
export const availableKey = 'availableKey';
export const newKey = 'newKey';
export const activeKey = 'activeKey';
export const expiredKey = 'expiredKey';
export const checkedOutKey = 'checkedOutKey';
export const entitlementManagementKey = 'entitlementManagementKey';
export const licenseManagementKey = 'licenseManagementKey';
export const softwareUpdatesKey = 'softwareUpdatesKey';
export const licensesAndHostsKey = 'licensesAndHostsKey';
export const softwareAccessLicensesKey = 'softwareAccessLicensesKey';
export const enterpriseAgreementsKey = 'enterpriseAgreementsKey';
export const addNewCertificatesKey = 'addNewCertificatesKey';
export const viewMyCertificatesKey = 'viewMyCertificatesKey';
export const shareMyCertificatesKey = 'shareMyCertificatesKey';
export const requestNewLicensesKey = 'requestNewLicensesKey';
export const deactivateLicensesKey = 'deactivateLicensesKey';
export const syncLicensesKey = 'syncLicensesKey';
export const renewNemoLicensesKey = 'renewNemoLicensesKey';
export const renewSoftwareSubscriptionsKey = 'renewSoftwareSubscriptionsKey';
export const renewEESOFLicensesKey = 'renewEESOFLicensesKey';
export const downloadSoftwareUpdatesKey = 'downloadSoftwareUpdatesKey';
export const viewByProductHostKey = 'viewByProductHostKey';
export const viewExpiringLicenseSupportKey = 'viewExpiringLicenseSupportKey';
export const manageHostsKey = 'manageHostsKey';
export const checkOutLicensesKey = 'checkOutLicensesKey';
export const checkInLicensesKey = 'checkInLicensesKey';
export const viewDownloadLicensesKey = 'viewDownloadLicensesKey';
export const softwareUsageReportKey = 'softwareUsageReportKey';
export const sWAccessLicensesKey = 'sWAccessLicensesKey';
export const manageEnterpriseAgreementsKey = 'manageEnterpriseAgreementsKey';
export const addNewCertificatesTooltipKey = 'addNewCertificatesTooltipKey';
export const viewCertificatesTooltipKey = 'viewCertificatesTooltipKey';
export const shareMyCertificatesTooltipKey = 'shareMyCertificatesTooltipKey';
export const requestNewLicensesTooltipKey = 'requestNewLicensesTooltipKey';
export const deactivateLicensesTooltipKey = 'deactivateLicensesTooltipKey';
export const syncLicensesTooltipKey = 'syncLicensesTooltipKey';
export const renewNemoLicensesTooltipKey = 'renewNemoLicensesTooltipKey';
export const renewSoftwareSubscriptionsTooltipKey = 'renewSoftwareSubscriptionsTooltipKey';
export const renewEESOFLicensesTooltipKey = 'renewEESOFLicensesTooltipKey';
export const downloadSoftwareUpdatesTooltipKey = 'downloadSoftwareUpdatesTooltipKey';
export const viewByProductHostTooltipKey = 'viewByProductHostTooltipKey';
export const viewExpiringLicenseSupportTooltipKey = 'viewExpiringLicenseSupportTooltipKey';
export const manageHostsTooltipKey = 'manageHostsTooltipKey';
export const checkOutLicensesTooltipKey = 'checkOutLicensesTooltipKey';
export const checkInLicensesTooltipKey = 'checkInLicensesTooltipKey';
export const viewDownloadLicensesTooltipKey = 'viewDownloadLicensesTooltipKey';
export const softwareUsageReportTooltipKey = 'softwareUsageReportTooltipKey';
export const sWAccessLicensesTooltipKey = 'sWAccessLicensesTooltipKey';
export const manageEnterpriseAgreementsTooltipKey = 'manageEnterpriseAgreementsTooltipKey';
export const redirectingKey = 'redirectingKey';
export const workingOnRequestKey = 'workingOnRequestKey';
export const pleaseWaitKey = 'pleaseWaitKey';
export const sessionExpiredKey = 'sessionExpiredKey';
export const refreshKey = 'refreshKey';
export const assignHostKey = 'assignHostKey';
export const productNumberKey = 'productNumberKey';
export const descriptionKey = 'descriptionKey';
export const qtyKey = 'qtyKey';
export const cancelChangesKey = 'cancelChangesKey';
export const saveHostIDKey = 'saveHostIDKey';
export const hostIdFormattingKey = 'hostIdFormattingKey';
export const serialNumberFormattingKey = 'serialNumberFormattingKey';
export const invalidHostKey = 'invalidHostKey';
export const invalidSerialNumberKey = 'invalidSerialNumberKey';
export const invalidFileKey = 'invalidFileKey';
export const reviewNumberHostKey = 'reviewNumberHostKey';
export const exceedingQtyKey = 'exceedingQtyKey';
export const assignProductsQtyDistributionMessageProductErrorKey = 'assignProductsQtyDistributionMessageProductErrorKey';
export const assignProductsQtyDistributionMessageHasErrorKey = 'assignProductsQtyDistributionMessageHasErrorKey';
export const assignProductsQtyDistributionMessageAvailableErrorKey = 'assignProductsQtyDistributionMessageAvailableErrorKey';
export const assignProductsQtyDistributionMessageEnableErrorKey = 'assignProductsQtyDistributionMessageEnableErrorKey';
export const assignProductsQtyDistributionMessageAppliedErrorKey = 'assignProductsQtyDistributionMessageAppliedErrorKey';
export const assignProductsQtyDistributionMessageHostsErrorKey = 'assignProductsQtyDistributionMessageHostsErrorKey';
export const invalidHostColonKey = 'invalidHostColonKey';
export const duplicateHostKey = 'duplicateHostKey';
export const assignProductDuplicateHostIDMessageErrorKey = 'assignProductDuplicateHostIDMessageErrorKey';
export const assignProductDuplicateHostUniqueMessageErrorKey = 'assignProductDuplicateHostUniqueMessageErrorKey';
export const assignProductsOnePerHostTitleProductErrorKey = 'assignProductsOnePerHostTitleProductErrorKey';
export const assignProductsOnePerHostTitleLimitErrorKey = 'assignProductsOnePerHostTitleLimitErrorKey';
export const onePerHostMessageKey = 'onePerHostMessageKey';
export const genericErrorTitleKey = 'genericErrorTitleKey';
export const assignProductsGenericMessagePleaseErrorKey = 'assignProductsGenericMessagePleaseErrorKey';
export const assignProductsGenericMessageIssueErrorKey = 'assignProductsGenericMessageIssueErrorKey';
export const specificHostFileKey = 'specificHostFileKey';
export const findViewCertKey = 'findViewCertKey';
export const myCertKey = 'myCertKey';
export const orderNumberKey = 'orderNumberKey';
export const purchaseOrderKey = 'purchaseOrderKey';
export const searchForKey = 'searchForKey';
export const exportAllExcelKey = 'exportAllExcelKey';
export const viewPdfKey = 'viewPdfKey';
export const certNumberKey = 'certNumberKey';
export const poNumberKey = 'poNumberKey';
export const productKey = 'productKey';
export const termKey = 'termKey';
export const startDateKey = 'startDateKey';
export const endDateKey = 'endDateKey';
export const qtyPurchasedKey = 'qtyPurchasedKey';
export const qtyRemainingKey = 'qtyRemainingKey';
export const contactUsHeaderKey = 'contactUsHeaderKey';
export const loadingPeriodsKey = 'loadingPeriodsKey';
export const genericEmptyGridKey = 'genericEmptyGridKey';
export const requestLicensesNowKey = 'requestLicensesNowKey';
export const noDataAvailableKey = "noDataAvailableKey";
export const addCertAccountKey = "addCertAccountKey";
export const findCertKey = "findCertKey";
export const orderCertificateDetailsKey = "orderCertificateDetailsKey";
export const addCertKey = "addCertKey";
export const addCertSuccessKey = "addCertSuccessKey";
export const clickRequestLicenseNowKey = "clickRequestLicenseNowKey";
export const enterValidCertKey = "enterValidCertKey";
export const validOrderProvidedKey = "validOrderProvidedKey";
export const certNotFoundKey = "certNotFoundKey";
export const confirmOrderKey = "confirmOrderKey";
export const certAssociatedKey = "certAssociatedKey";
export const certRedeemLicenseKey = "certRedeemLicenseKey";
export const certNoEntitlementKey = "certNoEntitlementKey";
export const certScalableKey = 'certScalableKey';
export const addCertEggplantFoundMessageKey1 = 'addCertEggplantFoundMessageKey1';
export const addCertEggplantFoundMessageKey2 = 'addCertEggplantFoundMessageKey2';
export const addCertEggplantFoundMessageKey3 = 'addCertEggplantFoundMessageKey3';
export const mailAllKey = "mailAllKey"
export const emailLicensesDirectionsKey = "emailLicensesDirectionsKey"
export const invalidEmailKey = "invalidEmailKey"
export const emailValidationMessageKey = "emailValidationMessageKey"
export const emailSuccessKey = "emailSuccessKey"
export const emailReceiveKey = "emailReceiveKey"
export const cancelKey = "cancelKey"
export const sendLicenseKey = "sendLicenseKey"
export const noLicensesRedeemKey = "noLicensesRedeemKey";
export const productsHostsKey = "productsHostsKey";
export const licensePreviewKey = "licensePreviewKey";
export const licenseDownloadKey = "licenseDownloadKey";
export const navigationAlertKey = "navigationAlertKey";
export const licenseExpirationDateKey = "licenseExpirationDateKey";
export const supportExpirationDateKey = "supportExpirationDateKey";
export const assignMoreProductsKey = "assignMoreProductsKey";
export const requestMoreKey = "requestMoreKey";
export const downloadAllLicensesKey = "downloadAllLicensesKey";
export const newLicensedProductsKey = "newLicensedProductsKey";
export const cloudLicenseKey = "cloudLicenseKey";
export const cloudLicenseMessageKey = "cloudLicenseMessageKey";
export const processingCompletedKey = "processingCompletedKey";
export const licensesReadyKey = "licensesReadyKey";
export const requestLicensesKey = "requestLicensesKey";
export const generateLicensesKey = "generateLicensesKey";
export const generateLicensesMessageKey = "generateLicensesMessageKey";
export const pleaseCancelKey = "pleaseCancelKey";
export const yesSureKey = "yesSureKey";
export const newlyAssignedProductsKey = "newlyAssignedProductsKey";
export const downloadLicensesKey = "downloadLicensesKey";
export const loadingGenerationKey = "loadingGenerationKey";
export const removeProductKey = "removeProductKey";
export const requestProcessingKey = "requestProcessingKey";
export const requestProcessingMessageKey = "requestProcessingMessageKey";
export const generationTimeoutKey = "generationTimeoutKey";
export const notGeneratedSupportAgentKey = "notGeneratedSupportAgentKey";
export const errorOccurredRequestKey = "errorOccurredRequestKey";
export const notGeneratedAvailableRedeemKey = "notGeneratedAvailableRedeemKey";
export const noQuantityKey = "noQuantityKey";
export const noQuantityMessageKey = "noQuantityMessageKey";
export const redeemNotAvailableErrorKey = "redeemNotAvailableErrorKey";
export const cannotGenerateKey = "cannotGenerateKey";
export const cannotGenerateMessageKey = "cannotGenerateMessageKey";
export const productsHostKey = "productsHostKey";
export const myProductsKey = "myProductsKey";
export const assignQtyKey = "assignQtyKey";
export const myAssignedProductsKey = "myAssignedProductsKey";
export const enterpriseLicensingKey = "enterpriseLicensingKey";
export const selectProductsKey = "selectProductsKey";
export const selectMoreKey = "selectMoreKey";
export const daysLeftKey = "daysLeftKey";
export const licenseTypeKey = "licenseTypeKey";
export const costPerUnitKey = "costPerUnitKey";
export const totalKey = "totalKey";
export const searchProductsKey = "searchProductsKey";
export const searchSoftwareFamilyKey = "searchSoftwareFamilyKey";
export const anyKey = "anyKey";
export const searchKey = "searchKey";
export const myCartKey = "myCartKey";
export const statusKey = "statusKey";
export const saveSelectionsKey = "saveSelectionsKey";
export const exportSelectionsKey = "exportSelectionsKey";
export const hostIdKey = "hostIdKey";
export const totalCostKey = "totalCostKey";
export const selectedItemCannotAddedKey = "selectedItemCannotAddedKey";
export const selectedItemCannotAddedMessageKey = "selectedItemCannotAddedMessageKey";
export const unassignedKey = "unassignedKey";
export const assignedKey = "assignedKey";
export const cannotBeProcessedKey = "cannotBeProcessedKey";
export const changeExceedsPoolKey = "changeExceedsPoolKey";
export const confirmDeletionKey = "confirmDeletionKey";
export const deleteUnassignedKey = "deleteUnassignedKey";
export const deleteAllKey = "deleteAllKey";
export const hostKey = "hostKey";
export const changeCannotSaveKey = "changeCannotSaveKey"
export const changeAnotherSessionKey = "changeAnotherSessionKey"
export const NoRecordsSearchKey = "NoRecordsSearchKey"
export const emptySearchKey = "emptySearchKey"
export const requestLicenseSummaryKey = "requestLicenseSummaryKey"
export const generationConfirmationKey = "generationConfirmationKey"
export const specialHandlingKey = "specialHandlingKey"
export const keysightGenerateLicensesContactKey = "keysightGenerateLicensesContactKey"
export const requestLicensesHostKey = "requestLicensesHostKey"
export const doNotRequestLicenseKey = "doNotRequestLicenseKey"
export const emailSelectedLicensesKey = "emailSelectedLicensesKey"
export const downloadSelectedLicensesKey = "downloadSelectedLicensesKey"
export const registerLoginEa = "registerLoginEa"
export const managePeriodicEa = "managePeriodicEa"
export const manageVariableEa = "manageVariableEa"
export const howToEA = "howToEA"
export const saveChangesKey = "saveChangesKey";
export const infoKey = "infoKey";
export const incompatibleProductsSelectedKey = "incompatibleProductsSelectedKey";
export const keysightGenerateLicensesV2Key = "keysightGenerateLicensesV2Key";
export const okKey = "okKey";
export const hostPendingSpecialKey = "hostPendingSpecialKey";
export const deleteProductHostKey = "deleteProductHostKey";
export const deleteHostKey = "deleteHostKey";
export const poolOverKey = "poolOverKey";
export const durationIncreaseCostKey = "elHostAssignmentCostErrorKey";
export const onePerHostKey = "onePerHostKey";
export const alreadyAssignedKey = "alreadyAssignedKey";
export const incorrectNumberImeiKey = "incorrectNumberImeiKey";
export const imeiAssignedQuantityKey = "imeiAssignedQuantityKey";
export const endDateExceedPoolKey = "endDateExceedPoolKey";
export const endDateNotPossibleKey = "endDateNotPossibleKey";
export const duplicateAliasKey = "duplicateAliasKey";
export const aliasUniqueKey = "aliasUniqueKey";
export const quantityConsumedKey = "quantityConsumedKey";
export const quantityConsumedSessionKey = "quantityConsumedSessionKey";
export const reassignLicensesKey = "reassignLicensesKey"
export const selectLicensesRenewKey = "selectLicensesRenewKey"
export const renewLicensesKey = "renewLicensesKey"
export const renewableLicensesKey = "renewableLicensesKey"
export const reassignBeforeProceedingKey = "reassignBeforeProceedingKey"
export const productDescriptionKey = "productDescriptionKey"
export const notesKey = "notesKey"
export const versionKey = "versionKey"
export const reassignKey = "reassignKey"
export const rehostThisLicenseKey = "rehostThisLicenseKey"
export const undoHostChangesKey = "undoHostChangesKey"
export const continueRenewLicensesKey = "continueRenewLicensesKey"
export const downloadThisLicenseKey = "downloadThisLicenseKey"
export const availableRenewKeys = "availableRenewKeys"
export const requestSuccessfullyKey = "requestSuccessfullyKey"
export const generatedRetrieveLicensesKey = "generatedRetrieveLicensesKey"
export const renewMoreLicencesKey = "renewMoreLicencesKey"
export const rehostedFromKey = "rehostedFromKey"
export const quantityToReassignKey = "quantityToReassignKey"
export const invalidKey = "invalidKey"
export const renewEesofOnePerHostErrorTitle = "renewEesofOnePerHostErrorTitle"
export const totalPoolKey = 'totalPoolKey'
export const consumedKey = 'consumedKey'
export const eaLicenseViewKey = "eaLicenseViewKey"
export const customerInformationKey = "customerInformationKey"
export const eaDetailsKey = "eaDetailsKey"
export const remixTypeKey = "remixTypeKey"
export const currencyKey = "currencyKey"
export const periodKey = "periodKey"
export const toKey = "toKey"
export const groupByHostIdKey = "groupByHostIdKey"
export const groupByProductsKey = "groupByProductsKey"
export const showExpiredLicensesKey = "showExpiredLicensesKey"
export const hostCostKey = "hostCostKey"
export const assignedProductsKey = "assignedProductsKey"
export const assignedHostsKey = "assignedHostsKey"
export const transportableLicensesSavedKey = "transportableLicensesSavedKey"
export const specialHandlingRequiredKey = "specialHandlingRequiredKey"
export const redeemTransportableLicensesKey = "redeemTransportableLicensesKey"
export const keysightGenerateLicensesKey = "keysightGenerateLicensesKey"
export const hostTotalKey = "hostTotalKey"
export const assignMoreLicensesKey = "assignMoreLicensesKey"
export const exportToExcelKey = "exportToExcelKey"
export const openKey = "openKey"
export const noLicensesPeriodKey = "noLicensesPeriodKey"
export const eARequestAgainKey = "eARequestAgainKey"
export const requestAgainLicenseViewKey = "requestAgainLicenseViewKey"
export const fromExpiryDateKey = "fromExpiryDateKey"
export const toExpiryDateKey = "toExpiryDateKey"
export const licensesExpireDateRangeKey = "licensesExpireDateRangeKey"
export const subscriptionPoolSelectedKey = "subscriptionPoolSelectedKey"
export const subscriptionPoolFreedKey = "subscriptionPoolFreedKey"
export const totalSubscriptionPoolAvailableKey = "totalSubscriptionPoolAvailableKey"
export const totalCostSelectedKey = "totalCostSelectedKey"
export const excessAmountKey = "excessAmountKey"
export const pleaseEditSelectedKey = "pleaseEditSelectedKey"
export const toRequestAgainKey = "toRequestAgainKey"
export const startDateSetKey = "startDateSetKey"
export const endDateSetKey = "endDateSetKey"
export const endDateQtyEditKey = "endDateQtyEditKey"
export const subscriptionPoolConsumeKey = "subscriptionPoolConsumeKey"
export const subscriptionPoolFrom = "subscriptionPoolFrom"
export const productNotAvailable = "productNotAvailable"
export const enterHostIdKey = "enterHostIdKey";
export const selectLicensesKey = "selectLicensesKey";
export const requestLicenseKey = "requestLicenseKey";
export const downloadAndConfirmKey = "downloadAndConfirmKey";
export const activationCodeKey = "activationCodeKey";
export const entitlementCodeKey = "entitlementCodeKey";
export const qtyDeactivateKey = "qtyDeactivateKey";
export const qtyCurrentlyAssignedKey = "qtyCurrentlyAssignedKey";
export const qtyKeepKey = "qtyKeepKey";
export const addARequestFileKey = "addARequestFileKey";
export const uploadFileDeactivationKey = "uploadFileDeactivationKey";
export const searchDeactivateKey = "searchDeactivateKey";
export const findHostKey = "findHostKey";
export const orKey = "orKey";
export const currentLicensesKey = "currentLicensesKey";
export const continueKey = "continueKey";
export const clearAllQuantitiesKey = "clearAllQuantitiesKey";
export const deactivateAllKey = "deactivateAllKey";
export const iAmDeactivatingKey = "iAmDeactivatingKey";
export const iAmKeepingKey = "iAmKeepingKey";
export const licenseGeneratingKey = "licenseGeneratingKey";
export const processNextStepKey = "processNextStepKey";
export const deactivationFailedKey = "deactivationFailedKey";
export const genericErrorContactUsKey = "genericErrorContactUsKey";
export const failedGenerationTimeKey = "failedGenerationTimeKey";
export const supportTeamDeactivationKey = "supportTeamDeactivationKey";
export const enterDeactiviationCodeKey = "enterDeactiviationCodeKey";
export const confirmDeactivationKey = "enterDeactiviationCodeKey";
export const confirmCodeRequiredKey = "confirmCodeRequiredKey";
export const confirmationCodeWarningKey = "confirmationCodeWarningKey";
export const incorrectConfirmationCodeKey = "incorrectConfirmationCodeKey";
export const confirmConfirmationCodeKey = "confirmConfirmationCodeKey";
export const deactivateMoreProductsKey = "deactivateMoreProductsKey";
export const deactivationCompleteKey = "deactivationCompleteKey";
export const reassignDeactivateKey = "reassignDeactivateKey";
export const binFileMessageKey = "binFileMessageKey";
export const licenseNoAssociationKey = "licenseNoAssociationKey";
export const addCertDeactivationKey = "addCertDeactivationKey";
export const noLicenseDeactivationKey = "noLicenseDeactivationKey";
export const noLicenseDeactivationMessageKey = "noLicenseDeactivationMessageKey";
export const deactivationNotSupportedKey = "deactivationNotSupportedKey";
export const hostNoExistKey = "hostNoExistKey";
export const confirmHostEnteredKey = "confirmHostEnteredKey";
export const nemoSupportRenewalKey = "nemoSupportRenewalKey";
export const uploadC2vKey = "uploadC2vKey";
export const requestRenewalKey = "requestRenewalKey";
export const addC2vFileKey = "addC2vFileKey";
export const pendingRenewalKey = "pendingRenewalKey";
export const c2vUploadAppliedKey = "c2vUploadAppliedKey";
export const currentSupportEndDate = "currentSupportEndDate";
export const newSupportEndDateKey = "newSupportEndDateKey";
export const newEndDateKey = "newEndDateKey";
export const validC2vKey = "validC2vKey";
export const noPendingRenewalsKey = "noPendingRenewalsKey";
export const renewNemoNoMatchPleaseProvideMessageErrorKey = "renewNemoNoMatchPleaseProvideMessageErrorKey";
export const renewNemoNoMatchFileMatchMessageErrorKey = "renewNemoNoMatchFileMatchMessageErrorKey";
export const generationTimeoutMessageV2Key = "generationTimeoutMessageV2Key";
export const licenseRenewedSuccessKey = "licenseRenewedSuccessKey";
export const licenseRenewedKey = "licenseRenewedKey";
export const noSupportRenewalKey = "noSupportRenewalKey";
export const selectSubscriptionKey = "selectSubscriptionKey";
export const downloadLicensesUpperKey = "downloadLicensesUpperKey";
export const standaloneSubscriptionKey = "standaloneSubscriptionKey";
export const supportSubscriptionKey = "supportSubscriptionKey";
export const cannotSupportSubscriptionKey = "cannotSupportSubscriptionKey";
export const accountNoSupportProductKey = "accountNoSupportProductKey";
export const noSupportAvailableProductKey = 'noSupportAvailableProductKey';
export const renewStandaloneNoSubscriptionErrorSubtitleYouHaveKey = 'renewStandaloneNoSubscriptionErrorSubtitleYouHaveKey';
export const renewStandaloneNoSubscriptionErrorSubtitleSubscriptionKey = 'renewStandaloneNoSubscriptionErrorSubtitleSubscriptionKey';
export const renewStandaloneNoSubscriptionErrorSubtitleToChangeKey = 'renewStandaloneNoSubscriptionErrorSubtitleToChangeKey';
export const supportRenewTogetherKey = 'supportRenewTogetherKey';
export const selectAllRenewalsKey = 'selectAllRenewalsKey';
export const renewalSelectedKey = "renewalSelectedKey";
export const renewMoreSubKey = "renewMoreSubKey";
export const notGeneratedAvailableRedeemV2Key = "notGeneratedAvailableRedeemV2Key";
export const notGeneratedSupportAgentV2Key = "notGeneratedSupportAgentV2Key";
export const youAreAllDoneKey = 'youAreAllDoneKey';
export const noLicenseFileForRenewalKey = 'noLicenseFileForRenewalKey';
export const noLicensesRenewalKey = "noLicensesRenewalKey";
export const syncUploaderKey = "syncUploaderKey";
export const addRequestFileKey = "addRequestFileKey";
export const searchHostSyncKey = "searchHostSyncKey";
export const noLicensesSyncKey = "noLicensesSyncKey";
export const hostNoRemainingLicensesSyncKey = "hostNoRemainingLicensesSyncKey";
export const confirmHostIdCorrectKey = "confirmHostIdCorrectKey";
export const notSupportedSyncKey = "notSupportedSyncKey";
export const addCertSyncKey = "addCertSyncKey";
export const hostReadySyncKey = "hostReadySyncKey";
export const syncedHostKey = "syncedHostKey";
export const requestSyncKey = "requestSyncKey";
export const syncMoreKey = "syncMoreKey";
export const syncedSuccessKey = "syncedSuccessKey";
export const generatedRetrieveLicensesV2Key = "generatedRetrieveLicensesV2Key";
export const syncFailedKey = "syncFailedKey";
export const noHostsAvailableKey = "noHostsAvailableKey";
export const myHostsKey = "myHostsKey";
export const aliasKey = "aliasKey";
export const assetInfoKey = "assetInfoKey";
export const cityKey = "cityKey";
export const stateKey = "stateKey";
export const countryKey = "countryKey";
export const licensesHostIdColonKey = "licensesHostIdColonKey";
export const prodNoKey = "prodNoKey";
export const sourceKey = "sourceKey";
export const licenseExpKey = "licenseExpKey";
export const supportExpKey = "supportExpKey";
export const goBackKey = "goBackKey";
export const editHostKey = "editHostKey";
export const licenseNotifierHostIdColonKey = "licenseNotifierHostIdColonKey";
export const serialNumberKey = "serialNumberKey";
export const hostMetaDataKey = "hostMetaDataKey";
export const removeHostKey = "removeHostKey";
export const areYouSureKey = "areYouSureKey";
export const permanentlyDeleteHostIdKey = "permanentlyDeleteHostIdKey";
export const exportHostInfoKey = "exportHostInfoKey";
export const expiringLicenseSupportKey = "expiringLicenseSupportKey";
export const seeExpirationsWithinKey = "seeExpirationsWithinKey";
export const allExpirationKey = "allExpirationKey";
export const thirtyDaysExpirationKey = "thirtyDaysExpirationKey";
export const sixtyDaysExpirationKey = "sixtyDaysExpirationKey";
export const oneHundredTwentyDaysExpirationKey = "oneHundredTwentyDaysExpirationKey";
export const orderCertificateKey = "orderCertificateKey";
export const enterpriseAgreementsNotIncludedKey = "enterpriseAgreementsNotIncludedKey";
export const pleaseUseLicenseViewKey = "pleaseUseLicenseViewKey";
export const trailLicenseRequestKey = "trailLicenseRequestKey";
export const selectHostKey = "selectHostKey";
export const requestSubmittedKey = "requestSubmittedKey";
export const enterHostInfoKey = "enterHostInfoKey";
export const trailLicenseSentKey = "trailLicenseSentKey";
export const durationKey = "durationKey";
export const quantityKey = "quantityKey";
export const requestAnotherTrailKey = "requestAnotherTrailKey";
export const contactInfoKey = "contactInfoKey";
export const firstNameKey = "firstNameKey";
export const lastNameKey = "lastNameKey";
export const emailKey = "emailKey";
export const phoneKey = "phoneKey";
export const companyNameKey = "companyNameKey";
export const jobTitleKey = "jobTitleKey";
export const addr1LineKey = "addr1LineKey";
export const addr2LineKey = "addr2LineKey";
export const stateProvinceKey = "stateProvinceKey";
export const zipPostalKey = "zipPostalKey";
export const countryAreaKey = "countryAreaKey";
export const submitRequestKey = "submitRequestKey";
export const deniedTrailLicenseRequestKey = "deniedTrailLicenseRequestKey";
export const productColonKey = "productColonKey";
export const additionalTrailShareDetailsKey = "additionalTrailShareDetailsKey";
export const toColonKey = "toColonKey";
export const keysightTechnologiesKey = "keysightTechnologiesKey";
export const subjectColonKey = "subjectColonKey";
export const trailLicenseDeniedKey = "trailLicenseDeniedKey";
export const bodyColonKey = "bodyColonKey";
export const invalidHostIdKey = "invalidHostIdKey";
export const ensureHostIdCorrectKey = "ensureHostIdCorrectKey";
export const trailLicenseNotAvailableKey = "trailLicenseNotAvailableKey";
export const trialDNEKey = "trialDNEKey";
export const notTrailDurationKey = "notTrailDurationKey";
export const requiredFieldsMissing = "requiredFieldsMissing";
export const completeRequiredFieldsKey = "completeRequiredFieldsKey";
export const enterValidEmailKey = "enterValidEmailKey";
export const trailLicenseExistHostKey = "trailLicenseExistHostKey";
export const trialLicenseSubmitErrorSubtitle2Key = "trialLicenseSubmitErrorSubtitle2Key";
export const trialLicenseSubmitErrorClickHereSubtitleKey = "trialLicenseSubmitErrorClickHereSubtitleKey";
export const trialLicenseSubmitSuccessKey = "trialLicenseSubmitSuccessKey";
export const trialLicenseSubmitSuccessSubtitle1Key = "trialLicenseSubmitSuccessSubtitle1Key";
export const trialLicenseSubmitSuccessSubtitle2Key = "trialLicenseSubmitSuccessSubtitle2Key";
export const messageSentKey = "messageSentKey";
export const reviewedByKeysightKey = "reviewedByKeysightKey";
export const encounteredErrorKey = "encounteredErrorKey";
export const contactUsTrailLicenseKey = "contactUsTrailLicenseKey";
export const trialLicensePrivacyStatementKey = "trialLicensePrivacyStatementKey";
export const pageNotFoundKey = "pageNotFoundKey";
export const pageNotFoundPleaseKey = "pageNotFoundPleaseKey";
export const pageNotFoundClickHereKey = "pageNotFoundClickHereKey";
export const pageNotFoundGoToKey = "pageNotFoundGoToKey";
export const pageCannotLoadKey = "pageCannotLoadKey";
export const pageCannotLoadPleaseKey = "pageCannotLoadPleaseKey";
export const pageCannotLoadClickHereKey = "pageCannotLoadClickHereKey";
export const pageCannotLoadGoToKey = "pageCannotLoadGoToKey";
export const topicsKey = "topicsKey";
export const frequentlyAskedQuestionsKey = "frequentlyAskedQuestionsKey";
export const helpWelcomeHeaderKey = "helpWelcomeHeaderKey";
export const quickStepsGetLicensesKey = "quickStepsGetLicensesKey";
export const ksmOverviewKey = "ksmOverviewKey";
export const registrationLoginKey = "registrationLoginKey";
export const homePageKey = "homePageKey";
export const loginAndRegistrationKey = "loginAndRegistrationKey";
export const entitlementCertificatesKey = "entitlementCertificatesKey";
export const licensesKey = "licensesKey";
export const helpWelcomeQuickStepsSectionKey = "helpWelcomeQuickStepsSectionKey";
export const helpWelcomeKSMOverviewSectionKey = "helpWelcomeKSMOverviewSectionKey";
export const helpWelcomeKSMRegistrationLoginSectionKey = "helpWelcomeKSMRegistrationLoginSectionKey";
export const helpWelcomeKSMWelcomePageHeaderKey = "helpWelcomeKSMWelcomePageHeaderKey";
export const helpWelcomeKSMWelcomePageSectionKey = "helpWelcomeKSMWelcomePageSectionKey";
export const helpWelcomeLoginRegistrationQAKey = "helpWelcomeLoginRegistrationQAKey";
export const helpWelcomeWelcomePageQAKey = "helpWelcomeWelcomePageQAKey";
export const helpWelcomeEntitlementCertificatesQAKey = "helpWelcomeEntitlementCertificatesQAKey";
export const helpWelcomeLicensesQAKey = "helpWelcomeLicensesQAKey";
export const addingCertificatesKey = "addingCertificatesKey";
export const helpAddCertificateFaqKey = "helpAddCertificateFaqKey";
export const helpAddCertificateQAKey = 'helpAddCertificateQAKey'
export const helpAddCertificateSectionKey = 'helpAddCertificateSectionKey'
export const quickStepsObtainingLicensesKey = 'quickStepsObtainingLicensesKey'
export const obtainingLicensesKey = 'obtainingLicensesKey'
export const helpAddCertificateObtainLicenseSectionKey = 'helpAddCertificateObtainLicenseSectionKey'
export const helpAddCertificateKSMOverviewSectionKey = 'helpAddCertificateKSMOverviewSectionKey'
export const helpAddCertificateRegLoginSectionKey = 'helpAddCertificateRegLoginSectionKey'
export const helpAddCertificateRegLoginFaqQAKey = 'helpAddCertificateRegLoginFaqQAKey'
export const helpAddCertificateWelcomePageFaqQAKey = 'helpAddCertificateWelcomePageFaqQAKey'
export const helpAddCertificateEntitlementCertFaqQAKey = 'helpAddCertificateEntitlementCertFaqQAKey'
export const helpAddCertificateObtainLicenseQAKey = 'helpAddCertificateObtainLicenseQAKey'
export const deactiveLicenseKey = "deactiveLicenseKey";
export const deactiveLicenseTopicKey = "deactiveLicenseTopicKey";
export const deactiveLicenseFaqKey = "deactiveLicenseFaqKey";
export const quickStepsDeactivationKey = 'quickStepsDeactivationKey'
export const navigationKey = 'navigationKey'
export const deactivatingLicensesKey = 'deactivatingLicensesKey'
export const specifyingTheHostKey = 'specifyingTheHostKey'
export const specifyingDeactivateKey = 'specifyingDeactivateKey'
export const requestingLicensesKey = 'requestingLicensesKey'
export const obtainingConfirmingDeactivationKey  = 'obtainingConfirmingDeactivationKey '
export const deactivationConfirmationKey = 'deactivationConfirmationKey'
export const errorHandlingKey = 'errorHandlingKey'
export const helpDeactivateQuickStepsSectionKey = 'helpDeactivateQuickStepsSectionKey'
export const helpDeactivateNavigationSectionKey = 'helpDeactivateNavigationSectionKey'
export const helpDeactivateSpecifyHostSectionKey = 'helpDeactivateSpecifyHostSectionKey'
export const helpDeactivateLicenseSectionKey = 'helpDeactivateLicenseSectionKey'
export const helpDeactivateSpecifyLicenseSectionKey = 'helpDeactivateSpecifyLicenseSectionKey'
export const helpDeactivateRequestingLicenseSectionKey = 'helpDeactivateRequestingLicenseSectionKey'
export const helpDeactivateObtainLicensesSectionKey = 'helpDeactivateObtainLicensesSectionKey'
export const helpDeactivateConfirmationSectionKey = 'helpDeactivateConfirmationSectionKey'
export const helpDeactivateErroHandlingSectionKey = 'helpDeactivateErroHandlingSectionKey'
export const helpDeactivateEntitlementCertFaqQAKey = 'helpDeactivateEntitlementCertFaqQAKey'
export const helpDeactivateLicensesFaqQAKey = 'helpDeactivateLicensesFaqQAKey'
export const ksmViewProductHostKey = "ksmViewProductHostKey";
export const productViewTopicKey = "productViewTopicKey";
export const productViewFqaKey = "productViewFqaKey";
export const viewingProductsHostKey = 'viewingProductsHostKey'
export const sortingFilteringKey = 'sortingFilteringKey'
export const redeemingDownloadingLicensesKey = 'redeemingDownloadingLicensesKey'
export const helpProductViewLicenseFaqQAKey = 'helpProductViewLicenseFaqQAKey'
export const helpProductViewNavigationContentKey = 'helpProductViewNavigationContentKey'
export const helpProductViewSortFilterContentKey = 'helpProductViewSortFilterContentKey'
export const helpProductViewProductHostContentKey = 'helpProductViewProductHostContentKey'
export const helpProductRedeemDownloadLicenseContentKey = 'helpProductRedeemDownloadLicenseContentKey'
export const ksmRenewNemoKey = "ksmRenewNemoKey";
export const quickStepsRenewNemoKey = "quickStepsRenewNemoKey";
export const creatingC2vFileKey = "creatingC2vFileKey";
export const requestingRenewalsKey = "requestingRenewalsKey";
export const generatingDownloadingKey = "generatingDownloadingKey";
export const renewalKey = "renewalKey";
export const helpRenewNemoQuickStepsSectionKey = "helpRenewNemoQuickStepsSectionKey";
export const helpRenewNemoNavigationSectionKey = "helpRenewNemoNavigationSectionKey";
export const helpRenewNemoCreatingC2VHeaderKey = "helpRenewNemoCreatingC2VHeaderKey";
export const helpRenewNemoCreatingC2VSectionKey = "helpRenewNemoCreatingC2VSectionKey";
export const helpRenewNemoRequestingRenewalsSectionKey = "helpRenewNemoRequestingRenewalsSectionKey";
export const helpRenewNemoGeneratingLicensesSectionKey = "helpRenewNemoGeneratingLicensesSectionKey";
export const helpRenewNemoErrorHandlingSectionKey = "helpRenewNemoErrorHandlingSectionKey";
export const helpRenewNemoRenewalQAKey = "helpRenewNemoRenewalQAKey";
export const transportKey = "transportKey";
export const transportOverviewKey = "transportOverviewKey";
export const helpTransportQuickStepsTransport = "helpTransportQuickStepsTransport";
export const helpTransportQuickStepsSave = "helpTransportQuickStepsSave";
export const helpTransportMain = "helpTransportMain";
export const helpTransportLicenses = "helpTransportLicenses";
export const helpTransportSaving = "helpTransportSaving";
export const helpTransportingBanking = "helpTransportingBanking";
export const helpTransportOverviewContent = "helpTransportOverviewContent";
export const helpTransportQuickStepsTransportContent = "helpTransportQuickStepsTransportContent";
export const helpTransportQuickStepsSavingContent = "helpTransportQuickStepsSavingContent";
export const helpTransportMainContent = "helpTransportMainContent";
export const helpTransportLicensesContent = "helpTransportLicensesContent";
export const helpTransportSavingContent = "helpTransportSavingContent";
export const helpKalTransportErrorHandling = "helpKalTransportErrorHandling";
export const helpKALTrasportQAKey = "helpKALTrasportQAKey";
export const helpTransportCCLOverviewContent = "helpTransportCCLOverviewContent";
export const helpTransportCCLQuickStepsTransportContent = "helpTransportCCLQuickStepsTransportContent";
export const helpTransportCCLQuickStepsSavingContent = "helpTransportCCLQuickStepsSavingContent";
export const helpTransportCCLMainContent = "helpTransportCCLMainContent";
export const helpTransportCCLLicensesContent = "helpTransportCCLLicensesContent";
export const helpTransportCCLSavingContent = "helpTransportCCLSavingContent";
export const helpTransportCCLQAKey = "helpTransportCCLQAKey";
export const helpTransportCCLQuickStepsSave = "helpTransportCCLQuickStepsSave";
export const helpTransportCCLQuickStepsTransport = "helpTransportCCLQuickStepsTransport";
export const quickStepsRenewSubscriptionsKey = "quickStepsRenewSubscriptionsKey";
export const helpRenewStandaloneQuickStepsSectionKey = "helpRenewStandaloneQuickStepsSectionKey";
export const helpRenewStandaloneNavigationSectionKey = "helpRenewStandaloneNavigationSectionKey";
export const helpRenewStandaloneRenewSupportSectionKey = "helpRenewStandaloneRenewSupportSectionKey";
export const helpRenewStandaloneErrorHandlingSectionKey = "helpRenewStandaloneErrorHandlingSectionKey";
export const licenseRenewalKey = "licenseRenewalKey";
export const helpRenewStandaloneLicenseRenewalQAKey = "helpRenewStandaloneLicenseRenewalQAKey";
export const quickStepsRenewEesofKey = "quickStepsRenewEesofKey";
export const reassigningLicenceNewHostKey = "reassigningLicenceNewHostKey";
export const requestRenewedLicenseKey = "requestRenewedLicenseKey";
export const helpRenewEesofQuickStepsSectionKey = "helpRenewEesofQuickStepsSectionKey";
export const helpRenewEesofNavigationSectionKey = "helpRenewEesofNavigationSectionKey";
export const helpRenewEesofReassignLicenseSectionKey = "helpRenewEesofReassignLicenseSectionKey";
export const requestingRenewalKey = "requestingRenewalKey";
export const helpRenewEesofRequestLicenseSectionKey = "helpRenewEesofRequestLicenseSectionKey";
export const helpRenewEesofErrorHandlingSectionKey = "helpRenewEesofErrorHandlingSectionKey";
export const helpRenewEesofRenewalQAKey = "helpRenewEesofRenewalQAKey";
export const ksmRequestNewLicenseKey = "ksmRequestNewLicenseKey";
export const requestLicenseTopicKey = "requestLicenseTopicKey";
export const requestLicenseFqaKey = "requestLicenseFqaKey";
export const quickStepsObtainLicensesKey = 'quickStepsObtainLicensesKey';
export const requestingNewLicenses = 'requestingNewLicenses';
export const selectingProductsKey = 'selectingProductsKey';
export const assigningProductsToHostKey = 'assigningProductsToHostKey';
export const changingProductAssignmentsKey = 'changingProductAssignmentsKey';
export const previewingLicensesKey = 'previewingLicensesKey';
export const helpRequestQuickStepsSectionKey = 'helpRequestQuickStepsSectionKey';
export const helpRequestNavigationSectionKey = 'helpRequestNavigationSectionKey';
export const helpRequestRequestingNewLicensesSectionKey = 'helpRequestRequestingNewLicensesSectionKey';
export const helpRequestSelectingProductSectionKey = 'helpRequestSelectingProductSectionKey';
export const helpRequestAssignProductSectionKey = 'helpRequestAssignProductSectionKey';
export const helpRequestPreviewLicenseSectionKey = 'helpRequestPreviewLicenseSectionKey';
export const helpRequestChangeProductSectionKey = 'helpRequestChangeProductSectionKey';
export const helpRequestGeneratingLicenseSectionKey = 'helpRequestGeneratingLicenseSectionKey';
export const helpRequestErroHandlingSectionKey = 'helpRequestErroHandlingSectionKey';
export const helpRequestEntitlementCertificateFaqQAKey = 'helpRequestEntitlementCertificateFaqQAKey';
export const helpRequestLicensesFaqQAKey = 'helpRequestLicensesFaqQAKey';
export const ksmSyncLicensesKey = "ksmSyncLicensesKey";
export const syncingYourLicenseFileKey = "syncingYourLicenseFileKey";
export const helpSyncLicenseNavigationSectionKey = "helpSyncLicenseNavigationSectionKey";
export const helpSyncLicenseSyncingYourLicenseFileSectionKey = "helpSyncLicenseSyncingYourLicenseFileSectionKey";
export const helpSyncLicenseErrorHandlingSectionKey = "helpSyncLicenseErrorHandlingSectionKey";
export const helpSyncLicensesEntitlementCertificatesQAKey = "helpSyncLicensesEntitlementCertificatesQAKey";
export const helpSyncLicensesLicensesQAKey = "helpSyncLicensesLicensesQAKey";
export const ksmViewCertKey = "ksmViewCertKey";
export const viewingCertKey = "viewingCertKey";
export const helpViewCertificateViewingCertificateSectionKey = "helpViewCertificateViewingCertificateSectionKey";
export const helpViewCertificateQuickStepsSectionKey = "helpViewCertificateQuickStepsSectionKey";
export const helpViewCertificateLoginRegistrationQAKey = "helpViewCertificateLoginRegistrationQAKey";
export const helpViewCertificateWelcomeQAKey = "helpViewCertificateWelcomeQAKey";
export const helpViewCertificateEntitlementCertificatesQAKey = "helpViewCertificateEntitlementCertificatesQAKey";
export const helpViewCertificateAddingCertificatesQAKey = "helpViewCertificateAddingCertificatesQAKey";
export const helpViewCertificateObtainingLicensesKey = "helpViewCertificateObtainingLicensesKey";
export const eaSummaryKey = "eaSummaryKey";
export const typesEAsKey = "typesEAsKey";
export const myEnterpriseAgreementsKey = "myEnterpriseAgreementsKey";
export const gettingStartedKey = "gettingStartedKey";
export const typesOfEnterpriseAgreementsKey = "typesOfEnterpriseAgreementsKey";
export const hostsKey = "hostsKey";
export const eaAccessKey = "eaAccessKey";
export const helpEASummaryQuickStepsSectionKey = "helpEASummaryQuickStepsSectionKey";
export const helpEASummaryNavigationSectionKey = "helpEASummaryNavigationSectionKey";
export const helpEASummaryEATypesSectionKey = "helpEASummaryEATypesSectionKey";
export const helpEASummaryMyEnterpriseAgreementsSectionKey = "helpEASummaryMyEnterpriseAgreementsSectionKey";
export const helpEASummaryGettingStartedSectionKey = "helpEASummaryGettingStartedSectionKey";
export const helpEASummaryErrorHandlingSectionKey = "helpEASummaryErrorHandlingSectionKey";
export const helpEASummaryEnterpriseAgreementsQAKey = "helpEASummaryEnterpriseAgreementsQAKey";
export const helpEASummaryLicensesQAKey = "helpEASummaryLicensesQAKey";
export const helpEASummaryHostsQAKey = "helpEASummaryHostsQAKey";
export const helpEASummaryEAAccessQAKey = "helpEASummaryEAAccessQAKey";
export const shortcutProductSelectionKey = "shortcutProductSelectionKey";
export const subscriptionPoolStatusKey = "subscriptionPoolStatusKey";
export const helpEADetailedViewShortcutSectionKey = "helpEADetailedViewShortcutSectionKey";
export const helpEADetailedViewEALicenseViewSectionKey = "helpEADetailedViewEALicenseViewSectionKey";
export const helpEADetailedViewSubscriptionPoolSectionKey = "helpEADetailedViewSubscriptionPoolSectionKey";
export const helpEADetailedViewEATypesSectionKey = "helpEADetailedViewEATypesSectionKey";
export const helpEADetailedViewErrorHandlingSectionKey = "helpEADetailedViewErrorHandlingSectionKey";
export const helpEADetailedViewEnterpriseAgreementsQAKey = "helpEADetailedViewEnterpriseAgreementsQAKey";
export const helpEADetailedViewLicensesQAKey = "helpEADetailedViewLicensesQAKey";
export const helpEADetailedViewHostsQAKey = "helpEADetailedViewHostsQAKey";
export const helpEADetailedViewEAAccessQAKey = "helpEADetailedViewEAAccessQAKey";
export const eaLicenseRequestsKey = "eaLicenseRequestsKey";
export const quickStepToGetLicensesKey = "quickStepToGetLicensesKey";
export const assigningProductsToHostsKey = "assigningProductsToHostsKey";
export const changingLicenseEndDatesKey = "changingLicenseEndDatesKey";
export const gettingLicensesKey = "gettingLicensesKey";
export const helpEARequestAgainKey = "helpEARequestAgainKey";
export const aboutEAPortalRequestAgainKey = "aboutEAPortalRequestAgainKey";
export const quickStepsRequestAgainKey = "quickStepsRequestAgainKey";
export const helpEARequestAgainAboutSectionKey = "helpEARequestAgainAboutSectionKey";
export const helpEARequestAgainQuickStepsSectionKey = "helpEARequestAgainQuickStepsSectionKey";
export const helpEARequestAgainNavigationSectionKey = "helpEARequestAgainNavigationSectionKey";
export const helpEARequestAgainSelectProductSectionKey = "helpEARequestAgainSelectProductSectionKey";
export const helpEARequestAgainAssignProductSectionKey = "helpEARequestAgainAssignProductSectionKey";
export const helpEARequestAgainRequestLicenseSectionKey = "helpEARequestAgainRequestLicenseSectionKey";
export const helpEARequestLicenseQuickStepsSectionKey = "helpEARequestLicenseQuickStepsSectionKey";
export const helpEARequestLicenseNavigationSectionKey = "helpEARequestLicenseNavigationSectionKey";
export const helpEARequestLicenseSelectingProductsSectionKey = "helpEARequestLicenseSelectingProductsSectionKey";
export const helpEARequestLicenseAssigningProductsSectionKey = "helpEARequestLicenseAssigningProductsSectionKey";
export const helpEARequestLicenseEndDateChangeSectionKey = "helpEARequestLicenseEndDateChangeSectionKey";
export const helpEARequestLicenseGettingLicensesSectionKey = "helpEARequestLicenseGettingLicensesSectionKey";
export const helpEARequestLicenseErrorHandlingSectionKey = "helpEARequestLicenseErrorHandlingSectionKey";
export const helpEARequestLicenseEnterpriseAgreementsQAKey = "helpEARequestLicenseEnterpriseAgreementsQAKey";
export const helpEARequestLicenseLicensesQAKey = "helpEARequestLicenseLicensesQAKey";
export const helpEARequestLicenseHostsQAKey = "helpEARequestLicenseHostsQAKey";
export const helpEARequestLicenseEAAccessQAKey = "helpEARequestLicenseEAAccessQAKey";
export const managingHostsKey = "managingHostsKey";
export const viewingLicensesKey = "viewingLicensesKey";
export const editingHostInfoKey = "editingHostInfoKey";
export const removingAHostKey = "removingAHostKey";
export const helpHostConfigManagingHostsSectionKey = "helpHostConfigManagingHostsSectionKey";
export const helpHostConfigViewingLicensesSectionKey = "helpHostConfigViewingLicensesSectionKey";
export const helpHostConfigEditingHostSectionKey = "helpHostConfigEditingHostSectionKey";
export const helpHostConfigRemovingHostSectionKey = "helpHostConfigRemovingHostSectionKey";
export const helpHostConfigErrorHandlingSectionKey = "helpHostConfigErrorHandlingSectionKey";
export const helpHostConfigHostsQAKey = "helpHostConfigHostsQAKey";
export const trailLicenseKey = "trailLicenseKey";
export const aboutKeysightTrailLicensesKey = "aboutKeysightTrailLicensesKey";
export const licenseProductInfoKey = "licenseProductInfoKey";
export const hostIdHostInfoKey = "hostIdHostInfoKey";
export const helpTrialLicenseAboutSectionKey = "helpTrialLicenseAboutSectionKey";
export const helpTrialLicenseProductInfoSectionKey = "helpTrialLicenseProductInfoSectionKey";
export const helpTrialLicenseHostIDHostInfoSectionKey = "helpTrialLicenseHostIDHostInfoSectionKey";
export const viewByKey = "viewByKey";
export const productsKey = "productsKey";
export const qtyAssignedKey = "qtyAssignedKey";
export const qtyUnassignedKey = "qtyUnassignedKey";
export const assignMoreKey = "assignMoreKey";
export const assignMoreHostsKey = "assignMoreHostsKey";
export const requestShipmentUpdateKitKey = "requestShipmentUpdateKitKey"
export const requestShipmentKey = "requestShipmentKey"
export const requestQuantityKey = "requestQuantityKey"
export const shippingAddressKey = "shippingAddressKey"
export const nameKey = "nameKey"
export const companyKey = "companyKey"
export const departmentKey = "departmentKey"
export const zipCodeKey = "zipCodeKey"
export const showOnlyUpdatesKey = "showOnlyUpdatesKey"
export const orderNumberPurchaseOrderModelEtcKey = "orderNumberPurchaseOrderModelEtcKey"
export const resetKey = "resetKey"
export const releaseDateKey = "releaseDateKey"
export const fileSizeKey = "fileSizeKey"
export const platformKey = "platformKey"
export const updateKey = "updateKey"
export const downloadSelectedKey = "downloadSelectedKey"
export const softwareFamilyKey = "softwareFamilyKey"
export const licenseKey = "licenseKey"
export const quantityToAssignKey = "quantityToAssignKey"
export const backToSoftwareUpdateKey = "BackToSoftwareUpdate"
export const getAnotherLicenseKey = "GetAnotherLicense"
export const requestUpdateLicensesKey = "RequestUpdateLicenses"
export const shipmentRequestReceivedKey = "shipmentRequestReceivedKey"
export const shipmentSuccessSubmittedKey = "shipmentSuccessSubmittedKey"
export const yourOrderNumberIsKey = "yourOrderNumberIsKey"
export const SoftwareUpdatesAreAvailablePleaseEnterAQtyBetween1AndKey = "SoftwareUpdatesAreAvailablePleaseEnterAQtyBetween1AndKey"
export const browserLimitConcurrentDownloadKey = "browserLimitConcurrentDownloadKey";
export const limitDownloadThenRequestKey = "limitDownloadThenRequestKey";
export const limitsMultipleConcurrentDownloadsKey = "limitsMultipleConcurrentDownloadsKey";
export const ksmSwUpdatesKey = 'ksmSwUpdatesKey'
export const swUpdatesOverview = 'swUpdatesOverview'
export const searchingSpecificUpdateKey = 'searchingSpecificUpdateKey'
export const gettingUpdateFilesKey = 'gettingUpdateFilesKey'
export const swUpdatesKey = 'swUpdatesKey'
export const helpSoftwareUpdateFaqQAKey = 'helpSoftwareUpdateFaqQAKey'
export const helpSoftwareUpdateOverviewContentKey = 'helpSoftwareUpdateOverviewContentKey'
export const helpSoftwareUpdateSearchContentKey = 'helpSoftwareUpdateSearchContentKey'
export const helpSoftwareUpdateGettingFilesContentKey = 'helpSoftwareUpdateGettingFilesContentKey'
export const helpSoftwareUpdateErrorHandlingContentKey = 'helpSoftwareUpdateErrorHandlingContentKey'
export const softwareUpdatesOverviewKey = 'softwareUpdatesOverviewKey'
export const selectLicenseKey = "selectLicenseKey"
export const myUpdatedLicensedProductsKey = "myUpdatedLicensedProductsKey"
export const qtyToAssignKey = "qtyToAssignKey"
export const updateCannotBeRedeemedKey = "updateCannotBeRedeemedKey"
export const firstRedeemThenUpdateKey = "firstRedeemThenUpdateKey"
export const backToSoftwareUpdatesKey = "backToSoftwareUpdatesKey"
export const certificateKey = "certificateKey";
export const licKey = "licKey";
export const downloadLicenseKey = "downloadLicenseKey";
export const availableLicenseBrowserKey = "availableLicenseBrowserKey";
export const requestAllAvailableLicensesKey = "requestAllAvailableLicensesKey";
export const requestAvailableLicensesOrderKey = "requestAvailableLicensesOrderKey";
export const requestAvailableLicensesProductKey = "requestAvailableLicensesProductKey";
export const numberOfProductsKey = "numberOfProductsKey"
export const enterpriseAgreementKey = "enterpriseAgreementKey";
export const remainingSubPoolKey = "remainingSubPoolKey";
export const remixOpensOnKey = "remixOpensOnKey";
export const noActiveAgreementsKey = "noActiveAgreementsKey";
export const workOnCurrentPeriodKey = "workOnCurrentPeriodKey";
export const workOnNextPeriodKey = "workOnNextPeriodKey";
export const remixNextPeriodKey = "remixNextPeriodKey";
export const remixCurrentPeriodKey = "remixCurrentPeriodKey";
export const copyProductsAndHostsKey = "copyProductsAndHostsKey";
export const copyProductsOnlyKey = "copyProductsOnlyKey";
export const startFromScratchKey = "startFromScratchKey";
export const requestAgainKey = "requestAgainKey";
export const requestAgainTooltipKey = "requestAgainTooltipKey";
export const getLicensesKey = "getLicensesKey";
export const ELStartFromScratchWarning1Key = "ELStartFromScratchWarning1Key";
export const ELStartFromScratchWarning2Key = "ELStartFromScratchWarning2Key";
export const ELStartFromScratchWarning3Key = "ELStartFromScratchWarning3Key";
export const ELCopyProductsOnlyWarning1Key = "ELCopyProductsOnlyWarning1Key";
export const ELCopyProductsOnlyWarning2Key = "ELCopyProductsOnlyWarning2Key";
export const ELCopyProductsAndHostsWarningKey = "ELCopyProductsAndHostsWarningKey";
export const ELRequestAgainCopyProductsAndHostsWarningKey = "ELRequestAgainCopyProductsAndHostsWarningKey";
export const ELCopyProductsWarning1Key = "ELCopyProductsWarning1Key";
export const ELCopyProductsWarning2Key = "ELCopyProductsWarning2Key";
export const ELCopyProductsWarning3Key = "ELCopyProductsWarning3Key";
export const confirmKey = "confirmKey";
export const ELCopyAlertTitleKey = "ELCopyAlertTitleKey";
export const ELCopyProductsOnlyAlertMessageKey = "ELCopyProductsOnlyAlertMessageKey";
export const ELCopyProductsAndHostsAlertMessageKey = "ELCopyProductsAndHostsAlertMessageKey";
export const transportLicenseKey = "transportLicenseKey";
export const enterSelectLicensesKey = "enterSelectLicensesKey";
export const reviewAndSubmitKey = "reviewAndSubmitKey";
export const TransportSelectDirections = "TransportSelectDirections";
export const TransportSearchDirections = "TransportSearchDirections";
export const TransportAssignDirections = "TransportAssignDirections";
export const licenseIdKey = "licenseIdKey";
export const confirmationCodeKey = "confirmationCodeKey";
export const addLicenseKey = "addLicenseKey";
export const saveToKeysightSoftwareManagerKey = "saveToKeysightSoftwareManagerKey";
export const licenseBelowNotValidKey = "licenseBelowNotValidKey";
export const licenseIdsKey = "licenseIdsKey";
export const transportNoMoreActionsKey = "transportNoMoreActionsKey";
export const licensesNotLinkedKSMProfileKey = "licensesNotLinkedKSMProfileKey";
export const transportLicenseLogInTOKSMKey = "transportLicenseLogInTOKSMKey";
export const TransportInvalidParamsErrorTitle = "TransportInvalidParamsErrorTitle";
export const TransportInvalidParamsErrorMessage = "TransportInvalidParamsErrorMessage";
export const infoProvidedInvalidKey = "infoProvidedInvalidKey";
export const checkTryAgainKey = "checkTryAgainKey";
export const oneMoreLicensesNotAvailableKey = "oneMoreLicensesNotAvailableKey";
export const alreadyTransportedKey = "alreadyTransportedKey";
export const ProductsAvailableToAssignKey = "ProductsAvailableToAssignKey";
export const returnLicenseKey = "returnLicenseKey";
export const transportRemainingLicensesKey = "transportRemainingLicensesKey";
export const licensesAreSuccessfullySavedKey = "licensesAreSuccessfullySavedKey";
export const TransportSuccessBankedMessage = "TransportSuccessBankedMessage";
export const enterLicensesKey = "enterLicensesKey";
export const TransportURLFile1 = "TransportURLFile1";
export const TransportURLFile2 = "TransportURLFile2";
export const TransportURLFile3 = "TransportURLFile3";
export const TransportURLFile4 = "TransportURLFile4";
export const uploadKeysightURLFileKey = "uploadKeysightURLFileKey";
export const whereToFileURLFileKey = "whereToFileURLFileKey";
export const currentHostIdKey = "currentHostIdKey";
export const sequenceNumberKey = "sequenceNumberKey";
export const emailLicenseKey = "emailLicenseKey";
export const viewAndDownloadActiveLicensesKey = "viewAndDownloadActiveLicensesKey";
export const myLicensesKey = "myLicensesKey";
export const assetKey = "assetKey";
export const transactionKey = "transactionKey";
export const checkoutDateKey = "checkoutDateKey";
export const noLicenseAvailableViewDownloadKey = "noLicenseAvailableViewDownloadKey";
export const backKey = "backKey";
export const removeKey = "removeKey";
export const addToCartKey = "addToCartKey";
export const qtyAvailableKey = "qtyAvailableKey";
export const enterProductNumberDescriptionKey = "enterProductNumberDescriptionKey";
export const clientInfoKey = "clientInfoKey";
export const detailedInfoSwaKey = "detailedInfoSwaKey";
export const reviewCompleteKey = "reviewCompleteKey";
export const clientInfoOptionalKey = "clientInfoOptionalKey";
export const companyInfoColonKey = "companyInfoColonKey";
export const companyAssetInfoColonKey = "companyAssetInfoColonKey";
export const poNumberColonKey = "poNumberColonKey";
export const clientNotesColonKey = "clientNotesColonKey";
export const companyInfoKey = "companyInfoKey";
export const companyAssetInfoKey = "companyAssetInfoKey";
export const clientNotesKey = "clientNotesKey";
export const deleteProductHostStep2Key = "deleteProductHostStep2Key";
export const detailedInfoSwaClientKey = "detailedInfoSwaClientKey";
export const noKey = "noKey";
export const yesKey = "yesKey";
export const selectHostBeginCheckInKey = "selectHostBeginCheckInKey";
export const selectHostsParenthesisKey = "selectHostsParenthesisKey";
export const selectLicensesParenthesisKey = "selectLicensesParenthesisKey";
export const checkInKey = "checkInKey";
export const customerKey = "customerKey";
export const selectHostCheckInKey = "selectHostCheckInKey";
export const expirationDateKey = "expirationDateKey";
export const reviewCheckInLicensesKey = "reviewCheckInLicensesKey";
export const emailConfirmationKey = "emailConfirmationKey";
export const sendConfirmationEmailKey = "sendConfirmationEmailKey";
export const sendConfirmationEmailToSeparatedKey = "sendConfirmationEmailToSeparatedKey";
export const successExclamationKey = "successExclamationKey";
export const attentionExclamationKey = "attentionExclamationKey";
export const checkInSuccessKey = "checkInSuccessKey";
export const checkInMoreLicensesKey = "checkInMoreLicensesKey";
export const confirmationEmailSentKey = "confirmationEmailSentKey";
export const undoneCheckInKey = "undoneCheckInKey";
export const sendEmailKey = "sendEmailKey";
export const noLicensesCheckInKey = "noLicensesCheckInKey";
export const generateReportKey = "generateReportKey";
export const selectReportTimeFrameKey = "selectReportTimeFrameKey";
export const allKey = "allKey";
export const checkedInKey = "checkedInKey";
export const hostAssetInfoKey = "hostAssetInfoKey";
export const clientAssetInfoKey = "clientAssetInfoKey";
export const checkInDateKey = "checkInDateKey";
export const createReportDataFormKey = "createReportDataFormKey";
export const exportKey = "exportKey";
export const daysOutKey = "daysOutKey";
export const aboutSoftwareAccessKey = "aboutSoftwareAccessKey";
export const helpSAAboutSectionKey = "helpSAAboutSectionKey";
export const helpSANavigationSectionKey = "helpSADownloadNavigationSectionKey";
export const stepsToViewDownloadLicensesKey = "stepsToViewDownloadLicensesKey";
export const helpSADownloadStepsSectionKey = "helpSADownloadStepsSectionKey";
export const stepsToViewSoftwareUsageReportKey = "stepsToViewSoftwareUsageReportKey";
export const helpSAReportStepsSectionKey = "helpSAReportStepsSectionKey";
export const columnsDisplayedReportKey = "columnsDisplayedReportKey";
export const helpSAReportColumnsSectionKey = "helpSAReportColumnsSectionKey";
export const quickStepsToCheckoutKey = "quickStepsToCheckoutKey";
export const helpSACheckoutStepsSectionKey = "helpSACheckoutStepsSectionKey";
export const helpSACheckoutNavigationSectionKey = "helpSACheckoutNavigationSectionKey";
export const helpSACheckoutSelectingProductsSectionKey = "helpSACheckoutSelectingProductsSectionKey";
export const helpSACheckoutAssigningProductsSectionKey = "helpSACheckoutAssigningProductsSectionKey";
export const helpSACheckoutClientInformationSectionKey = "helpSACheckoutClientInformationSectionKey";
export const helpSACheckoutReviewSectionKey = "helpSACheckoutReviewSectionKey";
export const helpSACheckoutQAKey = "helpSACheckoutQAKey";
export const quickStepsCheckInKey = "quickStepsCheckInKey";
export const helpSACheckinStepsSectionKey = "helpSACheckinStepsSectionKey";
export const helpSACheckinSelectHostsSectionKey = "helpSACheckinSelectHostsSectionKey";
export const helpSACheckinSelectLicensesSectionKey = "helpSACheckinSelectLicensesSectionKey";
export const helpSACheckinCheckinSectionKey = "helpSACheckinCheckinSectionKey";
export const helpSACheckinNavigationSectionKey = "helpSACheckinNavigationSectionKey";
export const helpSACheckinQAKey = "helpSACheckinQAKey";
export const entitlementOnHostTitleKey = "entitlementOnHostTitleKey";
export const entitlementOnHostMessageKey = "entitlementOnHostMessageKey";
export const profileItarTitleKey = "profileItarTitleKey";
export const profileItarMessageLACKey = "profileItarMessageLACKey";
export const profileItarMessageKey = "profileItarMessageKey";
export const profileItarMessageKeyTwo = "profileItarMessageKeyTwo";
export const profileItarMessageKeyThree = "profileItarMessageKeyThree";
export const controlTitleKey = "controlTitleKey";
export const controlMessageOneKey = "controlMessageOneKey";
export const controlMessageTwoKey = "controlMessageTwoKey";
export const acceptKey = "acceptKey";
export const declineKey = "declineKey";
export const uploadFileKey = "uploadFileKey";

export const mainMessages = {
    'en': {
        [contactUsKey]: 'Contact Us',
        [loginKey]: 'Login',
        [registerKey]: 'Register',
        [morePeriodsKey]: 'more...',
        [welcomeKey]: 'Welcome',
        [myProfileKey]: 'My Profile',
        [logoutKey]: 'Logout',
        [keysightSoftwareManagerKey]: 'Keysight Software Manager',
        [helpKey]: 'Help',

        [exploreKey]: 'Explore',
        [productServicesKey]: 'Products + Services',
        [solutionsKey]: 'Solutions',
        [industriesKey]: 'Industries',
        [eventsKey]: 'Events',
        [keysightUniversityKey]: 'Keysight University',

        [insightsKey]: 'Insights',
        [discoverInsightsKey]: 'Discover Insights',
        [successStoriesKey]: 'Success Stories',
        [resourcesKey]: 'Resources',
        [blogKey]: 'Blog',
        [communityKey]: 'Community',
        [partnersKey]: 'Partners',

        [supportKey]: 'Support',
        [keysightProductSupportKey]: 'Keysight Product Support',
        [ixiaProductSupportKey]: 'Ixia Product Support',
        [manageSoftwareLicensesKey]: 'Manage Software Licenses',
        [productOrderStatusKey]: 'Product Order Status',
        [partsKey]: 'Parts',

        [aboutKeysightKey]: 'About Keysight',
        [newsroomKey]: 'Newsroom',
        [investorRelationsKey]: 'Investor Relations',
        [corporateSocialKey]: 'Corporate Social Responsibility',
        [diversityEquityKey]: 'Diversity, Equity, and Inclusion',
        [modernSlaveryActKey]: 'Modern Slavery Act Transparency Statement',
        [careersKey]: 'Careers',

        [privacyKey]: 'Privacy',
        [termsKey]: 'Terms',
        [feedbackKey]: 'Feedback',
        [aboutLicensingKey]: 'About Licensing',

        [facebookLinkKey]: 'https://www.facebook.com/Keysight/',
        [twitterLinkKey]: 'https://twitter.com/keysight',
        [youtubeLinkKey]: 'https://www.youtube.com/user/keysight',

        [facebookConnectKey]: 'Facebook: Connect with Keysight',
        [linkedInConnectKey]: 'LinkedIn: Connect with Keysight',
        [twitterConnectKey]: 'Twitter: Connect with Keysight',
        [youtubeConnectKey]: 'Youtube: Connect with Keysight',
        [weChatConnectKey]: 'WeChat: Connect with Keysight',

        [systemMaintenanceKey]: 'Notice! Upcoming System Maintenance',
        [downtimeTextKey]: 'There will be brief interruption to access of Keysight Software Manager. We apologize for any inconvenience.',
        [downtimeStartKey]: 'Start of downtime: ',
        [downtimeEndKey]: 'End of downtime: ',

        [controlTitleKey]: 'Attention!',
        [controlMessageOneKey]: 'Your account contains ITAR-controlled entitlements and does not meet the requirements to access ITAR licenses. For further information or to request access, please email support@keysight.com',
        [controlMessageTwoKey]: 'If you no longer need access to ITAR licenses, please contact your account team to request they remove ITAR entitlements from your account.',

        [returningUsersKey]: 'Returning Users',
        [pleaseLoginKey]: 'Please Login',
        [newUserKey]: 'New User',
        [firstTimeUsersKey]: 'First Time Users',
        [pleaseRegisterKey]: 'Please Register',
        [quickReferenceGuideKey]: 'Quick Reference Guide',

        [availableKey]: 'Available',
        [newKey]: "New",
        [activeKey]: "Active",
        [expiredKey]: "Expired",
        [checkedOutKey]: "Checked Out",
        // tile titles
        [entitlementManagementKey]: 'Entitlement Management',
        [licenseManagementKey]: 'License Management',
        [softwareUpdatesKey]: 'Software Updates',
        [licensesAndHostsKey]: 'Licenses and Hosts',
        [softwareAccessLicensesKey]: 'Software Access Licenses',
        [enterpriseAgreementsKey]: 'Enterprise Agreements',
        // tile sub menus
        [addNewCertificatesKey]: 'Add New Certificate',
        [viewMyCertificatesKey]: 'View Certificates',
        [shareMyCertificatesKey]: 'Share Certificates',
        [requestNewLicensesKey]: 'Request New Licenses',
        [deactivateLicensesKey]: 'Deactivate Licenses',
        [syncLicensesKey]: 'Sync Licenses',
        [renewNemoLicensesKey]: 'Renew Nemo Licenses',
        [renewSoftwareSubscriptionsKey]: 'Renew Standalone Support',
        [renewEESOFLicensesKey]: 'Renew EEsof EDA Licenses',
        [downloadSoftwareUpdatesKey]: 'Download Software Updates',
        [viewByProductHostKey]: 'View Licenses by Product or Host',
        [viewExpiringLicenseSupportKey]: 'View Expiring Licenses and Support',
        [manageHostsKey]: 'Manage Hosts',
        [checkOutLicensesKey]: 'Check Out Licenses',
        [checkInLicensesKey]: 'Check In Licenses',
        [viewDownloadLicensesKey]: 'View and Download Licenses',
        [softwareUsageReportKey]: 'Software Usage Report',
        [sWAccessLicensesKey]: 'Manage SW Access Licenses',
        [manageEnterpriseAgreementsKey]: 'Manage Enterprise Agreements',
        // tile sub menus tooltips
        [addNewCertificatesTooltipKey]: 'Add your entitlement certificate to redeem licenses and download any corresponding SW updates.',
        [viewCertificatesTooltipKey]: 'View details of the products included on your entitlement certificates.',
        [shareMyCertificatesTooltipKey]: 'Enable other registered user profiles to manage your certificates/licenses.',
        [requestNewLicensesTooltipKey]: 'Select and assign available licenses to your hosts.',
        [deactivateLicensesTooltipKey]: 'Return a license using the offline process, after the return is completed, you can use Request New License to activate the license onto a different host.',
        [syncLicensesTooltipKey]: 'Sync a license in order to reflect the latest licenses and support end dates of your host.',
        [renewNemoLicensesTooltipKey]: 'Update your Nemo license to reflect your new support expiration date.',
        [renewSoftwareSubscriptionsTooltipKey]: 'Update your legacy license to reflect your new support expiration date.',
        [renewEESOFLicensesTooltipKey]: 'Update your EEsof license to reflect your new support expiration date.',
        [downloadSoftwareUpdatesTooltipKey]: 'Review and download available SW updates.',
        [viewByProductHostTooltipKey]: 'View details of your products, installed licenses and hosts, as well as existing support.',
        [viewExpiringLicenseSupportTooltipKey]: 'View upcoming License and Support expirations.',
        [manageHostsTooltipKey]: 'View details of your hosts, manage aliases, asset information, city, state, country, and notes for each of your hosts.',
        [checkOutLicensesTooltipKey]: 'Select the desired products and redeem licenses for your rental hosts.',
        [checkInLicensesTooltipKey]: 'Return your checked out rental licenses.',
        [viewDownloadLicensesTooltipKey]: 'View or download your active rental licenses.',
        [softwareUsageReportTooltipKey]: 'View your license usage details.',
        [sWAccessLicensesTooltipKey]: 'Check out, check in, download and view your usage details.',
        [manageEnterpriseAgreementsTooltipKey]: 'Redeem your agreement licenses, complete remixes and view/download your existing licenses.',
        [redirectingKey]: 'Redirecting',
        [workingOnRequestKey]: 'We are working on your request.',
        [pleaseWaitKey]: 'Please wait.',
        [sessionExpiredKey]: 'Your session has expired.',
        [refreshKey]: 'Refresh',

        [assignHostKey]: 'Assign Host',
        [productNumberKey]: 'Product Number',
        [descriptionKey]: 'Description',
        [qtyKey]: 'Qty',
        [cancelChangesKey]: 'Cancel',
        [saveHostIDKey]: 'Save',
        [hostIdFormattingKey]: 'Specific Host ID formatting is required',
        [serialNumberFormattingKey]: 'Specific Serial Number formatting is required',
        [invalidHostKey]: 'Invalid Host',
        [invalidSerialNumberKey]: 'Invalid Serial Number',
        [invalidFileKey]: 'Invalid File',
        [reviewNumberHostKey]: 'Please review the number of hosts provided.',
        [exceedingQtyKey]: 'The number of hosts provided is causing the qty required to exceed the qty available.',
        [assignProductsQtyDistributionMessageProductErrorKey]: 'Product',
        [assignProductsQtyDistributionMessageHasErrorKey]: 'has',
        [assignProductsQtyDistributionMessageAvailableErrorKey]: 'available, but needs',
        [assignProductsQtyDistributionMessageEnableErrorKey]: 'to enable assignment to all provided hosts (',
        [assignProductsQtyDistributionMessageAppliedErrorKey]: 'to be applied to each of the',
        [assignProductsQtyDistributionMessageHostsErrorKey]: 'hosts)',
        [invalidHostColonKey]: 'Invalid hosts: ',
        [duplicateHostKey]: 'One or more of the host ids provided is not unique, all host ids must be unique.',
        [assignProductDuplicateHostIDMessageErrorKey]: 'Host id',
        [assignProductDuplicateHostUniqueMessageErrorKey]: 'is not unique, please review and eliminate the duplicate entries.',
        [assignProductsOnePerHostTitleProductErrorKey]: 'Product ',
        [assignProductsOnePerHostTitleLimitErrorKey]: ' has a limit of 1 per host and cannot be added again.',
        [onePerHostMessageKey]: 'The product is already assigned to the same host and cannot be added again.',
        [genericErrorTitleKey]: 'There was an issue while processing your request.',
        [assignProductsGenericMessagePleaseErrorKey]: 'Please ',
        [assignProductsGenericMessageIssueErrorKey]: ' if the issue persists.',
        [specificHostFileKey]: 'Specific Host File is required',

        [findViewCertKey]: 'Find/View Certificates',
        [myCertKey]: 'My Certificates',
        [orderNumberKey]: 'Order Number',
        [purchaseOrderKey]: 'Purchase Order',
        [searchForKey]: 'Search for...',
        [exportAllExcelKey]: 'Export to Excel',
        [viewPdfKey]: 'View PDF',
        [requestLicensesNowKey]: 'Request Licenses Now',
        [certNumberKey]: 'Certificate Number',
        [poNumberKey]: 'PO Number',
        [productKey]: 'Product',
        [termKey]: 'Term',
        [startDateKey]: 'Start Date',
        [endDateKey]: 'End Date',
        [qtyPurchasedKey]: 'Qty Purchased',
        [qtyRemainingKey]: 'Qty Remaining',
        [contactUsHeaderKey]: 'Please use the link on the header to contact us if this issue persists.',
        [loadingPeriodsKey]: 'Loading...',
        [genericEmptyGridKey]: 'No records are available to display.',

        [noDataAvailableKey]: 'There is no data available',
        [addCertAccountKey]: 'Please Add a Certificate to Associate Your License Entitlements to Your Account.',
        [findCertKey]: 'Find Certificate',
        [orderCertificateDetailsKey]: 'Order/Certificate Details',
        [addCertKey]: 'Add Certificate',
        [addCertSuccessKey]: 'Certificate Successfully Added',
        [clickRequestLicenseNowKey]: 'Click on Request Licenses Now.',
        [enterValidCertKey]: 'Please enter a valid order and certificate number',
        [validOrderProvidedKey]: 'A valid order number and certificate number must be provided.',
        [certNotFoundKey]: 'Order/Certificate not found',
        [confirmOrderKey]: 'Please confirm the order and certificate numbers and try again.',
        [certScalableKey]: 'This certificate is for US Scalable products.',
        [addCertEggplantFoundMessageKey1]: 'Please click ',
        [addCertEggplantFoundMessageKey2]: 'here',
        [addCertEggplantFoundMessageKey3]: ' to add your certificate.',
        [certAssociatedKey]: 'This certificate is already associated to your profile.',
        [certRedeemLicenseKey]: 'Please use Request New Licenses to redeem your licenses for this certificate.',
        [certNoEntitlementKey]: 'This certificate is valid but has no entitlements to add to your profile.',
        [entitlementOnHostTitleKey]: 'Certificate Successfully Added',
        [entitlementOnHostMessageKey]: 'There are one or more entitlements on this certificate that are not available at this time. We are working towards resolving this and a representative will contact you with further instructions if needed.',
        [profileItarTitleKey]: 'Certificate Successfully Added With Exceptions',
        [profileItarMessageLACKey]: 'These are ITAR-controlled products.',
        [profileItarMessageKey]: 'These are ITAR-controlled products. Please click ',
        [profileItarMessageKeyTwo]: 'here',
        [profileItarMessageKeyThree]: ' to acknowledge the terms of use.',
        [acceptKey]: 'Accept',
        [declineKey]: 'Decline',
        [uploadFileKey]: 'Upload File',
        upload: {
            cancel: 'Cancel',
            clearSelectedFiles: 'Clear',
            dropFilesHere: 'Drop file here to upload',
            headerStatusUploaded: 'Done',
            headerStatusUploading: 'Uploading...',
            invalidFileExtension: 'File type not allowed.',
            invalidFiles: 'Invalid file(s). Please check file upload requirements.',
            invalidMaxFileSize: 'File size too large.',
            invalidMinFileSize: 'File size too small.',
            remove: 'Remove',
            retry: 'Retry',
            select: 'Select files...',
            statusUploaded: 'File successfully uploaded.',
            statusUploadFailed: 'File unsuccessfully uploaded.',
            uploadSelectedFiles: 'Upload',
            total: 'Total',
            files: 'files'
        },

        grid: {
            groupPanelEmpty: "Drag a column header and drop it here to group by that column",
            pagerItemsPerPage: "items per page",
            pagerInfo: "{0} - {1} of {2} items",
            pagerFirstPage: "Go to the first page",
            pagerPreviousPage: "Go to the previous page",
            pagerNextPage: "Go to the next page",
            pagerLastPage: "Go to the last page",
            pagerPage: "Page",
            pagerOf: "of",
            filterClearButton: "Clear",
            filterEqOperator: "Is equal to",
            filterNotEqOperator: "Is not equal to",
            filterIsNullOperator: "Is null",
            filterIsNotNullOperator: "Is not null",
            filterIsEmptyOperator: "Is empty",
            filterIsNotEmptyOperator: "Is not empty",
            filterStartsWithOperator: "Starts with",
            filterContainsOperator: "Contains",
            filterNotContainsOperator: "Does not contain",
            filterEndsWithOperator: "Ends with",
            filterGteOperator: "Is greater than or equal to",
            filterGtOperator: "Is greater than",
            filterLteOperator: "Is less than or equal to",
            filterLtOperator: "Is less than",
            filterIsTrue: "Is true",
            filterIsFalse: "Is false",
            filterBooleanAll: "(All)",
            filterAfterOrEqualOperator: "Is after or equal to",
            filterAfterOperator: "Is after",
            filterBeforeOperator: "Is before",
            filterBeforeOrEqualOperator: "Is before or equal to",
            noRecords: "No records are available to display.",
            filterAriaLabel: "Filter",
            filterCheckAll: "Check All",
            filterChooseOperator: "Choose Operator",
            filterSelectedItems: "selected items",
            filterSubmitButton: "Filter",
            filterTitle: "Filter",
            filterAndLogic: "And",
            filterOrLogic: "Or",
            groupColumn: "Group Column",
            pagerTotalPages: "{0}",
            searchPlaceholder: "Search",
            sortAriaLabel: "Sortable",
            sortAscending: "Sort Ascending",
            sortDescending: "Sort Descending",
            ungroupColumn: "Ungroup Column"
        },

        [mailAllKey]: 'Email All Licenses',
        [emailLicensesDirectionsKey]: 'Email Licenses to (separated by commas):',
        [invalidEmailKey]: 'Invalid email',
        [emailValidationMessageKey]: 'Please check the email address format. If you enter multiple addresses, separate them with a comma.',
        [emailSuccessKey]: 'Email successfully sent.',
        [emailReceiveKey]: 'You should receive your licenses shortly',
        [cancelKey]: 'Cancel',
        [sendLicenseKey]: 'Send',

        [noLicensesRedeemKey]: "No licenses are available to redeem.",
        [productsHostKey]: "Assign Products",
        [productsHostsKey]: "Assign Products to Hosts",
        [licensePreviewKey]: "License Preview",
        [licenseDownloadKey]: "License Download",
        [navigationAlertKey]: "Are you sure you want to leave this page? This will cause any work in progress to be lost.",
        [myProductsKey]: "My Products",
        [assignQtyKey]: "Assign Qty",
        [licenseExpirationDateKey]: "License Expiration Date",
        [supportExpirationDateKey]: "Support Expiration Date",
        [newLicensedProductsKey]: 'My New Licensed Products',
        [assignMoreProductsKey]: 'Assign More Products',
        [requestMoreKey]: "Request More",
        [downloadAllLicensesKey]: 'Download All Licenses',
        [cloudLicenseKey]: 'HLS Cloud License',
        [cloudLicenseMessageKey]: 'Your license is available in License Browser, which opens automatically when you launch Nemo Handy/Nemo Walker Air application on your handset.',
        [processingCompletedKey]: 'Processing completed!',
        [licensesReadyKey]: 'Your licenses are ready. Click on the Download or Email button to obtain your license files.',
        [myAssignedProductsKey]: "My Assigned Products",
        [requestLicensesKey]: "Request Licenses",
        [generateLicensesKey]: "Are you sure you want to generate licenses?",
        [generateLicensesMessageKey]: "This task cannot be undone once a host is assigned and a license is generated.",
        [pleaseCancelKey]: "Please Cancel",
        [yesSureKey]: "Yes, I am Sure",
        [newlyAssignedProductsKey]: "Newly Assigned Products",
        [downloadLicensesKey]: "Download licenses",
        [loadingGenerationKey]: "This license is being generated. Please be patient.",
        [removeProductKey]: "Click to remove this product.",
        [requestProcessingKey]: "Your request is being processed.",
        [requestProcessingMessageKey]: "Your licenses are being generated and should be available soon to be downloaded or emailed.",
        [generationTimeoutKey]: "License generation timed out.",
        [notGeneratedSupportAgentKey]: "KSM could not generate one or more licenses. A support agent will deliver your licenses via email.",
        [errorOccurredRequestKey]: "An error occurred while processing your request.",
        [notGeneratedAvailableRedeemKey]: "KSM could not generate one or more licenses. Your product(s) will remain available for redemption. Use the link in the header to contact Keysight if the issue persists.",
        [noQuantityKey]: "No Quantity Selected",
        [noQuantityMessageKey]: "Please enter quantity before proceeding",
        [redeemNotAvailableErrorKey]: "KSM could not generate one or more licenses. Use the link in the header to contact Keysight if the issue persists.",
        [cannotGenerateKey]: "License(s) cannot be generated.",
        [cannotGenerateMessageKey]: "The quantity requested is not available due to licenses that were assigned in another session.",

        [enterpriseLicensingKey]: "Enterprise Licensing",
        [selectProductsKey]: "Select Products",
        [selectMoreKey]: "Select More",
        [daysLeftKey]: 'Days Left',
        [licenseTypeKey]: "License Type",
        [costPerUnitKey]: "Cost Per Unit",
        [totalKey]: "Total",
        [searchProductsKey]: "Search Products",
        [searchSoftwareFamilyKey]: "Search Software Family",
        [anyKey]: "Any",
        [searchKey]: "Search",
        [myCartKey]: "My Cart",
        [statusKey]: "Status",
        [saveSelectionsKey]: "Save Selections",
        [exportSelectionsKey]: "Export Selections",
        [hostIdKey]: "Host ID",
        [selectedItemCannotAddedKey]: "Selected item cannot be added.",
        [selectedItemCannotAddedMessageKey]: "The cost of the selected items exceeds the available subscription pool.  Please reduce the quantity or choose another product or license type.",
        [unassignedKey]: "Unassigned",
        [assignedKey]: "Assigned",
        [cannotBeProcessedKey]: "This change cannot be processed.",
        [changeExceedsPoolKey]: "Last change made exceeds the available subscription pool and cannot be processed.",
        [confirmDeletionKey]: "Please Confirm Deletion",
        [deleteUnassignedKey]: "Delete UNASSIGNED only",
        [deleteAllKey]: "Delete All",
        [hostKey]: "Host",
        [changeCannotSaveKey]: 'This change cannot be saved.',
        [changeAnotherSessionKey]: 'Changes made in another session consumed all available subscription pool. Please reduce the quantity or remove products from the cart to proceed.',
        [NoRecordsSearchKey]: 'No records are available to display. Please search for products to add to cart.',
        [emptySearchKey]: 'Your search returned no results. Please change your search options and try again.',
        [requestLicenseSummaryKey]: 'Request Licenses Summary',
        [generationConfirmationKey]: 'Your requests cannot be changed after licences are generated.',
        [specialHandlingKey]: "Special Handling",
        [keysightGenerateLicensesContactKey]: "This product requires Keysight to generate your licenses. A Support agent will contact you to assist with generating your licenses.",
        [requestLicensesHostKey]: "Request licenses for this host",
        [doNotRequestLicenseKey]: "Do not request license for this host",
        [emailSelectedLicensesKey]: "Email Selected Licenses",
        [downloadSelectedLicensesKey]: "Download Selected Licenses",

        [registerLoginEa]: "How to Register and Login to EAs",
        [managePeriodicEa]: "How to Manage Periodic EAs",
        [manageVariableEa]: "How to Manage Variable EAs",
        [howToEA]: '\'How To\' Videos for Enterprise Agreements',
        [saveChangesKey]: "Save Changes",
        [infoKey]: "Info",
        [incompatibleProductsSelectedKey]: "This product is not compatible with the products selected. To work with this product, please uncheck all checked rows.",
        [keysightGenerateLicensesV2Key]: "This product requires Keysight Support to generate licenses. <br/> When you request a license, a Support Agent will contact you to assist with generating your license.",
        [okKey]: "OK",
        [hostPendingSpecialKey]: "Host Assignment Pending (Special Handling Required)",
        [deleteProductHostKey]: "Delete product from host",
        [deleteHostKey]: "Delete host",
        [poolOverKey]: "Subscription pool is over the limit.",
        [durationIncreaseCostKey]: "Reducing the duration of a license increased the cost, which has caused your total cost to exceed the available subscription pool. This change will not be possible until you free up enough subscription pool.",
        [onePerHostKey]: "Products below are limited to 1 per host.",
        [alreadyAssignedKey]: "These products are already assigned to the specified host.",
        [incorrectNumberImeiKey]: "Incorrect number of IMEI hosts provided.",
        [imeiAssignedQuantityKey]: "The number of IMEIs provided must be a factor of the assigned quantity so that KSM can assign the same number of licenses to each host. For example, if the quantity is 10, you can provide one, two, five, or ten IMEIs. If you provide five, KSM assigns two per host.",
        [endDateExceedPoolKey]: 'Extending the end date is causing your total cost to exceed the available subscription pool for a future year.',
        [endDateNotPossibleKey]: 'This end date change will not be possible. This happens when your subscription pool for the coming year is smaller than the one for the current year.',
        [duplicateAliasKey]: "Duplicate Alias",
        [aliasUniqueKey]: "Host aliases must be unique. The alias entered is already associated to a host. Please enter a different alias.",
        [quantityConsumedKey]: "Quantity Consumed",
        [quantityConsumedSessionKey]: "Quantity requested is already consumed in another session. Please refresh your screen.",

        [reassignLicensesKey]: "Reassign Licenses",
        [selectLicensesRenewKey]: "Select Licenses",
        [renewLicensesKey]: "EEsof License Renewal",
        [renewableLicensesKey]: "My Renewable Licenses",
        [reassignBeforeProceedingKey]: "(Reassign products to hosts as needed below before proceeding)",
        [productDescriptionKey]: "Product Description",
        [notesKey]: "Notes",
        [versionKey]: "Version",
        [reassignKey]: "Reassign",
        [rehostThisLicenseKey]: "Rehost this license",
        [undoHostChangesKey]: "Undo Changes",
        [continueRenewLicensesKey]: "Renew Licenses",
        [downloadThisLicenseKey]: "Download this license.",
        [availableRenewKeys]: 'Your licenses will continue to be available to renew.',
        [requestSuccessfullyKey]: 'License request submitted successfully.',
        [generatedRetrieveLicensesKey]: 'Once your licenses are generated you can use the buttons on the screen to receive your licenses via download or email.',
        [renewMoreLicencesKey]: 'Renew More Licenses',
        [rehostedFromKey]: 'Rehosted From',
        [quantityToReassignKey]: 'Quantity to Reassign',
        [invalidKey]: 'Invalid',
        [renewEesofOnePerHostErrorTitle]: 'The following product has a limit of 1 per host and cannot be added again',

        [totalPoolKey]: "Total Subscription Pool",
        [consumedKey]: "Consumed",

        [eaLicenseViewKey]: "EA License View",
        [customerInformationKey]: "Customer Information",
        [eaDetailsKey]: "Enterprise Agreement Details",
        [remixTypeKey]: "Remix Type",
        [currencyKey]: "Currency",
        [periodKey]: "Period",
        [toKey]: "To",
        [groupByHostIdKey]: "Group By Host ID",
        [groupByProductsKey]: "Group By Product",
        [showExpiredLicensesKey]: "Show Expired Licenses",
        [totalCostKey]: "Total Cost",
        [hostCostKey]: "Host Cost",
        [assignedProductsKey]: "Assigned Products",
        [assignedHostsKey]: "Assigned Hosts",
        [transportableLicensesSavedKey]: "Transportable Licenses Saved to KSM",
        [specialHandlingRequiredKey]: "(Special Handling Required)",
        [redeemTransportableLicensesKey]: "In order to redeem your transportable licenses again, please use KSM Home and click on the \"You can request new licenses\" button.",
        [keysightGenerateLicensesKey]: "This product requires Keysight support to generate license.",
        [hostTotalKey]: "Host Total",
        [assignMoreKey]: "Assign More",
        [assignMoreLicensesKey]: "Assign More Licenses",
        [exportToExcelKey]: "Export to Excel",
        [openKey]: "Open",
        [noLicensesPeriodKey]: "No licenses to display for this period.",

        [eARequestAgainKey]: "EA Request Again License View",
        [requestAgainLicenseViewKey]: "Request Again License View",
        [fromExpiryDateKey]: "From Expiry Date",
        [toExpiryDateKey]: "To Expiry Date",
        [licensesExpireDateRangeKey]: "Licenses Expiring in Date Range",
        [subscriptionPoolSelectedKey]: "The cost of the selected items exceeds the available subscription pool.",
        [subscriptionPoolFreedKey]: "The subscription pool is freed 5 days prior to expiry date.",
        [totalSubscriptionPoolAvailableKey]: "Total subscription pool available",
        [totalCostSelectedKey]: "Total cost of selected product",
        [excessAmountKey]: "Excess amount",
        [pleaseEditSelectedKey]: "Please edit selection of products to continue",
        [toRequestAgainKey]: "to Request Again",
        [startDateSetKey]: "Start date will be set to today's date.",
        [endDateSetKey]: "End date will be set based on previous duration of the license.",
        [endDateQtyEditKey]: "End date or quantity can be edited on the next screen.",
        [subscriptionPoolConsumeKey]: "This will consume ",
        [subscriptionPoolFrom]: " from your subscription pool.",
        [productNotAvailable]: "Product not available to be redeemed",
        [enterHostIdKey]: 'Enter Host ID',
        [selectLicensesKey]: 'Select Licenses',
        [requestLicenseKey]: 'Request License',
        [downloadAndConfirmKey]: 'Download & Confirm',
        [activationCodeKey]: 'Activation Code',
        [entitlementCodeKey]: 'Entitlement Code',
        [qtyCurrentlyAssignedKey]: 'Qty Currently Assigned',
        [qtyDeactivateKey]: 'Qty to Deactivate',
        [qtyKeepKey]: 'Qty to Keep',
        [addARequestFileKey]: 'Add Request File',
        [uploadFileDeactivationKey]: 'Upload File for Deactivation',
        [searchDeactivateKey]: 'Search for Other Host to Deactivate',
        [findHostKey]: 'Find Host',
        [orKey]: 'Or',
        [currentLicensesKey]: 'Current Licenses',
        [continueKey]: 'Continue',
        [clearAllQuantitiesKey]: 'Clear All',
        [deactivateAllKey]: 'Deactivate All',
        [iAmDeactivatingKey]: 'Licenses I am Deactivating',
        [iAmKeepingKey]: 'Licenses I am Keeping',
        [licenseGeneratingKey]: 'Your license is being generated.',
        [processNextStepKey]: 'This process may take a few seconds, KSM will advance to the next step once your license files are ready.',
        [deactivationFailedKey]: 'Your License Deactivation Request Failed',
        [genericErrorContactUsKey]: 'There was an issue while processing your request.\nPlease use the link on the header to contact us if this issue persists.',
        [failedGenerationTimeKey]: 'Your license could not be generated at this time',
        [supportTeamDeactivationKey]: 'Our support team will fix this issue and send the license via email. Once you have your license, please restart your deactivation in order to confirm the transaction.',
        [enterDeactiviationCodeKey]: 'Enter Deactivation Confirmation Code',
        [confirmCodeRequiredKey]: 'Confirmation Code Required',
        [confirmationCodeWarningKey]: 'Your license file is ready to be downloaded!\nAfter installing' +
        '                                        your license file, you will receive a deactivation confirmation code.' +
        '                                        Deactivation is not complete until the deactivation confirmation code is' +
        '                                        entered and submitted.\nPlease enter the confirmation code.',
        [incorrectConfirmationCodeKey]: 'The confirmation code provided does not match the expected value.',
        [confirmConfirmationCodeKey]: 'Please confirm and correct the confirmation code provided.',
        [deactivateMoreProductsKey]: 'Deactivate More',
        [deactivationCompleteKey]: 'Your deactivation is completed.',
        [reassignDeactivateKey]: 'You can re-assign your deactivated licenses now or later using the Request New License module.',
        [binFileMessageKey]: 'Please use a valid ".bin" request file from you client license manager or type in the host id string.',
        [licenseNoAssociationKey]: 'Host is available but licenses are not associated to your profile',
        [addCertDeactivationKey]: 'Please add the required certificates to your profile before requesting a deactivation for this host, or use an ILU request file instead of typing the host id.',
        [noLicenseDeactivationKey]: 'No licenses are available to deactivate for this host',
        [noLicenseDeactivationMessageKey]: 'Our records indicate that the host provided does not have any licenses available to deactivate',
        [deactivationNotSupportedKey]: 'This host does not support deactivate',
        [hostNoExistKey]: 'This host does not exist on our records',
        [confirmHostEnteredKey]: 'Please confirm if the host id was entered correctly.',

        [nemoSupportRenewalKey]: 'Nemo Support Renewal',
        [uploadC2vKey]: 'Upload C2V',
        [requestRenewalKey]: 'Request Renewal',
        [addC2vFileKey]: 'Add C2V File...',
        [pendingRenewalKey]: 'Hosts with Licenses Pending Renewal',
        [c2vUploadAppliedKey]: ' (C2V Uploaded and Applied)',
        [currentSupportEndDate]: 'Current Support End Date',
        [newSupportEndDateKey]: 'New Support End Date',
        [newEndDateKey]: 'New End Date',
        [validC2vKey]: 'Please use a valid “.c2v” file from your host.',
        [noPendingRenewalsKey]: 'The file provided did not match any pending renewals.',
        [renewNemoNoMatchPleaseProvideMessageErrorKey]: 'Please provide a ',
        [renewNemoNoMatchFileMatchMessageErrorKey]: ' file that matches one of your pending renewals.',
        [generationTimeoutMessageV2Key]: 'Your licenses could not be generated within the expected time. A support agent will contact you to deliver your license.',
        [licenseRenewedSuccessKey]: 'License(s) Renewed Successfully',
        [licenseRenewedKey]: 'Your license(s) were renewed.',

        [noSupportRenewalKey]: "No support subscriptions are available to renew.",
        [noLicensesRenewalKey]: "No licenses are available to renew.",
        [selectSubscriptionKey]: 'Select Subscription',
        [downloadLicensesUpperKey]: 'Download Licenses',
        [standaloneSubscriptionKey]: 'Standalone Subscription Renewal',
        [supportSubscriptionKey]: 'Support Subscriptions Available',
        [cannotSupportSubscriptionKey]: 'This support subscription cannot be used at this time.',
        [accountNoSupportProductKey]: 'Your KSM account does not have a product that corresponds to this support subscription.',
        [noSupportAvailableProductKey]: 'No more support subscriptions are available.',
        [renewStandaloneNoSubscriptionErrorSubtitleYouHaveKey]: 'You have ',
        [renewStandaloneNoSubscriptionErrorSubtitleSubscriptionKey]: ' subscriptions total and have selected ',
        [renewStandaloneNoSubscriptionErrorSubtitleToChangeKey]: ' licenses. To change a selection, you must first deselect a checkbox.',
        [supportRenewTogetherKey]: 'All support subscriptions for a given host must be renewed together',
        [selectAllRenewalsKey]: 'You have selected to renew subscriptions for the hosts below. In order to proceed with the renewal, please select all subscriptions for each host. If you need assistance, click the Contact Us link in the header.',
        [renewalSelectedKey]: 'Renew Selected',
        [renewMoreSubKey]: 'Renew More',
        [notGeneratedAvailableRedeemV2Key]: 'Your license have not been requested. Your products will remain available to redeem. Please use the link on the header to contact us if this issue persists.',
        [notGeneratedSupportAgentV2Key]: 'A support agent will contact you to deliver your license via email.',
        [youAreAllDoneKey]: 'You are all done!',
        [noLicenseFileForRenewalKey]: 'There are no licenses files to download for the requested renewals',

        [syncUploaderKey]: 'Upload File for Renewal or Sync',
        [addRequestFileKey]: 'Add Request File...',
        [searchHostSyncKey]: 'Search for a Host to Sync',
        [noLicensesSyncKey]: 'This host does not have any active licenses to sync',
        [hostNoRemainingLicensesSyncKey]: 'Sync cannot be requested as your host does not have any remaining licenses.',
        [confirmHostIdCorrectKey]: 'Please confirm if the host ID was entered correctly.',
        [notSupportedSyncKey]: 'This host does not support sync',
        [addCertSyncKey]: 'Please add the required certificates to your profile before requesting a sync for this host, or use an ILU request file instead of typing the host id.',
        [syncFailedKey]: 'Your license sync request failed',
        [hostReadySyncKey]: 'Host Ready to Sync',
        [syncedHostKey]: 'Synced Host',
        [requestSyncKey]: 'Request Sync',
        [syncMoreKey]: 'Sync More',
        [syncedSuccessKey]: 'License synced successfully',
        [generatedRetrieveLicensesV2Key]: 'Please use the buttons on the screen to receive your licenses via download or email.',

        [noHostsAvailableKey]: "No hosts are available.",
        [myHostsKey]: "My Hosts",
        [aliasKey]: "Alias",
        [assetInfoKey]: "Asset Information",
        [cityKey]: "City",
        [stateKey]: "State",
        [countryKey]: "Country",
        [licensesHostIdColonKey]: "Licenses for Host ID:",
        [prodNoKey]: "Prod No.",
        [sourceKey]: "Source",
        [licenseExpKey]: "License Exp.",
        [supportExpKey]: "Support Exp.",
        [goBackKey]: "Go Back",
        [editHostKey]: "Edit Host",
        [licenseNotifierHostIdColonKey]: "License Notifier Host ID:",
        [serialNumberKey]: "Serial Number",
        [hostMetaDataKey]: "Host Meta Data (Optional):",
        [removeHostKey]: "Remove Host",
        [areYouSureKey]: "Are You Sure?",
        [permanentlyDeleteHostIdKey]: "This action will permanently delete the following Host ID. This action cannot be undone.",
        [exportHostInfoKey]: "Export Host Information",

        [expiringLicenseSupportKey]: "Expiring Licenses and Support",
        [seeExpirationsWithinKey]: "See Expirations Within",
        [allExpirationKey]: "All",
        [thirtyDaysExpirationKey]: "30 Days",
        [sixtyDaysExpirationKey]: "60 Days",
        [oneHundredTwentyDaysExpirationKey]: "120 Days",
        [orderCertificateKey]: "Order/Certificate",
        [enterpriseAgreementsNotIncludedKey]: "Note: Enterprise Agreement licenses are not included",
        [pleaseUseLicenseViewKey]: "Please use the license view under the respective EA to see those.",
        [trailLicenseRequestKey]: "Trial License Request",
        [selectHostKey]: "Select Host",
        [requestSubmittedKey]: "Request Submitted",
        [enterHostInfoKey]: "Enter your host information",
        [trailLicenseSentKey]: "Your trial license should be sent to you shortly.",
        [durationKey]: "Duration",
        [quantityKey]: "Quantity",
        [requestAnotherTrailKey]: "Request Another",
        [contactInfoKey]: "Contact Information",
        [firstNameKey]: "First Name",
        [lastNameKey]: "Last Name",
        [emailKey]: "Email",
        [phoneKey]: "Phone",
        [companyNameKey]: "Company Name",
        [jobTitleKey]: "Job Title",
        [addr1LineKey]: "Address Line 1",
        [addr2LineKey]: "Address Line 2",
        [stateProvinceKey]: "State/Province",
        [zipPostalKey]: "Zip/Postal Code",
        [countryAreaKey]: "Country/Area",
        [submitRequestKey]: "Submit",
        [deniedTrailLicenseRequestKey]: "Trial license denied for the following request:",
        [productColonKey]: "Product:",
        [additionalTrailShareDetailsKey]: "If you wish to request an additional trial period for this host, or you believe you have received this message in error, please share the details of your request below:",
        [toColonKey]: "To:",
        [keysightTechnologiesKey]: "Keysight Technologies",
        [subjectColonKey]: "Subject:",
        [trailLicenseDeniedKey]: "Trial License Denied",
        [bodyColonKey]: "Body:",
        [invalidHostIdKey]: "Invalid Host ID",
        [ensureHostIdCorrectKey]: "Please ensure Host ID is correctly entered.",
        [trailLicenseNotAvailableKey]: "Trial license is not available for this Host ID",
        [trialDNEKey]: "The product either does not exist or is not ACTIVE",
        [notTrailDurationKey]: "The product does not have a trial duration greater than 0",
        [requiredFieldsMissing]: "Required fields missing",
        [completeRequiredFieldsKey]: "Please complete required fields before submitting your request.",
        [enterValidEmailKey]: "Please enter a valid email address.",
        [trailLicenseExistHostKey]: 'Your request for a free trial license was denied. This trial license has already been issued for host',
        [trialLicenseSubmitErrorSubtitle2Key]: 'If you wish to request an additional trial period for this host, or you believe you have received this message in error, please contact us by ',
        [trialLicenseSubmitErrorClickHereSubtitleKey]: 'clicking here',
        [trialLicenseSubmitSuccessKey]: "Trial license request successfully submitted",
        [trialLicenseSubmitSuccessSubtitle1Key]: 'We are processing your request. Your trial license for "',
        [trialLicenseSubmitSuccessSubtitle2Key]: '" should be sent to you shortly. <br/><br/> Thank you for choosing Keysight Technologies.',
        [messageSentKey]: "Your message was successfully sent",
        [reviewedByKeysightKey]: "Your request will be reviewed by a Keysight Technologies representative and you will be contacted shortly. <br/><br/> Thank you for choosing Keysight Technologies.",
        [encounteredErrorKey]: "We've encountered an error",
        [contactUsTrailLicenseKey]: "Please try again or <a class='hyperlink' href='https://www.keysight.com/contact.html' target=\"_blank\" rel=\"noopener noreferrer\">Contact Us</a> for further assistance.",
        [trialLicensePrivacyStatementKey]: "By continuing, you agree to the <a class='hyperlink' href='https://www.keysight.com/find/privacy' target=\"_blank\" rel=\"noopener noreferrer\">Privacy Statement</a>, which explains how we collect, store, and use your personal data.",

        [pageNotFoundKey]: 'Page Not Found',
        [pageNotFoundPleaseKey]: 'Please ',
        [pageNotFoundClickHereKey]: 'click here',
        [pageNotFoundGoToKey]: ' to go to the home page.',

        [pageCannotLoadKey]: 'Page Cannot be Loaded',
        [pageCannotLoadPleaseKey]: 'Please ',
        [pageCannotLoadClickHereKey]: 'click here',
        [pageCannotLoadGoToKey]: ' to go to the home page.',

        [topicsKey]: 'Topics',
        [frequentlyAskedQuestionsKey]: 'Frequently Asked Questions',
        
        //welcome help
        [helpWelcomeHeaderKey]: 'KSM Home Page Help',
        [quickStepsGetLicensesKey]: 'Quick Steps to Get Licenses',
        [ksmOverviewKey]: 'KSM Overview',
        [registrationLoginKey]: 'Registration and Login',
        [homePageKey]: 'Home Page',
        [loginAndRegistrationKey]: 'Login and Registration',
        [entitlementCertificatesKey]: 'Entitlement Certificates',
        [licensesKey]: 'Licenses',
        [helpWelcomeQuickStepsSectionKey]: {
            'section_one': 'To get your licenses quickly, follow the steps below:',
            'section_one_bullets': [
                'Add your entitlement certificate to your account. If you are using KSM for the first time, you did this when you created your account.',
                'On Home or Navigation Menu, click <b>Request New Licenses</b>.',
                'Redeem your licenses by selecting a quantity and assigning to a host.',
                'The requested licenses are generated and available to download or email in a few seconds.'
            ]
        },
        [helpWelcomeKSMOverviewSectionKey]: {
            'section_one': 'Keysight Software Manager (KSM) is an enterprise web-based application that allows our customers to manage their Keysight software licenses. With KSM, you can:',
            'section_one_bullets': [
                'Add new entitlement certificates to your account',
                'Redeem licenses',
                'Download your available SW updates',
                'View your existing entitlements, licenses, and hosts',
                'Perform off-line activation, deactivation and sync (Ixia products only)',
                'Access your enterprise agreement and get your enterprise licenses'
            ],
            'section_two': 'When you order software, Keysight emails or ship you an entitlement certificate for each product.',
            'section_three': 'The order number and certificate number located at the top of the certificate allow you to access to KSM and obtain your license.',
            'section_three_bullets': []
        },
        [helpWelcomeKSMRegistrationLoginSectionKey]: {
            'section_one': 'KSM shares the registration and login process with Keysight.com as well as other Keysight applications. If you already have a Keysight account, you can use that account to log in and access KSM by adding your Entitlement Certificate.  If you don’t already have an account, you can register using you order number and certificate number provided on your Entitlement Certificate.'
        },
        [helpWelcomeKSMWelcomePageHeaderKey]: 'KSM Home Page',
        [helpWelcomeKSMWelcomePageSectionKey]: {
            'section_one': 'The links on the Home page are organized into six tiles. The page is tailored to your account to let you access the functionality you need, and your licenses and their status determine what is available. Most users will not see all of the tiles described here.',
            'tiles': {
                'Entitlement Management': 'lets you add certificates to your account and view your existing certificates.',
                'License Management': 'lets you redeem licenses, activate/deactivate/sync your Ixia products, or apply a support renewal to existing licenses.',
                'Software Updates': 'provides access to available software updates.',
                'Licenses and Hosts': 'displays details about your licenses, host IDs, license and support expiration dates, and available entitlements.',
                'Software Access Licenses': 'lets selected Keysight Partners manage their Software Access licenses.',
                'Enterprise Agreements': 'lets users who have active Enterprise Agreements manage their licenses.'
            }
        },

        [helpWelcomeLoginRegistrationQAKey]: [
            {
                'Q': 'I do not have an account on Keysight. What do I need to register?',
                'A': 'You need the order number and certificate ID located at the top of your entitlement certificate to access KSM.'
            },
            {
                'Q': 'I have an account but forgot my password. How do I reset it?',
                'A': 'Click the “Forgot your password” link on the login page to request a password reset.'
            }
        ],
        [helpWelcomeWelcomePageQAKey]: [
            {
                'Q': 'I would like to see KSM in a different language. How do I change the language?',
                'A': 'KSM supports a few additional languages. Use the globe link on the header to change to the desired country. If that country’s language is not available, the text will display in English.'
            }
        ],
        [helpWelcomeEntitlementCertificatesQAKey]: [
            {
                'Q': 'What is an Entitlement Certificate?',
                'A': 'The certificate is the document provided by Keysight that entitles you to obtain licenses.'
            },
            {
                'Q': 'I just purchase software from Keysight and got a new certificate. How do I get my licenses?',
                'A': 'Go to www.keysight.com/find/software manager. If you already have an account, log in and add the certificate, then click Request Licenses Now. If you do not have an account, register using your certificate, then click Request New Licenses under the License Management tile.'
            },
            {
                'Q': 'I have a single entitlement certificate for multiple products and/or quantities, but different people need to install and manage the licenses. How can we share the certificate?',
                'A': 'Multiple users can manage licenses from one certificate. Share the order number and certificate number with your colleagues so they can also register in KSM and obtain their licenses. Because KSM keeps track of the licenses redeemed for each certificate, licenses can be obtained by different accounts until they have all been obtained.'
            },
            {
                'Q': 'Why does my certificate show zero quantity?',
                'A': 'All available licenses have been redeemed.'
            },
        ],
        [helpWelcomeLicensesQAKey]: [
            {
                'Q': 'How do I obtain my licenses?',
                'A': 'After you add the entitlement certificate to your account, click Request New License. Select your product and quantity and assign a host ID, then click Assign Licenses. After the licenses are generated, download or email the license files and installation instructions.'
            },
            {
                'Q': 'How do I install licenses?',
                'A': 'Refer to the installation instructions that are included with the emailed or downloaded license file. If you still need help, please use the contact us link on the header of the KSM pages.'
            },
            {
                'Q': 'How can I see all my existing entitlements, licenses, host IDs, and expiration dates or licenses and support?',
                'A': 'On Home or Navigation Menu, click View by Product or Host under Licenses and Hosts.'
            }
        ],
        
        //Add Cert help
        [addingCertificatesKey]: 'Adding Certificates',
        [helpAddCertificateFaqKey]: 'Add Certificate FAQ',
        [quickStepsObtainingLicensesKey]: ' Quick Steps to Obtaining Licenses',
        [obtainingLicensesKey]: 'Obtaining Licenses',
        [helpAddCertificateRegLoginFaqQAKey]: [{
            Q: 'I do not have an account on Keysight. What do I need to register?',
            A: 'You need the order number and certificate ID located at the top of your entitlement certificate to access KSM.'
        }, {
            Q: 'I have an account but forgot my password. How do I reset it?',
            A: 'Click the “Forgot your password” link on the login page to request a password reset.'
        }],
        [helpAddCertificateWelcomePageFaqQAKey]: [
            {
                Q: 'I would like to see KSM in a different language. How do I change the language?',
                A: 'KSM supports a few additional languages. Use the globe link on the header to change to the desired country. If that country’s language is not available, the text will display in English.'
            }],
        [helpAddCertificateObtainLicenseQAKey]: [{
            Q: 'How do I obtain my licenses?',
            A: 'After you add the entitlement certificate to your account, click Request New License. Select your product and quantity and assign a host ID, then click Assign Licenses. After the licenses are generated, download or email the license files and installation instructions.'
        }, {
            Q: 'How do I install licenses?',
            A: 'Refer to the installation instructions that are included with the emailed or downloaded license file. If you still need help, please use the Contact Us link in the header to request assistance.'
        },
            {
                Q: 'How can I see all my existing entitlements, licenses, host IDs, and expiration dates or licenses and support?',
                A: 'On Home or Navigation Menu, click View by Product or Host under Licenses and Hosts.'
            }
        ],
        [helpAddCertificateEntitlementCertFaqQAKey]: [{
            Q: 'What is an Entitlement Certificate?',
            A: 'The certificate is the document provided by Keysight that entitles you to obtain licenses.'
        },
            {
                Q: 'I have a single entitlement certificate for multiple products and/or quantities, but different people need to install and manage the licenses. How can we share the certificate?',
                A: 'Multiple users can manage licenses from one certificate. Share the order number and certificate number with your colleagues so they can also register in KSM and obtain their licenses. Because KSM keeps track of the licenses redeemed for each certificate, licenses can be obtained by different accounts until they have all been obtained.'
            }],
        [helpAddCertificateQAKey]: [{
            'Q': 'I just purchased software from Keysight and got a new certificate. How do I get my licenses?',
            'A': 'Go to www.keysight.com/find/software manager. If you already have an account, log in and add the certificate, then click Request Licenses Now. If you do not have an account, register using your certificate, then click Request New Licenses under the License Management tile.'
        }, {
            'Q': 'Why does my certificate show zero quantity?',
            'A': 'All available licenses have been redeemed.'
        }],
        [helpAddCertificateSectionKey]: {
            'section_one': 'Each time you purchase software, you need to add the entitlement certificate to your profile before you can redeem your licenses.',
            'section_two': 'To get access to KSM you need at least one entitlement certificate added to your account. This is automatically done at registration. If you already have an account and have a new certificate, you will need to add it to your account.',
            'section_three': 'To add a new certificate:',
            'section_three_bullets': [
                "On Home or Navigation Menu, click  <b>Add New Certificate</b>.",
                "Enter the order number and certificate number located in the upper-right corner of your certificate, then click <b>Find Certificate.</b>",
                "When the certificate details appear, click <b>Add Certificate.</b>",
                "If you would like to get your software licenses now, click <b>Request Licenses Now.</b> <br/> <br/> <b>Note:</b> The <b>Request Licenses Now</b> link is disabled when your certificate has no licenses remaining to be redeemed."
            ]
        },
        [helpAddCertificateObtainLicenseSectionKey]: {
            'section_one': 'You can obtain licenses after your certificate is added to your account.',
            'section_one_bullets': [
                'Ensure your entitlement certificate has been added to your account.',
                'On Home or Navigation menu, click <b> Request New Licenses </b>.',
                'Select the quantity and assign a host, then click <b>Assign Licenses</b>.',
                'After your license is generated, choose either the <b>Download or eMail</b> option. You will receive the license file and installation instructions.'
            ]
        },
        [helpAddCertificateKSMOverviewSectionKey]: {
            'section_one': 'Keysight Software Manager (KSM) is an enterprise web-based application that allows our customers to manage their Keysight software licenses. With KSM, you can:',
            'section_one_bullets': [
                'Add new entitlement certificates to your account',
                'Redeem licenses',
                'Download your available SW updates',
                'View your existing entitlements, licenses, and hosts',
                'Perform off-line activation, deactivation and sync (Ixia products only)',
                'Access your enterprise agreement and get your enterprise licenses'
            ],
            'section_two': 'When you order software, Keysight emails or ship you an entitlement certificate for each product. The order number and certificate number located at the top of the certificate allow you to access to KSM and obtain your license.'
        },
        [helpAddCertificateRegLoginSectionKey]: {
            'section_one': 'KSM shares the registration and login process with Keysight.com as well as other Keysight applications. If you already have a Keysight account, you can use that account to log in and access KSM by adding your entitlement certificate. If you don’t already have an account, you can register using you order number and certificate number provided on your entitlement certificate.'
        },
        
        //deactivate help
        [helpDeactivateEntitlementCertFaqQAKey]: [
            {
                Q: 'What is an Entitlement Certificate?',
                A: 'The certificate is the document provided by Keysight that entitles you to obtain licenses.'
            },
            {
                Q: 'I have a single entitlement certificate for multiple products and/or quantities, but different people need to install and manage the licenses. How can we share the certificate?',
                A: 'Multiple users can manage licenses from one certificate. Share the order number and certificate number with your colleagues so they can also register in KSM and obtain their licenses. Because KSM keeps track of the licenses redeemed for each certificate, licenses can be obtained by different accounts until they have all been obtained.'
            },
            {
                Q: 'I bought my licenses from Ixia a few years back and do not have entitlement certificates. How do I associate them to my account?',
                A: 'Please click the “Contact us” link at the top of the page to request assistance. A Keysight Representative will contact you and provide your entitlement certificates.'
            }
        ],
        [helpDeactivateLicensesFaqQAKey]: [
            {
                Q: 'How do I install my new license files?',
                A: 'Refer to the installation instructions that are included with the emailed or downloaded license files. If you still need help, please use the “Contact us” link at the top of the page to request assistance. '
            },
            {
                Q: 'Why were my expired time-based licenses removed from my license files?',
                A: 'KSM cannot generate a license file for expired time-based licenses. They sync process removes expired licenses.'
            },
            {
                Q: 'How can I see all my existing entitlements, licenses, host IDs, and expiration dates or licenses and support?',
                A: 'On Home or Navigation Menu, click “View by Product or Host” under Licenses and Hosts.'
            },
            {
                Q: 'I missed my confirmation code. How can I get it?',
                A: 'Your license manager may be able to display the last confirmation code. Please refer to your host documentation. If you cannot locate the code, please use the “Contact us” link at the top of the page to request assistance.'
            }
        ],
        [deactiveLicenseKey]: 'Deactivate License',
        [deactiveLicenseTopicKey]: 'Deactivate License Topic',
        [deactiveLicenseFaqKey]: 'Deactivate License FAQ',
        [quickStepsDeactivationKey]: 'Quick Steps to Deactivation',
        [navigationKey]: 'Navigation',
        [deactivatingLicensesKey]: ' Deactivating Licenses',
        [specifyingTheHostKey]: 'Specifying the Host',
        [specifyingDeactivateKey]: 'Specifying Licenses to Deactivate',
        [requestingLicensesKey]: 'Requesting Licenses',
        [obtainingConfirmingDeactivationKey ]: 'Obtaining Licenses and Confirming Deactivation',
        [deactivationConfirmationKey]: 'Deactivation Confirmation',
        [errorHandlingKey]: 'Error Handling',
        [helpDeactivateQuickStepsSectionKey]: {
            section_one: 'The following process allows you to deactivate licenses. The topics in this help file explain these steps in more detail.',
            section_one_bullets: [
                {
                    bullet_point: 'Click <b><u>Deactivate</u></b> License on Home or Navigation Menu.',
                    sub_bullet_point: 'Note: The  <b>Deactivate Licenses</b> page lets you <a class="navgation-link" href="#navigation"> navigate </a>   through the steps to deactivate one to all licenses assigned to a host.'
                },
                {
                    bullet_point: '<a class="navgation-link" href="#specify-host">  Specify the host  </a>on which you want to deactivate licenses. You can either upload a file generated by the ILU tool or enter the host ID.'
                },
                {
                    bullet_point: '<a class="navgation-link" href="#specify-license">Specify the quantity</a> you want to deactivate for each product installed on the host.'
                },
                {
                    bullet_point: 'Confirm that the quantities are correct and <a class="navgation-link" href="#request-license"> request the license.</a>'
                },
                {
                    bullet_point: '<u>Download and install <a class="navgation-link" href="#obtain-license"> the license file:</a></u>',
                    sub_bullet_points: [
                        'Obtain the license file via download or email.',
                        'Install the file on the host.'
                    ]
                },
                {
                    bullet_point: 'Confirm the deactivation',
                    sub_bullet_points: [
                        'When you receive a confirmation code from the host’s licensing manager, enter it in KSM and click <b>Confirm Deactivation.<b>'
                    ],
                },
            ],
            section_two: 'Note: If you exit KSM before you enter the confirmation code, you can complete the process later. When you return and click <b>Deactivate Licenses</b>, KSM will take you to the appropriate page and allow you to complete the confirmation.'
        },
        [helpDeactivateNavigationSectionKey]: {
            section_one: 'To navigate through each step:',
            section_one_bullets: [
                'The arrow buttons ( < and > ) let you navigate to the previous/next step. The buttons are disabled (grayed out) if it is not possible to go to the previous/next step.',
                'Click the step number to move to a specific step. Navigation is disabled if more data is needed to go to that step.',
            ]
        },
        [helpDeactivateLicenseSectionKey]: {
            section_one: ' You may occasionally want to use a product on a different instrument or computer. To do this, you must first deactivate the license on its current host. After you do this, the license is once again available in KSM, and you use the <b>Request New Licenses</b> module to activate the license on a different host.'
        },
        [helpDeactivateSpecifyHostSectionKey]: {
            section_one: 'You start the deactivation process by specifying the host where you want to deactivate licenses from.',
            section_one_bullets: [
                {
                    bullet_point: 'Specify the host either by uploading a request file or providing the host ID:',
                    sub_bullet_points: [
                        'If your host has the ILU tool, generate an offline request file, then click <b>Add Request File</b> to upload the file (a).',
                        'If you are entering the host ID, ensure that at least one of the corresponding certificates has been added to your account before your request deactivation. Enter the host ID, then click <b>Find Host</b> (b).'
                    ]
                },
                {
                    bullet_point: 'KSM takes you to the next step: <b>Select Licenses.</b>'
                }
            ]
        },

        [helpDeactivateSpecifyLicenseSectionKey]: {
            section_one: 'After KSM identifies your host, it displays the existing licenses for that host in a table where you specify the quantity to deactivate for each license.',
            section_one_bullets: [
                {
                    bullet_point: 'Specify the quantity you want to deactivate. You can:',
                    sub_bullet_points: ['Enter the quantity you want to deactivate for one or more products (a).',
                        'Click <b>Deactivate All</b> if you want to deactivate the all licenses on your host (b).',
                        'Click <b>Clear All Quantities</b> to reset all entered quantities back to zero (c).',
                    ]
                },
                {
                    bullet_point: 'When you are done entering the quantities, click <b>Continue</b> (d). This button is enabled only when at least one quantity is entered.',
                    sub_bullet_points: [
                        'KSM  moves to the next step: <b>Request License</b>.',
                    ],
                    bullet_footer_note: 'Note: The Cancel button cancels deactivation for this host. You will return to the <b>Enter Host ID</b> step.'
                }
            ],
        },
        [helpDeactivateRequestingLicenseSectionKey]: {
            section_one: 'After you have selected the licenses to deactivate, you are ready to request the license files.',
            section_one_bullets: [
                'A table shows which licenses you are deactivating and which you are keeping for the host. Review the list to verify your work. You will not be able to make changes after you request the license file.',
                'If changes are needed, click <b>Select Licenses</b> in the stepper to go back to the previous step and update quantities.',
                'When the information is correct, click <b>Request License</b> to get your new license file. A spinner shows that your request is in progress.',
                'When the license file is ready, KSM takes you to the <b>Download & Confirm<b/> page.'
            ],
            section_two: 'Note: The <b>Cancel</b> button cancels the deactivation for this host. You will return to the <b>Enter Host ID</b> step.'
        },
        [helpDeactivateObtainLicensesSectionKey]: {
            section_one: 'When your license file is ready, you will need to install it. You will receive one or two license files. The same confirmation code is used for both files.',
            section_one_bullets: [
                'KSM displays a table that shows the licenses you are deactivating.',
                'Click <b>Email License</b> or <b>Download License</b> to obtain the license files. Your browser may prompt you to enable multiple file download.',
                '<b>Note:</b> Any expired time-based licenses will be removed from the new license file.',
                'After getting your new license files, install them on the host. Follow the instructions included in the email or downloaded package. If you received two license files, install both.',
                'Take note of the confirmation code displayed by the host’s license manager.',
                'In KSM, enter the code in the <b>Confirm Deactivation</b> field, then click <b>Confirm Deactivation</b>.',
            ],
            section_one_2_bullets: [
                'When requesting licenses via email, you may receive one or two emails, please make sure to download and install all license files',
                'When downloading license files, you may receive one or two license files, please make sure to download and install all license files',
                'If two license files are received, your license manager will not generate the confirmation code until the second license file is installed.'
            ],
            section_two: '<b>Note:</b> If you exit KSM before you enter the confirmation code, you will have a pending deactivation. You can complete the process later. When you return to KSM, you click <b>Deactivate Licenses</b> and enter the host ID for the host with the pending deactivation. KSM will return to this step and allow you to enter the confirmation code. Note that a pending deactivation must be completed before you can deactivate additional licenses for that host.'
        },
        [helpDeactivateConfirmationSectionKey]: {
            section_one: 'When your deactivation is successfully confirmed in KSM:',
            section_one_bullets: [
                'A message tells you that deactivation is complete. The license is now available to you in the <b>Request New License</b> module.',
                'Click <b>Deactivate More Products</b> to deactivate products on another host.'
            ]
        },
        [helpDeactivateErroHandlingSectionKey]: {
            section_one: 'Occasionally, errors may occur while processing your request.',
            section_two: 'If your request is processed KSM does not generate the file(s) in the expected time, our Support Team will retrieve any missing file and email it to you.',
            section_three: 'You can click the <b>Contact Us</b> link in the header at any time for additional support on issues or to submit feedback.'
        },
        
        //product & host help
        [redeemingDownloadingLicensesKey]: 'Redeeming and Downloading Licenses',
        [viewingProductsHostKey]: 'Viewing Products and Host',
        [sortingFilteringKey]: 'Sorting and Filtering',
        [ksmViewProductHostKey]: 'KSM View by Product or Host',
        [productViewTopicKey]: 'Product View Topic',
        [productViewFqaKey]: 'Product View FAQ',
        [helpProductViewLicenseFaqQAKey]: [
            {
                Q: 'I don’t find a product or host I expected to find. Why is it missing?',
                A: 'KSM only show the products and hosts associated to products if the order/certificate has been added to your account. If you have missing products or hosts, you may need to add the corresponding orders/certificates to your account.'
            },
            {
                Q: 'I need to redeem a product from a specific order. How do I do that?',
                A: 'Click View by Product and search for the product you want to redeem. If there are unassigned licenses, click the icon <icon>  within the Order Number column to go to the Request New License page for that specific order. If you don’t know any specific products for that order, you can use View Certificate to find the products, or export all your data to excel and search for that order.'
            }
        ],
        [helpProductViewProductHostContentKey]: {
            section_one: '<b>View by Products</b> allows you to:',
            section_one_bullets: [
                'See all your products',
                'Verify license and support expiration dates ',
                'Redeem licenses for a product or order',
                'Download existing licenses for a product within a host'
            ],
            section_two: '<b>View by Hosts</b> allows you to:',
            section_two_bullets: [
                'See all your host IDs',
                'Verify licenses and support expiration dates for any host',
                'Redeem licenses for all available products',
                'Redeem licenses for an order',
                'Download licenses for a host',
                'Download licenses for a product within a host'
            ],
            section_three: 'If you would like to see the information regarding your products and hosts in Excel, click <b>Export All to Excel</b>. The file is saved in your Downloads folder. '
        },
        [helpProductViewNavigationContentKey]: {
            section_one: 'To navigate on this page:',
            section_one_bullets: [
                'Click the corresponding radio button to switch between the <b>Products</b> and <b>Hosts</b> views.',
                'Click the down or up arrow in the first column to expand or hide the details for a given row.',
                'Click three-dot button in a column heading to access <a class="navgation-link" href="#product-filter"> sort and filter </a> options.',
            ]
        },
        [helpProductViewSortFilterContentKey]: {
            section_one: 'Sort and filter options on each column help you find a specific product or host. ',
            section_one_bullets: [
                'Click three-dot button in the appropriate column to access the functions.',
                'Click the <b>Filter</b> drop-down to access the filter options.'
            ]
        },
        [helpProductRedeemDownloadLicenseContentKey]: {
            section_one: 'Two icon buttons in the table let you obtain licenses:',
            section_one_bullets_withicon: [
                'The <b>Redeem</b> button lets you redeem unassigned licenses for a product or an order.',
                'The <b>Download</b> button lets you download an existing license file for a host or a given product. Use this option if you need to reinstall a license on a host.'
            ],
            section_two: '<b>View by Products</b> behavior:',
            section_three: '<b>View by Hosts</b> behavior:'
        },

        //renew nemo help 
        [ksmRenewNemoKey]: 'KSM Renew Nemo Licenses',
        [quickStepsRenewNemoKey]: 'Quick Steps to Renew Nemo Licenses',
        [creatingC2vFileKey]: 'Creating a C2V File',
        [requestingRenewalsKey]: 'Requesting Renewals',
        [generatingDownloadingKey]: 'Generating and Downloading Licenses',
        [renewalKey]: 'Renewal',
        [helpRenewNemoQuickStepsSectionKey]: {
            'section_one': 'The following process allows you to obtain your renewed licenses. The topics in this help page explain these steps in more detail.',
            'section_one_numbers': [
                'You receive an email from KSM when a renewal is pending. The email contains the details on the host ID and your new support end date.',
                '<a class="navgation-link" href="#create-c2v"> Create a C2V</a> file on the host that has the pending renewal(s).',
                '<a class="navgation-link" href="#request-renewal"> Request the renewed licenses</a>:',
                'KSM <a class="navgation-link" href="#generate-licenses"> generates the licenses</a>. When license generation is complete, choose either the download or email option to obtain the corresponding license files and installation instructions.',
                'Repeat steps 2 - 4 for each additional host.'
            ],
            'section_one_sub_bullets': [
                'On Home or Navigation menu, click <b>Renew Nemo Licenses</b>.',
                'Upload the C2V file you created, then click <b>Request Renewal</b>.'
            ]
        },
        [helpRenewNemoNavigationSectionKey]: {
            'section_one': 'You can use the options below to navigate through each screen:',
            'section_one_bullets': [
                'Click the arrow buttons ( <b><</b> and <b>></b> ) to navigate to the previous/next step. The buttons are disabled (grayed out) when it is not possible to go to the previous/next step.',
                'Click the step number to move to a specific step. Navigation is disabled if more data is needed to go to that step.',
            ]
        },
        [helpRenewNemoCreatingC2VHeaderKey]: 'Creating a C2V File',
        [helpRenewNemoCreatingC2VSectionKey]: {
            'section_one': 'The customer-to-vendor file contains information that allows KSM to verify your access to the host with pending licenses. It does not contain private customer information. They are typically generated with a utility on the host. Refer to your host documentation for details.'
        },
        [helpRenewNemoRequestingRenewalsSectionKey]: {
            'section_one': 'To request your renewed licenses:',
            'section_one_numbers': [
                'Ensure your C2V file is accessible.',
                'On Home or Navigation menu, click <b>Renew Nemo Licenses</b>.',
                'Click <b>Add C2V File…</b> to upload the file into KSM.',
                'KSM matches your host ID to a pending renewal and displays the products that have pending renewals, including the current and new support end dates.',
                'Click <b>Request Renewal</b> to renew the licenses.'
            ]
        },
        [helpRenewNemoGeneratingLicensesSectionKey]: {
            'section_one': 'After you request your license, KSM starts the process to generate your license file. A screen with spinning dots indicates license generation is in progress.',
            'section_two': 'When the licenses for all hosts have been generated, KSM displays the <b>License Download</b> screen. Click either <b>Email All Licenses</b> or <b>Download All Licenses</b> to obtain all licenses with one click. Your browser may prompt you to enable multiple file download. Installation instructions will be provided along with your license file.'
        },
        [helpRenewNemoErrorHandlingSectionKey]: {
            'section_one': 'Occasionally, errors may occur while processing your request:',
            'section_one_bullets': [
                'If your request does not go through, your renewal will remain available to be redeemed.',
                'If your request is processed but the license file is not generated in the expected time, our Support Team will retrieve your license and email to you.'
            ],
            'section_two': 'Click the <b>Contact us</b> link in the header to contact Keysight at any time for additional support on any issues or to submit feedback.'
        },
        [helpRenewNemoRenewalQAKey]: [
            {
                'Q': 'I did not get a renewal email from KSM for my renewal. What should I do?',
                'A': 'Please click “Contact us” at the top of the page to request assistance.'
            },
            {
                'Q': 'I uploaded my C2V file, but KSM tells me there is no renewal pending.',
                'A': 'You may have generated a file from the wrong host. Please ensure that the host ID for the instrument or computer on which you generated the C2V file matches the host ID in the email Keysight sent you. If have verified the host ID and still don’t see a renewal, click “Contact us” at the top of the page to request assistance.'
            }
        ],

        //transport help
        [transportKey]: 'Transport',
        [transportOverviewKey]: 'Transport Overview',
        [helpTransportQuickStepsTransport]: 'Quick Steps to Transport Licenses',
        [helpTransportCCLQuickStepsTransport]: 'Quick Steps to Transport License',
        [helpTransportQuickStepsSave]: 'Quick Steps to Save/Return Licenses',
        [helpTransportCCLQuickStepsSave]: 'Quick Steps to Save/Return License',
        [helpTransportMain]: 'Transport Main Page',
        [helpTransportLicenses]: 'Transporting Licenses',
        [helpTransportSaving]: 'Saving/Returning Licenses',
        [helpTransportingBanking]: 'Transport/Banking',
        [helpTransportOverviewContent]: {
            section_one: 'Keysight Transportable licenses can be transported or saved back to KSM:',
            section_two: '<b>Transport</b>: Allows a license to be transported from one instrument/PC to another instrument/PC. ',
            section_three: '<b>Save to KSM</b>: Allow a license to be saved or returned to KSM to be redeemed to a different instrument/PC later. This transaction is called <b>Banking</b> in KSM.',
            section_four: 'Whether you want to transport or save your license to KSM, the process starts on PLM, Pathwave License Manager. On PLM you can select which licenses you want to transport and deactivate those licenses as your first step. After that, PLM will give you a link to the KSM Transport page, where you will complete your transport or banking process. It also provides a PDF document that you can save and copy into an USB card, in case your instrument/PC is not connected to the internet. Alternatively, you can also open the KSM Transport page URL and type each of your license ids and confirmation codes.',
            section_five: '<b>Note</b>: You can only save/return a license to KSM if the respective entitlement is already associated to a <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a> user account. You will need that account email and password to login to <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a> and redeem your license later.',
        },
        [helpTransportQuickStepsTransportContent]: {
            'section_one': 'Follow the steps below to transport your licenses:',
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'Open the KSM Transport page using the link from PLM',
                },
                {
                    order: 2,
                    type: '1',
                    step: 'Select the licenses you would like to transport and click on Transport Licenses',
                },
                {
                    order: 3,
                    type: '1',
                    step: 'Assign each license to a new host id (instrument or PC)',
                },
                {
                    order: 4,
                    type: '1',
                    step: 'Preview your licenses to ensure host ids are correct',
                },
                {
                    order: 5,
                    type: '1',
                    step: 'Click on Request Licenses',
                },
                {
                    order: 6,
                    type: '1',
                    step: 'Download or email the licenses when they are ready. For each host, you will receive two files, installation instructions file and license file',
                },
            ]
        },
        [helpTransportQuickStepsSavingContent]: {
            'section_one': 'Follow the steps below to save your licenses to KSM:',
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'Open the KSM Transport page using the link from PLM',
                },
                {
                    order: 2,
                    type: '1',
                    step: 'Select the licenses you would like to save and click on Save to KSM',
                },
                {
                    order: 3,
                    type: '1',
                    step: 'Preview your selection ',
                },
                {
                    order: 4,
                    type: '1',
                    step: 'Click on Return Licenses',
                },
                {
                    order: 5,
                    type: '1',
                    step: 'KSM will send a confirmation email to the accounts associated to the respective entitlement',
                },
                {
                    order: 6,
                    type: '1',
                    step: 'Once you are ready to get your license again, login to <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a> to redeem your license',
                },
            ]
        },

        [helpTransportMainContent]: {
            'section_one': 'If you use the URL provided by PLM, which contains the query strings for each license id and confirmation code, the transport main page will load with all your licenses ready to be transported or banked.',
            'section_two': 'You also have the option to type in the URL without the query string parameters and type each license id and confirmation code manually.',
            'section_three': 'Page loaded with no query string:',
            'section_four_steps': [
                {
                    order: 1,
                    type: 'a',
                    step: 'Type in the license id',
                },
                {
                    order: 2,
                    type: 'a',
                    step: 'Type in the confirmation code',
                },
                {
                    order: 3,
                    type: 'a',
                    step: 'Click on Add License. KSM will validate and add the license to the screen.\n' +
                        'Repeat as needed.',
                }
            ],
            'section_five': 'Note: Add License button will be enabled only after you enter the require fields.',
            'section_six': 'Page loaded with query string (as provided by PLM):',
            'section_seven': 'You can transport or bank your licenses any time.',
        },
        [helpTransportLicensesContent]: {
            section_one: 'Once your licenses are loaded in the main page you can follow the steps below to transport your licenses to a different instrument/PC:',
            section_two_steps: [
                {
                    order: 1,
                    type: 1,
                    step: 'Select the licenses you would like to transport using the checkboxes, you can transport one or multiple licenses at a single time. Click on Transport Licenses to Continue to go to Host Assignment.',
                    img: 'ksm-kal-transport-step-1',
                },
                {
                    order: 2,
                    type: 1,
                    step: 'KSM will display all selected products and you can assign one or more products to the new host.',
                    img: 'ksm-kal-transport-step-2',
                },
                {
                    order: 2.1,
                    type: 1,
                    step: 'Select one or more products and click on Assign Product to Host when ready. Type in your new host id (instrument/PC) and click Save Host ID. KSM will validate the host id and display an error if the expected formatting is not matched.',
                    img: 'ksm-kal-transport-step-2-1',
                },
                {
                    order: 2.2,
                    type: 1,
                    step: 'Once you successfully saved the host id, KSM will display the assigned hosts and remaining products to assign to host.',
                    img: 'ksm-kal-transport-step-2-2',
                },
                {
                    order: 2.3,
                    type: 1,
                    step: 'Repeat the host assignment for all remaining products, when all done, click on Continue to go to Preview.',
                    img: 'ksm-kal-transport-step-2-3',
                },
                {
                    order: 3,
                    type: 1,
                    step: 'On the preview screen confirm your host assignments. If corrections are needed, you can delete products from host or the entire host assignment and click on step 2 on the navigation bar to return to the host assignment step. If it looks all correct, click Request Licenses.',
                    img: 'ksm-kal-transport-step-3',
                },
                {
                    order: 4,
                    type: 1,
                    step: 'KSM will generate your licenses, this process may take a few seconds.',
                    img: 'ksm-kal-transport-step-4',
                },
                {
                    order: 4.1,
                    type: 1,
                    step: 'Once your license files are generated, they will be available to download individually, download all, or get them via email.',
                    img: 'ksm-kal-transport-step-4-1',
                },
                {
                    order: 'a',
                    type: 'a',
                    step: 'Download license and install instructions for that host',
                },
                {
                    order: 'b',
                    type: 'a',
                    step: 'Download all license files and install instructions',
                },
                {
                    order: 'c',
                    type: 'a',
                    step: 'Get all license files and install instructions via email',
                },
                {
                    order: 'd',
                    type: 'a',
                    step: 'If you have remaining licenses to transport, click on Transport Remaining Licenses to return to main page and complete your work.',
                },
            ]
        },
        [helpTransportSavingContent]: {
            section_one: 'Once your licenses are loaded in the main page you can follow the steps below to save/return your licenses:',
            section_two_steps: [
                {
                    order: 1,
                    type: 1,
                    step: 'Select the licenses you would like to save using the checkboxes, you can save multiple licenses in a single flow.',
                    img: 'ksm-kal-save-step-1',
                },
                {
                    order: 2,
                    type: 1,
                    step: 'Click on Save to Keysight Software Manager when ready. KSM will validate if all corresponding entitlements are associated to at least one KSM account. If any are not you will see an error message. To resolve this, add the corresponding order/certificate to your <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a> account and return to the Transport Page to try again.',
                    img: 'ksm-kal-save-step-2',
                },
                {
                    order: 3,
                    type: 1,
                    step: 'After reviewing the information, confirm by clicking on Return Licenses.',
                    img: 'ksm-kal-save-step-3',
                },
            ],
            section_three: 'Your licenses will be available to be redeemed in <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a>. KSM will send an email to all KSM accounts associated to the respective order/certificate to confirm the licenses are saved and available to be redeemed again.',
            section_four: 'If you have more licenses available on the main page to transport or bank, you can click on Transport Remaining Licenses to go back to main page and complete your work.',
            section_five: '<b>Note</b>: In order to return/save a license, the respective order/certificate must be associated to at least one <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a> account.'
        },
        [helpKalTransportErrorHandling]: {
            'section_one': 'Occasionally, errors may occur while processing your request:',
            'section_one_bullets': [
                'If your request does not go through, your pending license transports will continue to be available so you can request them later.',
                'If your request is processed but one or more license files are not generated in the expected time, our Support Team will email your license(s) to you.',
                'Click the <b>Contact us</b> link in the header to contact Keysight at any time for additional support on any issues or to submit feedback. '
            ]
        },
        [helpKALTrasportQAKey]: [
            {
                'Q': 'My licenses are showing as Transported and I cannot select them, why?',
                'A': 'Your licenses have already been transported, they are no longer available to be transported/banked.'
            },
            {
                'Q': 'My licenses are showing as Banked and I cannot select them, why?',
                'A': 'Your licenses have already been banked (saved to Keysight), they are no longer available to be transported/banked. You can redeem your new license in <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a>.'
            },
            {
                'Q': 'I transported my licenses and was not able to download the new licenses, where can I get them?',
                'A': 'You can get a copy of your latest licenses on the “Licenses and Hosts“ menu in <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a>, under the “View by Product or Host” module. You will see the licenses only if the respective order/certificate is already associated to your account, if not you will need to add the order/certificate to your account first.'
            },
            {
                'Q': 'My instrument/PC is not connected to the internet, how do I complete my transport?',
                'A': 'After you deactivate your transportable licenses in PLM, click on Save Your Reactivation Codes button to save the HTML document. Copy this document to a computer that is connected to the internet and click on link to open the reactivation page. Complete your transport in the KSM Transport page and download your licenses. Copy your licenses back to your instrument and install them.'
            },
        ],
        [helpTransportCCLOverviewContent]: {
            section_one: 'Keysight Transportable licenses can be transported or saved back to KSM:',
            section_two: '<b>Transport</b>: Allows a license to be transported from one instrument/PC to another instrument/PC. ',
            section_three: '<b>Save to KSM</b>: Allow a license to be saved or returned to KSM to be redeemed to a different instrument/PC later. This transaction is called <b>Banking</b> in KSM.',
            section_four: 'Whether you want to transport or save your license to KSM, the process starts on KLM, Keysight License Manager. KLM allows you to complete your transaction online, if your instrument/PC is connected to the internet, or offline if otherwise. You will need to use the KSM Transport page only when performing offline transport/banking. On KLM you can select which licenses you want to transport and deactivate those licenses as your first step. After that, KLM will give you a link to the KSM Transport page, where you will complete your transport or banking process. It also provides a URL file that you can save and copy into an USB card and use to open the Transport page on a connected computer. Alternatively, you can also open the KSM Transport page and type your license information.',
            section_five: '<b>Note</b>: You can only save/return a license to KSM if the respective entitlement is already associated to a <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a> user account. You will need that account email and password to login to <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a> and redeem your license later.',
        },
        [helpTransportCCLQuickStepsTransportContent]: {
            'section_one': 'Follow the steps below to transport your license:',
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'Open the KSM Transport page',
                },
                {
                    order: 2,
                    type: '1',
                    step: 'Click Transport License',
                },
                {
                    order: 3,
                    type: '1',
                    step: 'Assign the license to a new host id (instrument or PC)',
                },
                {
                    order: 4,
                    type: '1',
                    step: 'Preview your host assignment',
                },
                {
                    order: 5,
                    type: '1',
                    step: 'Click on Request License',
                },
                {
                    order: 6,
                    type: '1',
                    step: 'Download or email the license when they are ready. For each host, you will receive two files, installation instructions file and license file',
                },
            ]
        },
        [helpTransportCCLQuickStepsSavingContent]: {
            'section_one': 'Follow the steps below to save your license to KSM:',
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'Open the KSM Transport page',
                },
                {
                    order: 2,
                    type: '1',
                    step: 'Click on Save to KSM',
                },
                {
                    order: 3,
                    type: '1',
                    step: 'Confirm by clicking on Return License',
                },
                {
                    order: 4,
                    type: '1',
                    step: 'KSM will send a confirmation email to the accounts associated to the respective entitlement',
                },
                {
                    order: 5,
                    type: '1',
                    step: 'Once you are ready to get your license again, login to <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a> to redeem your license',
                },
            ]
        },
        [helpTransportCCLMainContent]: {
            section_one: 'If you use the URL provided by KLM, which contains the query strings for your licence, the transport main page will load with your license ready to be transported or banked.',
            section_two: 'You also have the option to type in the URL without the query string parameters and either load the KLM URL file or type each license information manually.',
            section_three: 'Page loaded with no query string:',
            section_four: '<b>To load URL File:</b>',
            section_four_steps: [
                {
                    order: 'a',
                    step: 'Upload or drag your URL file into the appropriated window'
                },
                {
                    order: 'c',
                    step: 'Click on Add License'
                },
            ],
            section_four_note: 'Note: If you get an Invalid File Type error, try to drag and drop the file, most browsers do not allow upload of URL file without specific configuration, but drag/drop should work. ',
            section_five: '<b>Type license information manually:</b>',
            section_five_steps: [
                {
                    order: 'b',
                    step: 'Enter the information on each of the fields.'
                },
                {
                    order: 'c',
                    step: 'Click on Add License'
                },
            ],
            section_five_note: 'Note: KSM will validate the information provided, if it is incorrect, you will not be able to proceed.',
            section_six: 'Page loaded with query string (as provided by KLM):',
            section_seven: 'You can transport or bank your licenses any time.',
        },
        [helpTransportCCLLicensesContent]: {
            section_one: 'Once your license is loaded in the main page you can follow the steps below to transport your license to a different instrument/PC:',
            section_two_steps: [
                {
                    order: 1,
                    type: 1,
                    step: 'Click on Transport Licenses, KSM will display the host assignment modal.',
                    img: 'ksm-ccl-transport-step-1'
                },
                {
                    order: 2,
                    type: 1,
                    step: 'Type in your new host id (instrument/PC) and click Save Host ID. KSM will validate the host id and display an error if the expected formatting is not matched. Once you successfully saved the host id, KSM will display the preview screen.',
                    img: 'ksm-ccl-transport-step-2'
                },
                {
                    order: 3,
                    type: 1,
                    step: 'On the preview screen confirm your host assignment. If corrections are needed, you can click on step 1 on the navigation bar to start over. If it looks all correct, click Request Licenses. KSM will generate your licenses, this process may take a few seconds.',
                    img: 'ksm-ccl-transport-step-3'
                },
                {
                    order: 4,
                    type: 1,
                    step: 'Once your license files are generated, they will be available to download individually, download all, or get them via email.',
                    img: 'ksm-ccl-transport-step-4',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Download license and install instructions for that host'
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Download all license files and install instructions'
                        },
                        {
                            order: 3,
                            type: 'a',
                            step: 'Get all license files and install instructions via email'
                        },
                    ]
                },
            ]
        },
        [helpTransportCCLSavingContent]: {
            section_one: 'Once your license is loaded in the main page you can follow the steps below to save/return your license:',
            section_two_Steps: [
                {
                    order: 1,
                    type: 1,
                    step: 'Click on Save to Keysight Software Manager. KSM will validate if the corresponding entitlement is associated to at least one KSM account. If it is not you will see an error message. To resolve this, add the corresponding order/certificate to your <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a> account and return to the Transport Page to try again. KSM will display the prevew screen.',
                    img: 'ksm-ccl-save-step-1'
                },
                {
                    order: 2,
                    type: 1,
                    step: 'After reviewing the information, confirm by clicking on Return License. KSM will display a confirmation message.',
                    img: 'ksm-ccl-save-step-2'
                },
            ],
            section_three: 'Your licenses will be available to be redeemed in <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a>. KSM will send an email to all KSM accounts associated to the respective order/certificate to confirm the licenses are saved and available to be redeemed again.',
            section_four: 'Note: In order to return/save a license, the respective order/certificate must be associated to at least one <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a> account.'
        },
        [helpTransportCCLQAKey]: [
            {
                'Q': 'My licenses are showing as Transported and I cannot select them, why?',
                'A': 'Your license has already been transported, they are no longer available to be transported/banked.'
            },
            {
                'Q': 'My license is showing as Banked and I cannot select them, why?',
                'A': 'Your license has already been banked (saved to Keysight), they are no longer available to be transported/banked. You can redeem your new license in <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a>.'
            },
            {
                'Q': 'I transported my licenses and was not able to download the new licenses, where can I get them?',
                'A': 'You can get a copy of your latest licenses on the “Licenses and Hosts“ menu in <a class="hyperlink" href="http://www.keysight.com/find/softwaremanager">KSM</a>, under the “View by Product or Host” module. You will see the licenses only if the respective order/certificate is already associated to your account, if not you will need to add the order/certificate to your account first.'
            },
            {
                'Q': 'My instrument/PC is not connected to the internet, how do I complete my transport?',
                'A': 'After you deactivate your transportable license in KLM, find your confirmation URL file under C:\\Users\\barata\\Documents\\Agilent License Manager\\Transport Confirmations. Copy this file to a connected computer, open the KSM Transport page and drag/drop your URL file to start your transport. Complete your transport in the KSM Transport page and download your license. Copy your license back to your instrument and install it.'
            },
        ],


        //renew standalone help
        [quickStepsRenewSubscriptionsKey]: 'Quick Steps to Renew Subscriptions',
        [helpRenewStandaloneQuickStepsSectionKey]: {
            'section_one': 'The following process allows you to obtain your renewed licenses. The topics in this help page explain these steps in more detail.',
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'You can complete a renewal when you have available standalone subscriptions to apply to your licenses.',
                },
                {
                    order: 2,
                    type: '1',
                    step: 'On Home or Navigation Menu, click <b>Renew Standalone Support</b>.',
                },
                {
                    order: 3,
                    type: '1',
                    step: 'To renew the licenses that correspond to a standalone subscription:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Click the standalone subscription you want to use.',
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Select the licenses you want to renew.',
                        },
                        {
                            order: 3,
                            type: 'a',
                            step: 'Click <b>Renew Selected</b>.',
                        },
                        {
                            order: 4,
                            type: 'a',
                            step: 'When license generation is complete, choose either the download or email option to obtain the corresponding license files and installation instructions.',
                        }
                    ]
                },
                {
                    order: 4,
                    type: '1',
                    step: 'Repeat step 3 to renew additional licenses.',
                }
            ]
        },
        [helpRenewStandaloneNavigationSectionKey]: {
            'section_one': 'Use the options below to navigate through each screen:',
            'section_one_bullets': [
                'Click the arrow buttons ( < and > ) to navigate to the previous/next step. The buttons are disabled (grayed out) when it is not possible to go to the previous/next step.',
                'Click the step number to move to a specific step. Navigation is disabled if more data is needed to go to that step.',
            ]
        },
        [helpRenewStandaloneRenewSupportSectionKey]: {
            'section_one': 'The <b>Renew Standalone Support</b> link is available when you have purchased one or more standalone subscription. You can apply each subscription to one license or product.',
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'On Home or Navigation menu, click <b>Renew Standalone Support</b>.',
                },
                {
                    order: 2,
                    type: '1',
                    step: 'The <b>Standalone Subscription Renewal</b> page shows all available standalone subscriptions.',
                    img: 'ksm-renew-standalone-renewals'
                },
                {
                    order: 3,
                    type: '1',
                    step: 'Click the standalone subscription you want to use. KSM displays:',
                    substeps: [
                        {
                            order: 1,
                            type: 'bullet',
                            step: 'The number of standalone subscriptions available (a)'
                        },
                        {
                            order: 2,
                            type: 'bullet',
                            step: 'All corresponding licenses and products available to be renewed with the selected standalone subscription (b)',
                            img: 'ksm-renew-standalone-select-licenses'
                        }
                    ]
                },
                {
                    order: 4,
                    type: '1',
                    step: 'Select the licenses or products you want to renew. KSM reduces the available quantity as you make your selections. Note the following:',
                    substeps: [
                        {
                            order: 1,
                            type: 'bullet',
                            step: 'The <b>Renew Selected</b> button is enabled after you select one license or product. You do not need to use all available subscriptions in this session.'
                        },
                        {
                            order: 2,
                            type: 'bullet',
                            step: 'You can apply a standalone subscription to an unassigned license. Unassigned licenses can be redeemed later within your KSM account.'
                        },
                        {
                            order: 3,
                            type: 'bullet',
                            step: 'If are renewing a floating license installed on a given host, all rows for that product/host must be selected for renewal. Partial renewals are not allowed.'
                        }
                    ]
                },
                {
                    order: 5,
                    type: '1',
                    step: 'Click <b>Renew Selected</b>. KSM generates the corresponding licenses or updates the corresponding entitlements (if not yet redeemed).'
                },
                {
                    order: 6,
                    type: '1',
                    step: 'When the licenses are available:',
                    substeps: [
                        {
                            order: 1,
                            type: 'bullet',
                            step_prefix: 'Click the download button (',
                            step_suffix: ') to download a single license.',
                            img: 'ksm-renew-standalone-download-button'
                        },
                        {
                            order: 2,
                            type: 'bullet',
                            step: 'Click <b>Email Licenses</b> or <b>Download Licenses</b> to obtain all available licenses at the same time.'
                        },
                        {
                            order: 3,
                            type: 'bullet',
                            step: 'Click <b>Renew More Subscriptions</b> to return to your list of available subscriptions and complete additional renewals.'
                        }
                    ]
                }
            ]
        },
        [helpRenewStandaloneErrorHandlingSectionKey]: {
            'section_one': 'Occasionally, errors may occur while processing your request:',
            'section_one_bullets': [
                'If your request does not go through, your renewal remains available to be done later.',
                'If your request is processed but one or more license files are not generated in the expected time, our Support Team will email your license(s) to you.',
                'Click the <b>Contact us</b> link in the header to contact Keysight at any time for additional support on any issues or to submit feedback. '
            ]
        },
        [licenseRenewalKey]: 'License Renewal',
        [helpRenewStandaloneLicenseRenewalQAKey]: [
            {
                'Q': 'Why don’t I see <b>Renew Standalone Support</b> in the KSM menu?',
                'A': 'If you have purchased standalone subscriptions but do not see the link, make sure you have added the corresponding entitlement certificate to your account.'
            },
            {
                'Q': 'Can I apply a standalone subscription to a license or product multiple times?',
                'A': 'Yes. For example, if you have three 12-month standalone subscriptions for a given product, you can apply all three to the same license. You would then be able to get software updates and support for three years.\n' +
                    'To do this, you would apply the subscription to the license and renew it, then repeat the process two more times. Your support subscription would be extended accordingly with each renewal.'
            }
        ],
        
        //renew eesof help
        [quickStepsRenewEesofKey]: 'Quick Steps to Renew EEsof EDA Licenses',
        [requestRenewedLicenseKey]: 'Requesting Renewed Licenses',
        [helpRenewEesofQuickStepsSectionKey]: {
            'section_one': 'The following process allows you to obtain your renewed licenses. The topics in this help page explain these steps in more detail.',
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'You receive an email from KSM when a renewal is pending.'
                },
                {
                    order: 2,
                    type: '1',
                    step: 'On Home or Navigation Menu, click <b>Renew EEsof EDA Licenses</b>.'
                },
                {
                    order: 3,
                    type: '1',
                    step: 'You can renew all your licenses at once or a few at a time. To renew your licenses:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Verify the host assignments. You can assign licenses to different hosts if desired.'
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Request you renewed licenses.'
                        },
                        {
                            order: 3,
                            type: 'a',
                            step: 'When license generation is complete, choose either the download or email option to obtain the corresponding license files and installation instructions.'
                        }
                    ]
                },
                {
                    order: 4,
                    type: '1',
                    step: 'If you have more pending renewals and want to renew them now, click <b>Renew More Licenses</b>.'
                }
            ]
        },
        [helpRenewEesofNavigationSectionKey]: {
            'section_one': 'Use the options below to navigate through each screen:',
            'section_one_bullets': [
                'Click the arrow buttons ( < and > ) to navigate to the previous/next step. The buttons are disabled (grayed out) when it is not possible to go to the previous/next step.',
                'Click the step number to move to a specific step. Navigation is disabled if more data is needed to go to that step.',
            ]
        },
        [reassigningLicenceNewHostKey]: 'Reassigning License to a New Host',
        [helpRenewEesofReassignLicenseSectionKey]: {
            'section_one': 'Once a year, during the renewal window, you can move your perpetual renewable license to a different host. To reassign you license to a new host, follow these steps:',
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'On Home or Navigation Menu, click <b>Renew EEsof EDA Licenses</b>.',
                },
                {
                    order: 2,
                    type: '1',
                    step: 'KSM displays the licenses that are eligible for renewal. Review the hosts assignments.',
                    img: 'ksm-renew-eesof-reassign-licenses'
                },
                {
                    order: 3,
                    type: '1',
                    step: 'If you need to reassign a license to a new host:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Click the <b>Reassign</b> icon button:',
                            img: 'ksm-renew-eesof-reassign-button'
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'In the <b>Reassign from</b> window, enter the new host ID. Note that:',
                            substeps: [
                                {
                                    order: 1,
                                    type: 'bullet',
                                    step: 'As you enter the ID, KSM suggests similar hosts IDs previously used within your account.'
                                },
                                {
                                    order: 2,
                                    type: 'bullet',
                                    step: 'If you enter a new host ID, KSM validates the format and displays an error message if the format is incorrect.'
                                }
                            ]
                        },
                        {
                            order: 3,
                            type: 'a',
                            step: 'Click <b>Save Host ID</b>. KSM displays your reassigned license under the new host.',
                            img: 'ksm-renew-eesof-reassign-from-host'
                        },
                    ]
                },
                {
                    order: 4,
                    type: '1',
                    step: 'Repeat step 3 as needed. When all host assignments are correct, you are ready to request your renewal.'
                }
            ]
        },
        [requestingRenewalKey]: 'Requesting Renewal',
        [helpRenewEesofRequestLicenseSectionKey]: {
            'section_one': 'To obtain new license files, follow these steps:',
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'Click <b>Continue to Renew Licenses</b>.'
                },
                {
                    order: 2,
                    type: '1',
                    step: 'Checkboxes show the licenses to be renewed. By default, all are selected. Uncheck any licenses you do not want to renew at this time (you will be able to renew them later).',
                    img: 'ksm-renew-eesof-renew-selected'
                },
                {
                    order: 3,
                    type: '1',
                    step: 'Click <b>Renew Selected Licenses</b>. KSM updates the screen during the license generation process:',
                    substeps: [
                        {
                            order: 1,
                            type: 'bullet',
                            step: 'In the host rows, dots indicate KSM is processing the request (a). A download button lets you download the generated license (b).'
                        },
                        {
                            order: 2,
                            type: 'bullet',
                            step: 'When finished, the buttons to obtain your licenses become active (c).',
                            img: 'ksm-renew-eesof-generate-licenses'
                        },
                    ]
                },
                {
                    order: 4,
                    type: '1',
                    step: 'When you are finished generating licenses, click <b>Email All Licenses</b> or <b>Download All Licenses</b> to obtain the license files. '
                },
                {
                    order: 5,
                    type: '1',
                    step: 'If you have more pending renewals, you can click <b>Renew More Licenses</b> to renew additional products.'
                }
            ]
        },
        [helpRenewEesofErrorHandlingSectionKey]: {
            'section_one': 'Occasionally, errors may occur while processing your request:',
            'section_one_bullets': [
                'If your request does not go through, your renewal will remain available to be done later.',
                'If your request is processed but the license file is not generated in the expected time, our Support Team will email your license(s) to you.',
                'Click the <b>Contact us</b> link in the header to contact Keysight at any time for additional support on any issues or to submit feedback.'
            ]
        },
        [helpRenewEesofRenewalQAKey]: [
            {
                'Q': 'How can I renew a license that expired more than 30 days ago?',
                'A': 'Contact Keysight for help. KSM Support will help you complete your renewal.'
            },
            {
                'Q': 'Can I split a license onto two different hosts?',
                'A': 'No. If you have a given quantity installed on a host through a single license file, you cannot split up the license. You can still reassign the entire quantity to a new host if desired.'
            }
        ],
        
        //request license help
        [ksmRequestNewLicenseKey]: 'KSM Request New License',
        [requestLicenseTopicKey]: 'Request License Topic',
        [requestLicenseFqaKey]: 'Request License FAQ',
        [previewingLicensesKey]: 'Previewing Licenses',
        [changingProductAssignmentsKey]: 'Changing Product/Host Assignments',
        [assigningProductsToHostKey]: 'Assigning Products to Host',
        [selectingProductsKey]: 'Selecting Products',
        [requestingNewLicenses]: 'Requesting New Licenses',
        [quickStepsObtainLicensesKey]: 'Quick Steps to Request Licenses',
        [helpRequestQuickStepsSectionKey]: {
            section_one: 'The following process allows you to request your licenses. The topics in this help page explain these steps in more detail.',
            section_one_steps: [
                {
                    order: 1,
                    type: '1',
                    step: 'Ensure your entitlement certificate has been added to your account, then click <b><u><a href="/request-license">Request New Licenses</a></u></b> on Home or Navigation Menu. <br/> Note: The <b>Request New Licenses</b> page lets you <u>navigate</u> through the steps to set up multiple hosts and make changes as needed.\n',
                    substeps: []
                },
                {
                    order: 2,
                    type: '1',
                    step: 'Assign the products to the hosts that will run the software:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: '<u>Locate and select the product(s)</u> for a specific host, then click <b>Assign Products to Host</b>.'
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: '<u>Enter the host ID</u>, then click <b>Save Host ID</b>.'
                        },
                        {
                            order: 3,
                            type: 'a',
                            step: 'Repeat this process for each additional host.'
                        }
                    ]
                },
                {
                    order: 3,
                    type: '1',
                    step: 'Click <b><u>License Preview</u></b> to confirm that your products are assigned to the correct hosts.'
                },
                {
                    order: 4,
                    type: '1',
                    step: 'If any are incorrect, <u>change the product/host assignments</u> <i>before</i> you generate the licenses.'
                },
                {
                    order: 5,
                    type: '1',
                    step: 'Click <b><u>Request Licenses</u></b>.'
                },
                {
                    order: 6,
                    type: '1',
                    step: 'When license generation is complete, click either the <b>Download</b> or <b>Email</b> option to obtain the corresponding license files and installation instructions. '
                }
            ]
        },
        [helpRequestNavigationSectionKey]: {
            section_one: 'You can use the options below to navigate through each screen:',
            section_one_bullets: [
                'Click the arrow buttons ( < and > ) to navigate to the previous/next step. The buttons are disabled (grayed out) if it is not possible to go to the previous/next step.',
                'Click the step number to move to a specific step. Navigation is disabled if more data is needed to go to that step.',
            ]
        },
        [helpRequestRequestingNewLicensesSectionKey]: {
            section_one: 'To request a license, you must:',
            section_one_bullets: [
                'Ensure the entitlement certificate has been added to your KSM account.',
                'Know the host ID for the computer or instrument that will run the software.'
            ],
            section_two: 'After a certificate is added to your account, you can request the corresponding license(s) at any time. The <b>Request New Licenses</b> page lets you request one license for a single product, all licenses for all purchased products, or any number in between. This flexibility allows you to purchase multiple products in any quantity and request the licenses as needed. To begin, click <b>Request New Licenses</b>.'
        },
        [helpRequestSelectingProductSectionKey]: {
            section_one: 'When you click <b> Request New Licenses </b>, KSM displays all products you have available to request. The <b>My Products</b> table lets you select the products you want to assign to a particular host. In the table, you can:',
            section_one_bullets: [
                'Change the number of items displayed on the page.',
                'Sort, filter, and search to find your desired products. Click the three-dot button in the appropriate column to access the functions.'
            ],
            section_two: 'When you locate the appropriate product:',
            section_two_bullets: [
                'Click the checkbox in the first column.',
                'If your account has more than 20 products, KSM will rearrange the table to display your selected product first followed by any compatible products, which you can also select.',
                'Any products that are not compatible with the selected product(s) are grayed out.',
                'If required, assign a quantity for any selected product in the <b> Qty </b> column.',
                'When you are finished selecting products, click <b> Assign Products to Host. </b>'
            ]
        },
        [helpRequestAssignProductSectionKey]: {
            section_one: 'After you click <b>Assign Products to Host</b>, the host assignment screen displays. ',
            section_one_bullets: [
                'Enter the host ID. Host ID formats are instrument-specific (for example, a model number, serial number, and hardware ID separated by commas, or an Ethernet address in hex characters). Hover the cursor over the host ID field to display a tooltip that shows the proper format for that host.',
                'Click Save Host ID to complete the assignment. KSM will validate the host format at save. If the format is incorrect, an error message displays.',
                'You can repeat the product selection and host assignment steps for all desired hosts.'
            ],
            section_one_sub_bullet: 'As you begin entering the host ID, KSM will auto-populate a drop-down menu to let you select from your previously used host IDs.',
            section_two: 'When you are finished making assignments, you can:',
            section_two_bullets: [
                'Click<a class="navgation-link" href="#preview-license"> License Preview </a> to verify your product/host assignments.',
                ' <a class="navgation-link" href="#change-product">Change any assignments</a> before you request the licenses.'
            ]
        },
        [helpRequestPreviewLicenseSectionKey]: {
            section_one: 'When you click <b> License Preview </b>, KSM displays a table for each host that lists all products and quantities assigned to that host. This allows you to review your product/host assignments before you request your licenses.',
            section_one_bullets: [
                'If corrections are needed, return to the <b> Assign Products to Hosts </b> step and make the corrections. See <a class="navgation-link" href="#change-product"> Changing Product/Host Assignments</a> for details.',
                'When all assignments are correct, click  <b>Request Licenses</b>, then confirm your request. See   <a class="navgation-link" href="#generate-license">Generating and Downloading Licenses</a> for details.'
            ]
        },
        [helpRequestChangeProductSectionKey]: {
            section_one: 'Before you confirm your license request, you can go back to the product selection step to make changes if necessary.',
            section_one_bullets: [
                'Click the <b>Assign Products to Hosts</b> step one to return to product assignment:',
                'KSM collapses the tables. Click the arrow to the left of the host ID to expand a table for a given host.',
                'Click the <b>X</b> button in the first column to delete a product from a host.',
                'If all products are removed from a host, the host table is removed from the screen.'
            ],
            section_two: 'To add additional products to a host, repeat the product selection/host assignment process.'
        },
        [helpRequestGeneratingLicenseSectionKey]: {
            section_one: 'After clicking on Request Licenses, KSM will start generating your licenses. This process may take a few minutes. KSM will display an intermediary screen while your licenses are being generated:',
            section_one_bullets: [
                'The dots indicate the process is progressing',
                'Message displayed indicates license generation is in progress',
            ],
            section_two: 'Once license generation process is completed, the license download screen is displayed: ',
            section_two_bullets: [
                'The green message indicates all licenses are ready to be downloaded',
                'Licenses can be individually downloaded using the download icon',
                'The buttons to Email or Download licenses are enabled, use them to get all licenses generated',
                'Click on Assign More Products to request more licenses'
            ],
            section_two_sub_bullets_c: [
                'When receiving licenses via email:',
                'When downloading the licenses:'
            ],
            section_two_sub_bullets_c_i: [
                'You may receive multiple emails, one for each different host and different installation instructions.',
            ],
            section_two_sub_bullets_c_ii: [
                'You may need to authorize your browser to download multiple license files',
                'Each license file will have a corresponding install instructions file (html)'
            ]

        },
        [helpRequestErroHandlingSectionKey]: {
            section_one: 'Occasionally, errors may occur while processing your request:',
            section_one_bullets: [
                'If your request does not go through, your entitlements will remain available to be requested. ',
                'If your request is processed but any license files are not generated in the expected time, our Support Team will retrieve your licenses and email them to you.',
                'If you determine that a product is assigned to the wrong host after you requested the license, you will need to contact Keysight to correct the error.',
            ],
            section_two: 'You can contact Keysight, through the “Contact us” link within the header, at any time for additional support on any issues or to submit feedback.'
        },
        [helpRequestEntitlementCertificateFaqQAKey]: [{
            Q: 'What is an Entitlement Certificate?',
            A: 'The certificate is the document provided by Keysight that entitles you to obtain licenses.'
        },
            {
                Q: 'I have a single entitlement certificate for multiple products and/or quantities, but different people need to install and manage the licenses. How can we share the certificate?',
                A: 'Multiple users can manage licenses from one certificate. Share the order number and certificate number with your colleagues so they can also register in KSM and request their licenses. KSM keeps track of the licenses requested for each certificate, licenses can be requested by different accounts until the total quantity is consumed.'
            },
            {
                Q: 'I bought my licenses from Ixia a few years back and I do not have an entitlement certificate. How do I get the order/certificate numbers for my licenses?',
                A: 'Please click the “Contact us” link at the top of the page to request assistance. A Keysight Representative will contact you and provide your entitlement certificates.'
            }
        ],
        [helpRequestLicensesFaqQAKey]: [
            {
                Q: 'How do I obtain my licenses?',
                A: 'After you add the entitlement certificate to your account, click Request New License. Select your product and quantity and assign a host ID, then click Assign Licenses. After the licenses are generated, download or email the license files and installation instructions.'
            },
            {
                Q: 'How do I install my new license files?',
                A: 'Refer to the installation instructions that are included with the emailed or downloaded license files. If you still need help, please use the “Contact us” link in the header to request assistance.'

            },
            {
                Q: 'I requested a license on the wrong host. How do I corrected it?',
                A: 'Please use the “Contact us” link in the header to request the correction. A Keysight representative will contact you and correct the license.'
            },
            {
                Q: 'I need help to find the host ID of my instrument.',
                A: 'Refer to the documentation for your instrument or use the “Contact us” link in the header to request help.'
            },
            {
                Q: 'How can I see all my existing entitlements, licenses, host IDs, and expiration dates or licenses and support?',
                A: 'On Home or Navigation Menu, click “View by Product or Host” under Licenses and Hosts.'
            }
        ],
        
        //sync help
        [ksmSyncLicensesKey]: 'KSM Sync Licenses',
        [syncingYourLicenseFileKey]: 'Syncing Your License File',
        [helpSyncLicenseNavigationSectionKey]: {
            'section_one': 'Use the following options to navigate through each screen:',
            'section_one_bullets': [
                'Click the arrow button ( <b><</b> and <b>></b> ) to navigate to the previous/next step. The buttons are disabled (grayed out) if it is not possible to go to the previous/next step or if one does not exist.',
                'Click the step number to move to a specific step. Navigation is disabled if more data is needed to go to that step.'
            ]
        },
        [helpSyncLicenseSyncingYourLicenseFileSectionKey]: {
            'section_one': 'Sync allows you to get  updated license files to renew a support agreement or restore your licenses. Follow these steps.',
            'section_two': '<b>Note:</b> Any expired time-based licenses will be removed from the new license file.',
            'section_three_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'Initiate the request on the <b>Sync Licenses</b> page by either uploading a request file or providing the host ID:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'If your host has the ILU tool, generate an offline request file with the tool, then click <b>Add Request File</b> to upload the file.'
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Ensure that at least one of the corresponding certificates has been added to your account, then enter your host ID and click <b>Find Host</b>.',
                            img: 'ksm-sync-licenses-upload'
                        }
                    ]
                },
                {
                    order: 2,
                    type: '1',
                    step: 'When KSM displays your current licenses and corresponding support end dates, click <b>Request Sync.<b/>',
                    img: 'ksm-sync-licenses-request'
                },
                {
                    order: 3,
                    type: '1',
                    step: 'KSM generates the licenses. Spinning dots indicate your licenses are being generated.',
                    img: 'ksm-sync-licenses-spinner'
                },
                {
                    order: 4,
                    type: '1',
                    step: 'When your license files are ready:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Click the email or download button to obtain your licenses.'
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Click <b>Sync Another Host</b> to obtain additional licenses.',
                            img: 'ksm-sync-licenses-another-host'
                        }
                    ]
                }
            ]
        },
        [helpSyncLicenseErrorHandlingSectionKey]: {
            'section_one': 'Delays or error occasionally occur while processing requests. If you experience either, please try again. If the problem persists, use the <b>Contact Us</b> link at the top of the page to let us know.'
        },
        [helpSyncLicensesEntitlementCertificatesQAKey]: [
            {
                'Q': 'What is an Entitlement Certificate?',
                'A': 'The certificate is the document provided by Keysight that entitles you to obtain licenses.'
            },
            {
                'Q': 'I have a single entitlement certificate for multiple products and/or quantities, but different people need to install and manage the licenses. How can we share the certificate?',
                'A': 'Multiple users can manage licenses from one certificate. Share the order number and certificate number with your colleagues so they can also register in KSM and obtain their licenses. Because KSM keeps track of the licenses redeemed for each certificate, licenses can be obtained by different accounts until they have all been obtained.'
            },
            {
                'Q': 'I bought my licenses from Ixia a few years back and do not have entitlement certificates. How do I associate them to my account?',
                'A': 'Please click the “Contact us” link at the top of the page to request assistance. A Keysight Representative will contact you and provide your entitlement certificates.'
            }
        ],
        [helpSyncLicensesLicensesQAKey]: [
            {
                'Q': 'How do I install my new license files?',
                'A': 'Refer to the installation instructions that are included with the emailed or downloaded license file. If you still need help, please contact Keysight for assistance.'
            },
            {
                'Q': 'Why were my expired time-based licenses removed from my license files?',
                'A': 'KSM cannot generate a license file for expired time-based licenses. They sync process removes expired licenses.'
            },
            {
                'Q': 'How can I see all my existing entitlements, licenses, host IDs, and expiration dates or licenses and support?',
                'A': 'On Home or Navigation Menu, click “View by Product or Host” under Licenses and Hosts.'
            }
        ],
        
        //view vert help
        [ksmViewCertKey]: 'KSM View Certificate',
        [viewingCertKey]: 'Viewing Certificates',
        [helpViewCertificateViewingCertificateSectionKey]: {
            'section_one': 'KSM lets you view the order details for each of your certificates. You can see your products, support, and available quantity. You can also export the details to Excel. This can be helpful if you have multiple certificates and need to share a specific certificate with another user.',
            'section_two': 'On the <b>Find/View Certificates</b> page, you can:',
            'section_two_bullets': [
                'Navigate through your certificates in the <b>My Certificates</b> table (a)',
                'Sort your information using the filters on each column (b)',
                'Click on any row to display the order details below the table (c)'
            ]
        },
        [helpViewCertificateQuickStepsSectionKey]: {
            'section_one': 'You can obtain licenses after your certificate is added to your account.',
            'section_one_numbers': {
                1: 'Ensure your entitlement certificate has been added to your account.',
                2: 'On Home or Navigation menu, click <b>Request New Licenses</b>.',
                3: 'Select the quantity and assign a host, then click <b>Assign Licenses</b>.',
                4: 'After your license is generated, choose either the <b>Download or eMail option</b>. You will receive the license file and installation instructions.',
            }
        },
        [helpViewCertificateLoginRegistrationQAKey]: [
            {
                'Q': 'I do not have an account on Keysight. What do I need to register?',
                'A': 'You need the order number and certificate ID located at the top of your entitlement certificate to access KSM.'
            },
            {
                'Q': 'I have an account but forgot my password. How do I reset it?',
                'A': 'Click the “Forgot your password” link on the login page to request a password reset.'
            }
        ],
        [helpViewCertificateWelcomeQAKey]: [
            {
                'Q': 'I would like to see KSM in a different language. How do I change the language?',
                'A': 'KSM supports a few additional languages. Use the globe link on the header to change to the desired country. If that country’s language is not available, the text will display in English.',
            }
        ],
        [helpViewCertificateEntitlementCertificatesQAKey]: [
            {
                'Q': 'What is an Entitlement Certificate?',
                'A': 'The certificate is the document provided by Keysight that entitles you to obtain licenses.',
            },
            {
                'Q': 'I have a single entitlement certificate for multiple products and/or quantities, but different people need to install and manage the licenses. How can we share the certificate?',
                'A': 'Multiple users can manage licenses from one certificate. Share the order number and certificate number with your colleagues so they can also register in KSM and obtain their licenses. Because KSM keeps track of the licenses redeemed for each certificate, licenses can be obtained by different accounts until they have all been obtained.',
            }
        ],
        [helpViewCertificateAddingCertificatesQAKey]: [
            {
                'Q': 'I just purchase software from Keysight and got a new certificate. How do I get my licenses?',
                'A': 'Go to www.keysight.com/find/software manager. If you already have an account, log in and add the certificate, then click Request Licenses Now. If you do not have an account, register using your certificate, then click Request New Licenses under the License Management tile.'
            },
            {
                'Q': 'Why does my certificate show zero quantity?',
                'A': 'All available licenses have been redeemed.'
            }
        ],
        [helpViewCertificateObtainingLicensesKey]: [
            {
                'Q': 'How do I obtain my licenses?',
                'A': 'After you add the entitlement certificate to your account, click Request New License. Select your product and quantity and assign a host ID, then click Assign Licenses. After the licenses are generated, download or email the license files and installation instructions.'
            },
            {
                'Q': 'How do I install licenses?',
                'A': 'Refer to the installation instructions that are included with the emailed or downloaded license file. If you still need help, please use the Contact Us link on the header to request assistance.'
            },
            {
                'Q': 'How can I see all my existing entitlements, licenses, host IDs, and expiration dates or licenses and support?',
                'A': 'On Home or Navigation Menu, click View by Product or Host under Licenses and Hosts.'
            }
        ],
        
        //ea summary help
                [eaSummaryKey]: 'EA Summary',
        [typesEAsKey]: 'Types of EAs',
        [myEnterpriseAgreementsKey]: 'My Enterprise Agreements',
        [gettingStartedKey]: 'Getting Started',
        [typesOfEnterpriseAgreementsKey]: 'Types of Enterprise Agreements',
        [hostsKey]: 'Hosts',
        [eaAccessKey]: 'EA Access',
        [helpEASummaryQuickStepsSectionKey]: {
            section_one: 'The following steps let you get licenses for any products available under the terms of your agreement.',
            section_one_bullets: [
                'On Home or Navigation menu, click <b>Manage Enterprise Agreements</b>.',
                'Click the 3-bar icon next to the agreement you want to work on, and select <b>Get Licenses</b>, <b>Work on Current Period</b>, or <b>Remix Next Period</b>.',
                'Select products and add them to your cart.',
                'Click <b>Assign Products to Hosts</b>. Select the products you want to assign to a particular host and provide the host information. Repeat this process to assign products to hosts.',
                'Preview your licenses to ensure the assignments are correct, then request the licenses.',
                'Download or email the licenses when they are ready. For each host, you will receive one or more messages or download packages that contains the license file and installation instructions.'
            ],
            section_two: 'Other help pages explain these steps in detail.'
        },
        [helpEASummaryNavigationSectionKey]: {
            section_one: 'As you go through the process of requesting your licenses, you can navigate to other pages at any time. Products will remain in your cart and host assignments will remain saved, allowing you to pick up where you left off.'
        },
        [helpEASummaryEATypesSectionKey]: {
            section_one: 'KSM offers two types of agreements:',
            section_one_bullets: [
                '<b>Periodic EAs</b> allow you to get licenses quarterly, semi-annually, or annually.',
                '<b>Variable EAs</b> allow you to get licenses at any time and let you choose the desired duration for each license.'
            ],
            section_one_sub_bullets_one: [
                'The start and end dates for all licenses match the duration of the period.',
                'You can get your first set of licenses as soon as you get access to your EA.',
                'You can get a new set of licenses when the remix window opens 15 days before the start of the next period.'
            ],
            section_one_sub_bullets_one_three: [
                'During this time you can copy your selections from the current period and make changes to the copied selections, or start from scratch. ',
                'KSM sends you an email notification when the remix window opens.',
            ],
            section_one_sub_bullets_two: [
                'You can get your first set of licenses as soon as you get access to your EA.',
                'You can get new licenses as soon as you have funds available in your subscription pool. Funds are freed up 5 days before your licenses expire.',
                'The default license duration is 90 days. You can change the expiration date when you assign the license to a host.',
                'You can copy the previously issued product(s) selection and host assignment to request the license again, using the \'Request Again\' feature in the Hamburger menu.',
                'If your subscription pool consumption falls below 80%, KSM will email you monthly to remind you that you have funds available for more licenses.'
            ],
            section_two: 'Expansion Pool Agreements',
            section_three: 'An expansion pool EA may be available to you to manage emergencies. This additional agreement is a variable EA. It should be used only if your basic agreement is maxed out and you urgently need more licenses. If you request licenses through your expansion pool, Keysight will invoice you according to the terms of your contract.'
        },
        [helpEASummaryMyEnterpriseAgreementsSectionKey]: {
            section_one: 'When you click on the <b>Enterprise Agreements</b> module, KSM displays all enterprise agreements (EAs) associated to your KSM account.',
            section_two: 'The table shows information about your agreements:',
            section_two_bullets: [
                '<b>Start Date</b> and <b>End Date</b> show the duration for the agreement.',
                '<b>Remix Type</b> identifies the type of EA. KSM supports periodic agreements and variable agreements, which are described above.',
                '<b>Total Subscription Pool</b> shows the maximum cost of your active licenses. Your agreement may have different subscription pool limits for each year of the agreement. You can check the amounts available for each year by selecting <b>EA License View</b> from the icon menu.',
                '<b>Remaining Subscription Pool</b> shows the amount you can use to get new licenses. You can get licenses at any time for the duration of the EA as long as you have subscription pool available. Funds become available when licenses expire.',
                '<b>Remix Opens On</b> shows when your remix window starts. You can copy your licenses from the previous period when the remix option is shown on the menu.'
            ],
            section_three: '<b>Note:</b> Expired EAs are grayed out. You can view the details of an expired account.'
        },
        [helpEASummaryGettingStartedSectionKey]: {
            section_one_prefix: 'Click the menu icon (',
            section_one_suffix_one: ') in the first column of an EA to get started. The options available depend on the type of agreement you have, your remix window, and current',
            section_one_suffix_two: 'selections you may already have on your EA.',
            section_two: 'Periodic EA Options',
            section_three: 'The options vary depending on where you are in your quarterly, semi-annual, or annual cycle.',
            section_three_bullets: [
                '<b>EA License View<b/>:',
                '<b>Work on Current Period</b> takes you to product selection for the current period, where you can add products to your cart and get licenses, provided you have availability in your subscription pool.',
                '<b>Remix Next Period</b> becomes available 15 days before the start of the next period. Three options let you choose how to get started on the next period:',
                '<b>Work on Next Period</b> takes you to product selection for the next period, where you can add products to your cart and get licenses, provided you have availability in your subscription pool. It is only available during the remix window.'
            ],
            section_three_sub_bullets_one: [
                'Shows your licenses and allows you to download license files',
                'Lets you view the information for any period'
            ],
            section_three_sub_bullets_three: [
                '<b>Copy Products and Hosts</b> copies all products and host assignments from the current period to the next period. You can make changes before requesting your new licenses.',
                '<b>Copy Products Only</b> lets you copy all products from the current period to the next period, then reassign them to different hosts. You can make changes before requesting your new licenses.',
                '<b>Start From Scratch</b> lets you start on product selection without any data copied.'
            ],
            section_three_sub_three: [
                'If you choose a copy option, you can add or delete products, change quantities, and delete or assign hosts as needed after your data is copied and before requesting your licenses.',
                '<b>Note:</b> The remix options remain available until you copy your current information or add a product to your cart if you start from scratch. After that, you will see <b>Work on Next Period</b>. Remix options are not available if the subscription pool for your next period is smaller than the one for the previous period.',
            ],
            section_three_sub_four: '<b>Note:</b> When the remix window is over, any work in progress remains available. The <b>Work on Current Period</b> option lets you pick up where you left off.',
            section_four: 'Variable EA Options',
            section_five: 'Three options are available.',
            section_five_bullets: [
                '<b>EA License View</b> shows your requested licenses and allows you to download license files.',
                '<b>Get Licenses</b> takes you to product selection for the current period, where you can add products to your cart and get licenses, provided you have availability in your subscription pool.',
                '<b>Request Again</b> lets you copy the previously issued product(s) selection and host assignment to request the license again.'
            ]
        },
        [helpEASummaryErrorHandlingSectionKey]: {
            section_one: 'Occasionally, errors may occur while processing your request for licenses:',
            section_one_bullets: [
                'If your request does not go through, your work in progress will be retained, allowing you to process the request later.',
                'Click the Contact Us link in the header to contact Keysight at any time for additional support on any issues or to submit feedback. '
            ]
        },
        [helpEASummaryEnterpriseAgreementsQAKey]: [
            {
                'Q': 'What information do I need to get my licenses?',
                'A': 'You need to know which products you need to license and the host IDs for the instruments or computers that will run the licensed software.'
            },
            {
                'Q': 'Where can I see the details for my EA?',
                'A': 'Click the menu icon for the agreement you want to see, then select <b>EA License View</b>.'
            },
            {
                'Q': 'Where can I see a list of all the products available within my EA?',
                'A': 'The terms of your agreement determine which products you are allowed to select, and KSM limits your selections to those products. You also can refer to your agreement paperwork from Keysight.'
            },
            {
                'Q': 'I have reached my subscription pool limit, but we need more licenses. Who should I contact?',
                'A': 'If you have an expansion pool EA, you can use it to get additional licenses. If you do not have an expansion pool, please contact your sales representative.'
            },
            {
                'Q': 'How do annual, quarterly, and semi-annual EAs work?',
                'A': 'A periodic remix EA is either quarterly, semi-annual, or annual. The EA allows you to get a new set of licenses every period. All licenses activated during a given period have the same start and end dates, set to the start and end date of the period you are working on.<br/><br/>KSM sends you an email when the remix window for the next period opens, which is 15 days before the start date. You can start requesting new licenses during this window. A subscription pool is defined for each year of your agreement. The subscription pool represents the total cost of all licenses you can activate within each period. You can request additional licenses during the current period if you have availability in your subscription pool, but you cannot make changes to existing licenses.<br/><br/>Product selection is limited to the products assigned to your EA.'
            },
            {
                'Q': 'How does a variable EA work?',
                'A': 'A variable EA allows you to get licenses at any time if you have availability in your subscription pool. The subscription pool represents the total cost of all licenses that are currently active.<br/><br/>The default duration for licenses is 90 days. You can change to the desired duration when you assign the license to a host. The end date can be any date before the end of your agreement. Five days before a license expires, the cost of the license is freed up in your subscription pool, allowing you to use the funds for different licenses.<br/><br/>When your subscription pool has more than 20% capacity available, KSM sends you a notification email once a month.<br/><br/>Product selection is limited to the products assigned to your EA.\n'
            }
        ],
        [helpEASummaryLicensesQAKey]: [
            {
                'Q': 'When can I start getting licenses for my EA?',
                'A': 'Any time after you get the “Welcome to your new Keysight Enterprise Agreement” email. Follow the instructions provided to start getting your licenses.'
            },
            {
                'Q': 'When can I get a new set of licenses for the next period of my EA?',
                'A': 'The periodic remix option is available 15 days before the new period start date. When the remix window opens you will see additional options within the EA menu that allow you to copy your selections from the current period or start from scratch.'
            },
            {
                'Q': 'I requested a license with an incorrect host or product. How can I get it corrected?',
                'A': 'Contact our support team at <u><a href="mailto:eesof_bsc@keysight.com">eesof_bsc@keysight.com</a></u> and ask them to cancel the corresponding license.'
            },
            {
                'Q': 'My instrument/host broke down. How do I get a new license?',
                'A': 'Contact our support team at <u><a href="mailto:eesof_bsc@keysight.com">eesof_bsc@keysight.com</a></u> and ask them to rehost the corresponding license.'
            },
            {
                'Q': 'I have software from Ixia that requires an activation code. Can I get the activation code from KSM?',
                'A': 'The portal only supports offline activation for ILU compatible products. You will be able to upload your request file during host assignment.'
            },
            {
                'Q': 'Can I return a transportable license to Keysight and redeem it again?',
                'A': 'Yes, you can return a transportable license using Pathwave or KLM. To get a new license, click <b>Request New Licenses</b> link on Home or Navigation Menu.'
            },
            {
                'Q': 'I closed the request license screen before I emailed or downloaded my licenses. How can I get my licenses?',
                'A': 'Go to <b>EA License View</b>. You can select multiple products and then click the download or email button to get the licenses, or you can click the download button for a particular host to receive a license for that host.'
            },
            {
                'Q': 'I have a variable EA and need to change the end date of my license from the 90 days default. How do I change the end date?',
                'A': 'Complete your product selection and host assignment. After the host is assigned, expand the host and change the end date to the desired date.'
            },
            {
                'Q': 'In addition to my base EA , I have an expansion pool agreement. How does the expansion pool work?',
                'A': 'Use the expansion pool only when your base subscription pool has been consumed and you still need additional licenses. When you request a license from the expansion pool, Keysight evaluates your usage and invoices your company according to the terms of your agreement.'
            },
            {
                'Q': 'I have a variable agreement and have consumed all of my subscription pool. When can I get a new license?',
                'A': 'You can get another license when funds are freed up in your pool. For variable agreements, funds for an expiring license are freed up five days before the license expires.'
            },
            {
                'Q': 'I requested licenses and some or all licenses were not generated within the allotted time. How do I get the missing licenses?',
                'A': 'The Keysight support team will fix the issue and email you your missing licenses. Contact us via email at <u><a href="mailto:eesof_bsc@keysight.com">eesof_bsc@keysight.com</a></u>. You can also download your license from the EA License View when the licenses become available on the KSM server.'
            }
        ],
        [helpEASummaryHostsQAKey]: [
            {
                'Q': 'I have some hosts that require a request file to get a license. How do I get licenses for those type of hosts?',
                'A': 'You need to upload your request file when you assign a product to the host.'
            },
            {
                'Q': 'I have some hosts that require a C2V/V2C file to get a license. How do I get licenses for those type of hosts?',
                'A': 'They require help from Keysight. After you request the license, a Keysight representative will contact you via email to get your C2V file. Keysight will then generate your license and email a V2C file to you.'
            }
        ],
        [helpEASummaryEAAccessQAKey]: [
            {
                'Q': 'How can my colleagues get access to an EA?',
                'A': 'Forward the “Welcome to your new Keysight Enterprise Agreement” email to your colleagues who need access. They can then create a KSM account and add the certificate, or add the certificate to their existing account. Because all users will share the same subscription pool, product selections and host assignments, coordination is recommended.'
            }
        ],
        
        //ea details help
                [shortcutProductSelectionKey]: 'Shortcut to Product Selection',
        [subscriptionPoolStatusKey]: 'Subscription Pool Status',
        [helpEADetailedViewShortcutSectionKey]: {
            section_one: 'You can access the product selection screen directly from <b>EA License View</b> by clicking <b>Assign More Licenses</b>. This button shows only when subscription pool is available and the period is open.'
        },
        [helpEADetailedViewEALicenseViewSectionKey]: {
            section_one: '<b>EA License View</b> displays the details of your agreement and your licenses. This page shows:',
            section_one_steps: [
                {
                    order: 1,
                    type: 'a',
                    step: 'The name of your company and your email address.'
                },
                {
                    order: 2,
                    type: 'a',
                    step: 'Details of your agreement, including the start/end dates and remix type.'
                },
                {
                    order: 3,
                    type: 'a',
                    step: 'The status of your subscription pool.'
                },
                {
                    order: 4,
                    type: 'a',
                    step: 'Options for viewing licenses, which can be grouped by host ID or product:',
                    substeps: [
                        {
                            order: 1,
                            type: 'dash',
                            step: 'For Periodic EAs, you can see license information for different periods.'
                        },
                        {
                            order: 2,
                            type: 'dash',
                            step: 'For Variable EAs, you can choose to show or hide expired licenses.'
                        }
                    ]
                }
            ],
            section_two: '<b>Periodic EA</b>',
            section_three: '<b>Variable EA</b>',
            section_four: 'Two buttons let you perform actions:',
            section_four_bullets: [
                '<b>Assign More Licenses</b> lets you add licensed products to your agreement.',
                '<b>Export to Excel</b> saves your agreement and license information in a file named KSM_EA_DETAILS in your downloads folder.'
            ],
            section_five: 'As you add licenses to your agreement, more information is displayed:',
            section_five_steps: [
                {
                    order: 1,
                    type: 'a',
                    step: 'Your subscription pool chart and information are updated to show the amount spent on licenses you have, the amount allocated for products in your cart, and the amount available.'
                },
                {
                    order: 2,
                    type: 'a',
                    step: 'Host and product information is displayed and can be grouped by host ID (as shown below) or by product.'
                },
                {
                    order: 3,
                    type: 'a',
                    step: 'The arrow next to the host ID or product lets you show or hide details.'
                }
            ],
            section_six: 'When grouped by host ID:',
            section_six_steps: [
                {
                    order: 4,
                    type: 'a',
                    step: 'The download icon button lets you get a license file for a particular host.'
                },
                {
                    order: 5,
                    type: 'a',
                    step: 'Checkboxes lets you select multiple hosts and download or email the licenses for those hosts.'
                }
            ],
            section_seven: 'When grouped by product, you can see all hosts that have a license for each product. Licenses cannot be downloaded or emailed on this view.'
        },

        [helpEADetailedViewSubscriptionPoolSectionKey]: {
            section_one: 'KSM displays information on your subscription pool status and updates the information as your work on your agreement. You can always see your total subscription pool, the amount allocated to products in your cart, and the amount consumed by licenses already issued. In the subscription pool chart:',
            section_one_bullets: [
                '<b>White</b> shows the amount that is not yet used.',
                '<b>Light purple</b> shows the amount for products you have selected but have not yet licensed.',
                '<b>Dark purple</b> shows the amount for products you have licensed.'
            ],
            section_two: 'The table below shows some possible states for your pool.',
            section_two_table_1_2: "Display",
            section_two_table_2_1: "<b>Initial State, 100% available</b><br/><br/>No products have been selected.",
            section_two_table_3_1: "<b>Selection has begun but is not complete</b><br/><br/>You can select more products or obtain licenses for the selected products.",
            section_two_table_4_1: "<b>Subscription pool is consumed, not all products are licensed</b><br/><br/>You are finished selecting products and are ready to obtain licenses for the selected products."
        },
        [helpEADetailedViewEATypesSectionKey]: {
            section_one: 'KSM offers two types of agreements:',
            section_one_bullets: [
                '<b>Periodic EAs</b> allow you to get licenses quarterly, semi-annually, or annually.',
                '<b>Variable EAs</b> allow you to get licenses at any time and let you choose the desired duration for each license.'
            ],
            section_one_sub_bullets_one: [
                'The start and end dates for all licenses match the duration of the period.',
                'You can get your first set of licenses as soon as you get access to your EA.',
                'You can get a new set of licenses when the remix window opens 15 days before the start of the next period.'
            ],
            section_one_sub_bullets_one_three: [
                'During this time you can copy your selections from the current period and make changes to the copied selections, or start from scratch. ',
                'KSM sends you an email notification when the remix window opens.',
            ],
            section_one_sub_bullets_two: [
                'You can get your first set of licenses as soon as you get access to your EA.',
                'You can get new licenses as soon as you have funds available in your subscription pool. Funds are freed up 5 days before your licenses expire.',
                'The default license duration is 90 days. You can change the expiration date when you assign the license to a host.',
                'You can copy the previously issued product(s) selection and host assignment to request the license again, using the \'Request Again\' feature in the Hamburger menu.',
                'If your subscription pool consumption falls below 80%, KSM will email you monthly to remind you that you have funds available for more licenses.'
            ],
            section_two: 'Expansion Pool Agreements',
            section_three: 'An expansion pool EA may be available to you to manage emergencies. This additional agreement is a variable EA. It should be used only if your basic agreement is maxed out and you urgently need more licenses. If you request licenses through your expansion pool, Keysight will invoice you according to the terms of your contract.'
        },
        [helpEADetailedViewErrorHandlingSectionKey]: {
            section_one: 'Occasionally, errors may occur while processing your request for licenses:',
            section_one_bullets: [
                'If your request does not go through, your work in progress will be retained, allowing you to process the request later.',
                'Click the Contact Us link in the header to contact Keysight at any time for additional support on any issues or to submit feedback. '
            ]
        },
        [helpEADetailedViewEnterpriseAgreementsQAKey]: [
            {
                'Q': 'What information do I need to get my licenses?',
                'A': 'You need to know which products you need to license and the host IDs for the instruments or computers that will run the licensed software.'
            },
            {
                'Q': 'Where can I see the details for my EA?',
                'A': 'Click the menu icon for the agreement you want to see, then select <b>EA License View</b>.'
            },
            {
                'Q': 'Where can I see a list of all the products available within my EA?',
                'A': 'The terms of your agreement determine which products you are allowed to select, and KSM limits your selections to those products. You also can refer to your agreement paperwork from Keysight.'
            },
            {
                'Q': 'I have reached my subscription pool limit, but we need more licenses. Who should I contact?',
                'A': 'If you have an expansion pool EA, you can use it to get additional licenses. If you do not have an expansion pool, please contact your sales representative.'
            },
            {
                'Q': 'How do annual, quarterly, and semi-annual EAs work?',
                'A': 'A periodic remix EA is either quarterly, semi-annual, or annual. The EA allows you to get a new set of licenses every period. All licenses activated during a given period have the same start and end dates, set to the start and end date of the period you are working on.<br/><br/>KSM sends you an email when the remix window for the next period opens, which is 15 days before the start date. You can start requesting new licenses during this window. A subscription pool is defined for each year of your agreement. The subscription pool represents the total cost of all licenses you can activate within each period. You can request additional licenses during the current period if you have availability in your subscription pool, but you cannot make changes to existing licenses.<br/><br/>Product selection is limited to the products assigned to your EA.'
            },
            {
                'Q': 'How does a variable EA work?',
                'A': 'A variable EA allows you to get licenses at any time if you have availability in your subscription pool. The subscription pool represents the total cost of all licenses that are currently active.<br/><br/>The default duration for licenses is 90 days. You can change to the desired duration when you assign the license to a host. The end date can be any date before the end of your agreement. Five days before a license expires, the cost of the license is freed up in your subscription pool, allowing you to use the funds for different licenses.<br/><br/>When your subscription pool has more than 20% capacity available, KSM sends you a notification email once a month.<br/><br/>Product selection is limited to the products assigned to your EA.\n'
            }
        ],
        [helpEADetailedViewLicensesQAKey]: [
            {
                'Q': 'When can I start getting licenses for my EA?',
                'A': 'Any time after you get the “Welcome to your new Keysight Enterprise Agreement” email. Follow the instructions provided to start getting your licenses.'
            },
            {
                'Q': 'When can I get a new set of licenses for the next period of my EA?',
                'A': 'The periodic remix option is available 15 days before the new period start date. When the remix window opens you will see additional options within the EA menu that allow you to copy your selections from the current period or start from scratch.'
            },
            {
                'Q': 'I requested a license with an incorrect host or product. How can I get it corrected?',
                'A': 'Contact our support team at <u><a href="mailto:eesof_bsc@keysight.com">eesof_bsc@keysight.com</a></u> and ask them to cancel the corresponding license.'
            },
            {
                'Q': 'My instrument/host broke down. How do I get a new license?',
                'A': 'Contact our support team at <u><a href="mailto:eesof_bsc@keysight.com">eesof_bsc@keysight.com</a></u> and ask them to rehost the corresponding license.'
            },
            {
                'Q': 'I have software from Ixia that requires an activation code. Can I get the activation code from KSM?',
                'A': 'The portal only supports offline activation for ILU compatible products. You will be able to upload your request file during host assignment.'
            },
            {
                'Q': 'Can I return a transportable license to Keysight and redeem it again?',
                'A': 'Yes, you can return a transportable license using Pathwave or KLM. To get a new license, click <b>Request New Licenses</b> link on Home or Navigation Menu.'
            },
            {
                'Q': 'I closed the request license screen before I emailed or downloaded my licenses. How can I get my licenses?',
                'A': 'Go to <b>EA License View</b>. You can select multiple products and then click the download or email button to get the licenses, or you can click the download button for a particular host to receive a license for that host.'
            },
            {
                'Q': 'I have a variable EA and need to change the end date of my license from the 90 days default. How do I change the end date?',
                'A': 'Complete your product selection and host assignment. After the host is assigned, expand the host and change the end date to the desired date.'
            },
            {
                'Q': 'In addition to my base EA , I have an expansion pool agreement. How does the expansion pool work?',
                'A': 'Use the expansion pool only when your base subscription pool has been consumed and you still need additional licenses. When you request a license from the expansion pool, Keysight evaluates your usage and invoices your company according to the terms of your agreement.'
            },
            {
                'Q': 'I have a variable agreement and have consumed all of my subscription pool. When can I get a new license?',
                'A': 'You can get another license when funds are freed up in your pool. For variable agreements, funds for an expiring license are freed up five days before the license expires.'
            },
            {
                'Q': 'I requested licenses and some or all licenses were not generated within the allotted time. How do I get the missing licenses?',
                'A': 'The Keysight support team will fix the issue and email you your missing licenses. Contact us via email at <u><a href="mailto:eesof_bsc@keysight.com">eesof_bsc@keysight.com</a></u>. You can also download your license from the EA License View when the licenses become available on the KSM server.'
            }
        ],
        [helpEADetailedViewHostsQAKey]: [
            {
                'Q': 'I have some hosts that require a request file to get a license. How do I get licenses for those type of hosts?',
                'A': 'You need to upload your request file when you assign a product to the host.'
            },
            {
                'Q': 'I have some hosts that require a C2V/V2C file to get a license. Can I get licenses for those type of hosts?',
                'A': 'Yes, but those require help from Keysight. After you request the license, a Keysight representative will contact you via email to get your C2V file. Keysight will then generate your license and email a V2C file to you.'
            }
        ],
        [helpEADetailedViewEAAccessQAKey]: [
            {
                'Q': 'How can my colleagues get access to an EA?',
                'A': 'Forward the “Welcome to your new Keysight Enterprise Agreement” email to your colleagues who need access. They can then create a KSM account and add the certificate, or add the certificate to their existing account. Because all users will share the same subscription pool, product selections and host assignments, coordination is recommended.'
            }
        ],

        //ea request license help
                [eaLicenseRequestsKey]: 'EA License Requests',
        [quickStepToGetLicensesKey]: 'Quick Step to Get Licenses',
        [assigningProductsToHostsKey]: 'Assigning Products to Hosts',
        [changingLicenseEndDatesKey]: 'Changing License End Dates',
        [gettingLicensesKey]: 'Getting Licenses',
        [helpEARequestLicenseQuickStepsSectionKey]: {
            section_one: 'Follow the steps below to your get your EA licenses: ',
            section_one_steps: [
                {
                    order: 1,
                    type: '1',
                    step: 'Select the desired products, quantities, and license types and add them to your cart.'
                },
                {
                    order: 2,
                    type: '1',
                    step: 'Select the products you want to assign to a particular host and provide the host information. Repeat this process to assign all desired products to hosts.'
                },
                {
                    order: 3,
                    type: '1',
                    step: 'Preview your licenses to ensure the products, quantities, license types, end dates, and host assignments are correct, then request the licenses.'
                },
                {
                    order: 4,
                    type: '1',
                    step: 'Download or email the licenses when they are ready. For each host, you will receive one or more messages or download packages that contains the license files and installation instructions.'
                }
            ]
        },
        [helpEARequestLicenseNavigationSectionKey]: {
            section_one: 'You can use the options below to navigate through each screen:',
            section_one_bullets: [
                'As you go through the process, the action buttons on the pages will move you forward.',
                'You also can use the navigation tool to move to a specific step, and you can go back and forth in a way that works best for you. Navigation is disabled if more data is needed to go to that step.',
            ]
        },
        [helpEARequestLicenseSelectingProductsSectionKey]: {
            section_one: 'To get started, identify the products you need and add them to cart.',
            section_one_steps: [
                {
                    order: 1,
                    type: '1',
                    step: 'Use the software family drop-down and/or the text search field to locate the products you want:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Use the <b>Search Software Family</b> drop-down to search all product families or within a specific product family. Select:',
                            substeps: [
                                {
                                    order: 1,
                                    type: 'dash',
                                    step: '<b>Any</b> to show all products available within your agreement'
                                },
                                {
                                    order: 2,
                                    type: 'dash',
                                    step: 'A specific software family to limit the search to products in that group'
                                }
                            ]
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'You can enter a partial or full product number or product description in the second field to limit the results to products that include the text in either the product number or description.',
                            img: 'ksm-ea-request-license-select-products'
                        }
                    ]
                },
                {
                    order: 2,
                    type: 'a',
                    step: 'Specify the product details and add the product to your cart:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'In the <b>Qty</b> field, enter the number of licenses you want for the product.'
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'If multiple license types are available, select a license type. The license type determines the cost (node-locked licenses are the lowest; floating worldwide licenses are the highest).'
                        },
                        {
                            order: 3,
                            type: 'a',
                            step: 'View the cost per unit and total cost for your selection.'
                        },
                        {
                            order: 4,
                            type: 'a',
                            step_prefix: 'Click the <b>Add to Cart</b> icon (',
                            step_suffix: ') in the last column.',
                            img: 'ksm-ea-request-license-add-to-cart'
                        }
                    ]
                },
                {
                    order: 3,
                    type: 'a',
                    step: 'Repeat steps 1 and 2 to locate and add additional products. You can continue to add products to the cart until you are ready to request licenses or have no more subscription pool available.'
                }
            ],
            section_one_step_1b: 'For example, you could search for the following:',
            section_one_step_1b_bullets: [
                '<b>Pathwave Design Software</b> family with no search string to find all Pathwave products',
                '<b>Pathwave Design Software</b> family and the string <b>RF</b> to find all Pathwave RF products',
                'Any software family with the string <b>LTE</b> or <b>5G</b> to find wireless broadband products',
                'Any software family and a product number such as <b>89601301C</b> or <b>W4804B</b> to find a particular product'
            ],
            section_one_step_2d1: '<b>Note:</b> The add to cart icon is enabled only when the quantity and license type are provided.',
            section_one_step_2d2: 'As you add products:',
            section_one_step_2d_bullets: [
                'The products are displayed in the <b>My Cart</b> table.',
                'The corresponding costs are added to the <b>My Cart</b> total and deducted from the <b>Available total</b> in the subscription pool status.'
            ],
            section_two: '<b>Note:</b> Products remain in your cart if you close your browser or log out of KSM, allowing you to continue your work at another time.',
            section_three: 'Managing Your Cart',
            section_four: 'KSM displays the <b>My Cart</b> table as long as you have products added to your cart. When a license for a given product is issued, the quantity is reduced in the table. When all licenses for the product are issued, the product is removed from the table.',
            section_five: 'It is not possible to change the quantity or license type for the products already added to your cart. If a change is needed, delete the product from your cart and add it again with the correct quantity and license type.',
            section_six: 'When you delete a product that has no host assignments, the total quantity is deleted. If you have already assigned the product to one or more hosts, KSM gives you the option of deleting the entire quantity or only the unassigned quantity.',
            section_seven: 'Next Steps',
            section_eight: 'When ready to move to the next step, click the <b>Assign Products to Host</b> button or the step 2 link in the navigation tool.'
        },
        [helpEARequestLicenseAssigningProductsSectionKey]: {
            section_one: 'You can assign a product to a host immediately after you add the product to your cart, select all products and then assign to hosts, or select/assign in batches.',
            section_two: 'Follow these steps to assign a host.',
            section_two_steps: [
                {
                    order: 1,
                    type: 'a',
                    step: 'Click the <b>Assign Products to Host</b> button below your cart or click the link in the navigation tool:',
                    img: 'ksm-ea-request-license-assign-products-navigation'
                },
                {
                    order: 2,
                    type: 'a',
                    step: 'Select the product(s) to assign:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Click the checkbox for all product(s) you want to assign to the same host. KSM grays out products that are not compatible with the first product selected. If preferred, you can use the check all checkbox to select all compatible products at once.'
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Enter a quantity if necessary.'
                        },
                        {
                            order: 3,
                            type: 'a',
                            step: 'If you have a variable EA, use the date picker to change the license end date if desired. The default duration is 3 months. You also can edit this date after you assign the host.'
                        },
                        {
                            order: 4,
                            type: 'a',
                            step: 'Click <b>Assign Products to Host</b>.',
                            img: 'ksm-ea-request-license-assign-products'
                        }
                    ]
                },
                {
                    order: 3,
                    type: 'a',
                    step: 'In the <b>Assign Host</b> window:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Enter the <b>Host ID</b>. When you start entering characters, KSM auto-suggests previously used host IDs that match the characters you enter. Suggestions are based on matching the host ID or alias.'
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Hover the cursor over the host ID field to see an example of the expected format.'
                        },
                        {
                            order: 3,
                            type: 'a',
                            step: 'Optionally, enter <b>Host Meta Data</b>. You can enter an alias for your host, asset information, city, state, country, and notes. You can use the alias to select your host ID the next time you assign a license to this host. Use the other fields for reporting or organizing your hosts.'
                        },
                        {
                            order: 4,
                            type: 'a',
                            step: 'Click <b>Save Host ID</b>.',
                            img: 'ksm-ea-request-license-assign-products-save'
                        }
                    ]
                },
                {
                    order: 4,
                    type: 'a',
                    step: 'KSM validates the format and saves the information, then adds the product to the <b>My Assigned Products</b> table. The host and metadata are saved, allowing you to easily use this host again or edit its metadata with the <b>Manage Hosts</b> tool.'
                },
                {
                    order: 5,
                    type: 'a',
                    step: 'Click the arrow button next to the host to see all products assigned to it. At this point, you can make changes if necessary.',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Change the assigned quantity if desired.'
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'If you have a Variable EA, you can use the date picker to change the license end date from the default 90-day duration. If you have a Periodic EA, you cannot change the license end date. All licenses expire at the end of the term.'
                        },
                        {
                            order: 3,
                            type: 'a',
                            step_prefix: 'Click the delete button (',
                            step_suffix: ') in the host ID row to delete the assignment, or click the button in the first column to remove a ',
                            step_suffix_two: 'single product from the host. The respective quantity is added to your cart and can be assigned to a different host.',
                            img: 'ksm-ea-request-license-assign-products-delete'
                        },
                        {
                            order: 4,
                            type: 'a',
                            step: 'Click <b>Save My Work</b> if you make any changes.',
                            img: 'ksm-ea-request-license-assign-products-details'
                        }
                    ]
                }
            ],
            section_two_step_1: '<b>Note:</b>  The button and link are enabled only when you have products in your cart. At any point you can add more products by clicking <b>Select Products</b> in the navigation tool.',
            section_three: '<b>Notes:</b>',
            section_three_bullets: [
                'At any point you can go back to the first step to add more products.',
                'If products have been assigned in another KSM session, the subscription pool may not be large enough to cover the cost of the licenses. If this occurs, you will need to remove some products from your pending requests.'
            ]
        },
        [helpEARequestLicenseEndDateChangeSectionKey]: {
            section_one: 'If you have a Periodic EA (quarterly, semi-annual, or annual), the end date for your licenses corresponds to the end of the period in which the licenses were issued. You cannot change the end dates.',
            section_two: 'If you have a Variable EA, KSM will assign a default duration of 90 days to your licenses. You can change the end date in the <b>My Cart</b> table before you assign products to a host, or in the <b>My Assigned Products</b> table after the host is assigned:',
            section_two_bullets: [
                'Click the calendar next to the end date to change the end date for one product.',
                'Click the pencil icon in the header row to change the end date for all products assigned to the host.'
            ]
        },
        [helpEARequestLicenseGettingLicensesSectionKey]: {
            section_one: 'You can get licenses for any products that have assigned hosts.',
            section_two: 'Before you proceed check your assigned licenses and end dates and make sure they are all correct.',
            section_two_steps: [
                {
                    order: 1,
                    type: 'a',
                    step: 'In the <b>My Assigned Products</b> section, click <b>License Preview</b>.',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Verify the information displayed. If you are unsure of any configuration, you can unselect a host if you are not ready to get the licenses for that host.'
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Click <b>Request Licenses</b>, then confirm your request. Upon confirmation, KSM generates your licenses. This may take a few seconds, depending on the number of products and hosts you are requesting.',
                            img: 'ksm-ea-request-license-request'
                        }
                    ]
                },
                {
                    order: 2,
                    type: 'a',
                    step: 'When your licenses are ready, KSM:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Updates the subscription pool information above your cart. The cost is added the <b>Consumed</b> amount and subtracted from the <b>My Cart</b> amount.'
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Displays notifications when license generation is in progress and when your licenses are ready. This may take a few seconds to complete.'
                        },
                        {
                            order: 3,
                            type: 'a',
                            step: 'Enables the email and download buttons.',
                            img: 'ksm-ea-request-license-generate'
                        }
                    ]
                }
            ],
            section_three: 'After KSM generates your licenses, you can receive them via either download or email.',
            section_three_bullets: [
                'If you have assigned licenses to multiple hosts, you can select all of the hosts, then use the email or download button to get the license files at the same time.',
                'To get a license file for a single host, click the download button to the right of the host ID. One license file will cover all products for that host.',
                'KSM may send you more than one license file per host, depending on the products you have assigned to your host.'
            ],
            section_four: 'Emailed licenses are attached to a message that provides installation instructions. Downloaded licenses include license files and installation instructions in the download package.',
            section_five: 'Notes:',
            section_five_bullets: [
                'You can go back to the first or second steps at any point to select more products or to assign hosts for products in your cart.',
                'In some cases, the subscription pool may not be large enough to cover the cost of the licenses due to changes made to your agreement in another KSM session. If this occurs, you will need to reduce the cost of your pending requests.',
                'If you do not download or email your licenses now, you can download them later by going to your enterprise agreements and selecting the <b>EA License View</b> option.'
            ],
            section_six: 'Special Handling Products',
            section_seven: 'Certain products require Keysight to fulfill the license. Those products will be grouped together in a <b>Special Handling</b> group. When you request licenses for those products, KSM sends an email to a Keysight representative who will contact you to get the information needed to deliver your licenses.'
        },
        [helpEARequestLicenseErrorHandlingSectionKey]: {
            section_one: 'Occasionally, errors may occur while processing your request for licenses:',
            section_one_bullets: [
                'If your request does not go through, your assignments will continue to be available so you can request them later.',
                'If your request is processed but one or more license files are not generated in the expected time, our Support Team will email your license(s) to you.',
                'Click the <b>Contact us</b> link in the header to contact Keysight at any time for additional support on any issues or to submit feedback.'
            ]
        },
        [helpEARequestLicenseEnterpriseAgreementsQAKey]: [
            {
                'Q': 'What information do I need to get my licenses?',
                'A': 'You need to know which products you need to license and the host IDs for the instruments or computers that will run the licensed software.'
            },
            {
                'Q': 'Where can I see the details for my EA?',
                'A': 'Click the menu icon for the agreement you want to see, then select <b>EA License View</b>.'
            },
            {
                'Q': 'Where can I see a list of all the products available within my EA?',
                'A': 'The terms of your agreement determine which products you are allowed to select, and KSM limits your selections to those products. You also can refer to your agreement paperwork from Keysight.'
            },
            {
                'Q': 'I have reached my subscription pool limit, but we need more licenses. Who should I contact?',
                'A': 'If you have an expansion pool EA, you can use it to get additional licenses. If you do not have an expansion pool, please contact your sales representative.'
            },
            {
                'Q': 'How do annual, quarterly, and semi-annual EAs work?',
                'A': 'A periodic remix EA is either quarterly, semi-annual, or annual. The EA allows you to get a new set of licenses every period. All licenses activated during a given period have the same start and end dates, set to the start and end date of the period you are working on.<br/><br/>KSM sends you an email when the remix window for the next period opens, which is 15 days before the start date. You can start requesting new licenses during this window. A subscription pool is defined for each year of your agreement. The subscription pool represents the total cost of all licenses you can activate within each period. You can request additional licenses during the current period if you have availability in your subscription pool, but you cannot make changes to existing licenses.<br/><br/>Product selection is limited to the products assigned to your EA.'
            },
            {
                'Q': 'How does a variable EA work?',
                'A': 'A variable EA allows you to get licenses at any time if you have availability in your subscription pool. The subscription pool represents the total cost of all licenses that are currently active.<br/><br/>The default duration for licenses is 90 days. You can change to the desired duration when you assign the license to a host. The end date can be any date before the end of your agreement. Five days before a license expires, the cost of the license is freed up in your subscription pool, allowing you to use the funds for different licenses.<br/><br/>When your subscription pool has more than 20% capacity available, KSM sends you a notification email once a month.<br/><br/>Product selection is limited to the products assigned to your EA.\n'
            }
        ],
        [helpEARequestLicenseLicensesQAKey]: [
            {
                'Q': 'When can I start getting licenses for my EA?',
                'A': 'Any time after you get the “Welcome to your new Keysight Enterprise Agreement” email. Follow the instructions provided to start getting your licenses.'
            },
            {
                'Q': 'When can I get a new set of licenses for the next period of my EA?',
                'A': 'The periodic remix option is available 15 days before the new period start date. When the remix window opens you will see additional options within the EA menu that allow you to copy your selections from the current period or start from scratch.'
            },
            {
                'Q': 'I requested a license with an incorrect host or product. How can I get it corrected?',
                'A': 'Contact our support team at <u><a href="mailto:eesof_bsc@keysight.com">eesof_bsc@keysight.com</a></u> and ask them to cancel the corresponding license.'
            },
            {
                'Q': 'My instrument/host broke down. How do I get a new license?',
                'A': 'Contact our support team at <u><a href="mailto:eesof_bsc@keysight.com">eesof_bsc@keysight.com</a></u> and ask them to rehost the corresponding license.'
            },
            {
                'Q': 'I have software from Ixia that requires an activation code. Can I get the activation code from KSM?',
                'A': 'The portal only supports offline activation for ILU compatible products. You will be able to upload your request file during host assignment.'
            },
            {
                'Q': 'Can I return a transportable license to Keysight and redeem it again?',
                'A': 'Yes, you can return a transportable license using Pathwave or KLM. To get a new license, click <b>Request New Licenses</b> link on Home or Navigation Menu.'
            },
            {
                'Q': 'I closed the request license screen before I emailed or downloaded my licenses. How can I get my licenses?',
                'A': 'Go to <b>EA License View</b>. You can select multiple products and then click the download or email button to get the licenses, or you can click the download button for a particular host to receive a license for that host.'
            },
            {
                'Q': 'I have a variable EA and need to change the end date of my license from the 90-day default. How do I change the end date?',
                'A': 'Complete your product selection and host assignment. After the host is assigned, expand the host and change the end date to the desired date.'
            },
            {
                'Q': 'In addition to my base EA, I have an expansion pool agreement. How does the expansion pool work?',
                'A': 'Use the expansion pool only when your base subscription pool has been consumed and you still need additional licenses. When you request a license from the expansion pool, Keysight evaluates your usage and invoices your company according to the terms of your agreement.'
            },
            {
                'Q': 'I have a variable agreement and have consumed all of my subscription pool. When can I get a new license?',
                'A': 'You can get another license when funds are freed up in your pool. For variable agreements, funds for an expiring license are freed up five days before the license expires.'
            },
            {
                'Q': 'I requested licenses and some or all licenses were not generated within the allotted time. How do I get the missing licenses?',
                'A': 'The Keysight support team will fix the issue and email you your missing licenses. Contact us via email at <u><a href="mailto:eesof_bsc@keysight.com">eesof_bsc@keysight.com</a></u>. You can also download your license from the EA License View when the licenses become available on the KSM server.'
            }
        ],
        [helpEARequestLicenseHostsQAKey]: [
            {
                'Q': 'I have some hosts that require a request file to get a license. How do I get licenses for those type of hosts?',
                'A': 'You need to upload your request file when you assign a product to the host.'
            },
            {
                'Q': 'I have some hosts that require a C2V/V2C file to get a license. How do I get licenses for those type of hosts?',
                'A': 'You will need help from Keysight. After you request the license, a Keysight representative will contact you via email to get your C2V file. Keysight will then generate your license and email a V2C file to you.'
            }
        ],
        [helpEARequestLicenseEAAccessQAKey]: [
            {
                'Q': 'How can my colleagues get access to an EA?',
                'A': 'Forward the “Welcome to your new Keysight Enterprise Agreement” email to your colleagues who need access. They can add the certificate to their existing KSM account or to a new account they create. Because all users will share the same subscription pool, product selections and host assignments, coordination is recommended.'
            }
        ],

        //ea request again help
        [helpEARequestAgainKey]: 'EA Request Again',
        [aboutEAPortalRequestAgainKey]: 'About EA Portal Request Again',
        [quickStepsRequestAgainKey]: 'Quick Steps to ‘Request Again’',
        [helpEARequestAgainAboutSectionKey]: {
            section_one: 'The EA Portal Request Again feature is available only for Variable EAs. It lets you copy the previously issued product(s) selection and host assignment to request the license again.'
        },
        [helpEARequestAgainQuickStepsSectionKey]: {
            section_one: 'Follow the steps below to request licenses again:',
            section_one_steps: [
                {
                    order: 1,
                    type: '1',
                    step: 'On the KSM home page, click <b>Manage Enterprise Agreements</b> in the <b>Enterprise Agreement</b> module.',
                },
                {
                    order: 2,
                    type: '1',
                    step: 'On the <b>Enterprise Licensing</b> page, click on the Hamburger menu of a Variable EA and click <b>‘Request Again’</b>',
                },
                {
                    order: 3,
                    type: '1',
                    step: 'From the <b>EA Request Again License View</b> screen, select the Products by checking the checkbox.',
                },
                {
                    order: 4,
                    type: '1',
                    step: 'When you have made all your selections, click on <b>‘Request Again’</b>.',
                },
                {
                    order: 5,
                    type: '1',
                    step: 'Review the <b>My Assigned Products table</b>.',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Edit the End Date if needed.',
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Change the quantity to assign if needed.',
                        },
                        {
                            order: 3,
                            type: 'a',
                            step: 'You can go back to ‘Select products’ to add more products.',
                        }
                    ]
                },
                {
                    order: 6,
                    type: 'a',
                    step: 'Click <b>License Preview</b> when you have made all desired assignments.',
                },
                {
                    order: 7,
                    type: 'a',
                    step: 'After review, click on <b>Request Licenses</b>',
                },
                {
                    order: 8,
                    type: 'a',
                    step: 'KSM displays notifications when your request is being processed and when it is complete.  You can Email or download the generated licenses now. If you choose to email the licenses, enter one or more addresses.',
                }
            ]
        },
        [helpEARequestAgainNavigationSectionKey]: {
            section_one_steps: [
                {
                    order: 1,
                    type: '1',
                    step: 'On the <b>Enterprise Licensing</b> page, click on the Hamburger menu of a Variable EA and click <b>‘Request Again.’</b>',
                },
                {
                    order: 2,
                    type: '1',
                    step: 'It would take you to the <b>EA Request Again License View screen</b>. At this screen select the Products by checking the checkbox and click on ‘Request Again’ button on the bottom right.',
                },
                {
                    order: 3,
                    type: '1',
                    step: 'It would take you to the ‘Assign Products to Host’ screen. You can navigate the workflow using the stepper.',
                }
            ]
        },
        [helpEARequestAgainSelectProductSectionKey]: {
            section_one_steps: [
                {
                    order: 1,
                    type: '1',
                    step: 'By default, ‘<b>EA Request Again License View</b>’ page displays licenses expiring in the next 5 days.',
                },
                {
                    order: 2,
                    type: '1',
                    step: 'You may edit the License expiry date range to fetch licenses expired or expiring within that window.<br/><b>Note:</b> License expiry End-date is restricted to today + 1 month.',
                },
                {
                    order: 3,
                    type: '1',
                    step: 'KSM displays all products that match the search criteria. Click the checkbox to select the Product(s) you want to request licenses for again. The ‘Request again’ button will not be enabled until you have selected at least one product.',
                },
                {
                    order: 4,
                    type: '1',
                    step: 'If the selected Products Total cost is more than your available subscription pool, you will get an error message and prompted to change the selection.',
                },
                {
                    order: 5,
                    type: '1',
                    step: 'When you have made all your selections, click Request Again.<br/><b>Note:</b> The Products that are obsolete or not part of EA anymore will be greyed out and not available for selection. Though they may appear in your search results based on License Expiry date range entered.',
                }
            ]
        },
        [helpEARequestAgainAssignProductSectionKey]: {
            section_one_steps: [
                {
                    order: 1,
                    type: '1',
                    step: 'After confirming the selection of Products, you will land at the <b>Assign Products to Hosts</b> page:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Review the Product and Host assignment.',
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Edit the quantity to assign if required. Qty is copied over from the previous transaction and can be edited to be equal to or less than the previous redemption.',
                        },
                        {
                            order: 3,
                            type: 'a',
                            step: 'Start Date is set as Today’s date.',
                        },
                        {
                            order: 4,
                            type: 'a',
                            step: 'Edit the End-Date if required. End-date is pre-populated based on the previous duration for which the license was redeemed, considering Today as start date.',
                            substeps: [
                                {
                                    order: 1,
                                    type: 'i',
                                    step: 'Click the calendar next to the end date to change the end date for one product.',
                                },
                                {
                                    order: 2,
                                    type: 'i',
                                    step: 'Click the pencil icon in the header row to change the end date for all products assigned to the host.',
                                },
                            ]
                        },
                    ]
                },
                {
                    order: 2,
                    type: '1',
                    step: 'KSM adds your assignment to the <b>My Assigned Products</b> table. At this point, you can:',
                    substeps: [
                        {
                            order: 1,
                            type: 'bullet',
                            step: 'Go to ‘Select Products’ to add more products',
                        },
                        {
                            order: 2,
                            type: 'bullet',
                            step: 'Click the trash icon in a row to delete that product from the host or in the header row to delete the assignment. <br/>Note: Even though the Host Assignment is deleted the product option remains in your Cart unless you go back to step 1 and delete from ‘<b>My Cart</b>’',
                        },
                    ]
                },
                {
                    order: 3,
                    type: '1',
                    step: 'Click <b>License Preview</b> when you have made all desired assignments.',
                },
            ]
        },
        [helpEARequestAgainRequestLicenseSectionKey]: {
            section_one_steps: [
                {
                    order: 1,
                    type: '1',
                    step: 'On the <b>Request License Summary</b> page, review your assigned licenses and end dates and make sure they are all correct.',
                },
                {
                    order: 2,
                    type: '1',
                    step: 'You can unselect a host if you are not ready to get the licenses for that host.',
                },
                {
                    order: 3,
                    type: '1',
                    step: 'Click <b>Request Licenses</b>, then confirm your request. Upon confirmation, KSM generates your licenses. This may take a few seconds, depending on the number of products and hosts you are requesting.',
                },
                {
                    order: 4,
                    type: '1',
                    step: 'When your licenses are ready, KSM:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Updates the subscription pool information above your cart. The cost is added to the <b>Consumed</b> amount and subtracted from the <b>My Cart</b> amount.',
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Displays notifications when license generation is in progress and when your licenses are ready. This may take a few seconds to complete.',
                        },
                        {
                            order: 3,
                            type: 'a',
                            step: 'Enables the email and download buttons.',
                        },
                    ]
                },
            ]
        },

        //host config help
        [managingHostsKey]: 'Managing Hosts',
        [viewingLicensesKey]: 'Viewing Licenses',
        [editingHostInfoKey]: 'Editing Host Information',
        [removingAHostKey]: 'Removing a Host',
        [helpHostConfigManagingHostsSectionKey]: {
            'section_one': 'The <b>Manage Hosts</b> module lets you view and modify the host metadata for all Enterprise Agreements and Software Access Licenses in your KSM account.',
            'section_two': 'On this page, you can:',
            'section_two_steps': [
                'Quickly locate a particular host or set of hosts. Click the 3-dot icon in any header to access the sort and filter functions.',
                'See the licenses for a particular host, edit the host metadata, or remove the host from your <b>My Hosts</b> list. Click the 3-bar icon next to a host to access these options.',
                'Export all of your host data to an Excel spreadsheet. Click <b>Export Host Information</b> to save a file named <b>KSM_HOSTS_<i>date</i></b> in your downloads folder.'
            ]
        },
        [helpHostConfigViewingLicensesSectionKey]: {
            'section_one': 'The <b>Show Licenses</b> option will show all active licenses assigned to that host. Along with the details of each product.<br/><br/>Hosts that were not saved through an Enterprise Agreement or Software Access are not displayed here. To see all licenses active in your KSM account, click the <b>View by Product or Host</b> link on Home or Navigation Menu.'
        },
        [helpHostConfigEditingHostSectionKey]: {
            'section_one': 'The <b>Edit Host</b> option lets you modify the metadata for a host. The host ID and serial number cannot be modified. Click <b>Save Host ID</b> if you make any changes.'
        },
        [helpHostConfigRemovingHostSectionKey]: {
            'section_one': '<b>Remove Host ID</b> lets you remove a host from your KSM account. Any license files installed on that host will remain valid until they expire. Because this action permanently deletes the host and cannot be undone, you must confirm the removal. If removed, the host ID will not be included in the autosuggest list provided in the <b>Assign Hosts</b> window.'
        },
        [helpHostConfigErrorHandlingSectionKey]: {
            'section_one': ' Occasionally, errors may occur while processing your request for licenses:',
            'section_one_bullets': [
                'If your request does not go through, your work in progress will be retained, allowing you to process the request later.',
                'Click the Contact Us link in the header to contact Keysight at any time for additional support on any issues or to submit feedback.'
            ]
        },
        [helpHostConfigHostsQAKey]: [
            {
                'Q': 'Why it is not possible to add a host here?',
                'A': 'Hosts are added automatically when you assign a host to a product. The Manage Hosts link lets you view and modify the metadata for hosts that were previously used to request licenses. This ensures the hosts entered are validated and in the correct format.'
            },
            {
                'Q': 'I incorrectly entered the wrong host ID when I assigned hosts. Can I correct the host ID here?',
                'A': 'No. The host ID and serial number cannot be modified. If you have requested a license for the wrong host ID, you need to contact Keysight to invalidate the license. Once you request the license again, the correct host will be automatically added. The incorrect host can be removed anytime by using the Remove Host option.'
            },
            {
                'Q': 'I accidentally removed a host ID that is still in use. How do I fix this?',
                'A': 'The host will be automatically added the next time you request a license for that host.'
            },
            {
                'Q': 'I redeemed a licensing using the Request New Licenses module and don’t see my host on the Host Management module. Why?',
                'A': 'Only hosts saved from the Enterprise Agreement or Software Access modules will be showing on the Host Management module. In the future this functionality may be extended to the Request New Licenses module.'
            }
        ],
        
        //trial help
        [trailLicenseKey]: 'Trial License',
        [aboutKeysightTrailLicensesKey]: 'About Keysight Trial Licenses',
        [licenseProductInfoKey]: 'License Product Information',
        [hostIdHostInfoKey]: 'Host Identifier/Host Information',
        [helpTrialLicenseAboutSectionKey]: 'This web application allows you to obtain a trial license from Keysight.<br/><br/>The process requires you to enter your contact information and host information. Your information will be handled in a manner appropriate to Keysight\'s privacy policy and in some instances will require a Keysight representative to contact you for follow up regarding the request.<br/><br/>Note: DO NOT use browser forward and backward to navigate as it can cause unpredictable results.<br/><br/>Please remember that your license will be delivered via email.  Therefore, it is imperative that you type and confirm your email correctly.  Upon submission, you will see a confirmation message informing you about the status of your request.<br/><br/>After a successful submission, the trial license request is processed, and your license will be emailed to you.  In some cases, it will take a few minutes before you receive your license via email.<br/><br/>If your trial request is denied, you can still request a trial by submitting a trial request exception. You will be contacted by an Keysight representative if further information is required.',
        [helpTrialLicenseProductInfoSectionKey]: 'Product number for the desired Trial License.  For a different product trial, go to the appropriate Keysight.com product web page to begin the trial license request.',
        [helpTrialLicenseHostIDHostInfoSectionKey]: 'Host Identifier refers to a licensing identifier, which varies for different Keysight products.',
        
        [viewByKey]: "View By",
        [productsKey]: "Products",
        [qtyAssignedKey]: "Qty Assigned",
        [qtyUnassignedKey]: "Qty Unassigned",
        [assignMoreHostsKey]: "Assign More Hosts",

        [requestShipmentUpdateKitKey]: 'Request Shipment of Update Kit',
        [requestShipmentKey]: 'Request Shipment',
        [requestQuantityKey]: 'Request Quantity',
        [shippingAddressKey]: 'Shipping Address',
        [nameKey]: 'Name',
        [companyKey]: 'Company',
        [departmentKey]: 'Department',
        [zipCodeKey]: 'Zip Code',
        [showOnlyUpdatesKey]: 'Show Only Updates That Contain',
        [orderNumberPurchaseOrderModelEtcKey]: 'Order Number, Purchase Order, Model',
        [resetKey]: 'Reset',
        [releaseDateKey]: 'Release Date',
        [fileSizeKey]: 'File Size',
        [platformKey]: 'Platform',
        [updateKey]: 'Update',
        [downloadSelectedKey]: 'Download Selected',
        [softwareFamilyKey]: 'Software Family',
        [licenseKey]: 'License',
        [quantityToAssignKey]: 'Quantity to Assign',
        [backToSoftwareUpdateKey]: 'Back to Software Update',
        [getAnotherLicenseKey]: 'Get Another License',
        [requestUpdateLicensesKey]: 'Request Update Licenses',
        [shipmentRequestReceivedKey]: 'Shipment request received!',
        [shipmentSuccessSubmittedKey]: 'Your request for a shipment was successfully submitted.',
        [yourOrderNumberIsKey]: 'Your order number is: ',
        [SoftwareUpdatesAreAvailablePleaseEnterAQtyBetween1AndKey]: ' are available. Please enter a quantity between 1 and ',
        [browserLimitConcurrentDownloadKey]: 'Your browser may limit the number of concurrent downloads.',
        [limitDownloadThenRequestKey]: 'If you do not get all requested files, please limit downloads to 6 concurrent files, then request additional downloads as each download finishes.',
        [limitsMultipleConcurrentDownloadsKey]: 'Limits on multiple concurrent downloads',
        
        //software updates help
        [ksmSwUpdatesKey]: 'KSM SW Updates',
        [softwareUpdatesOverviewKey]: 'Software Updates Overview',
        [swUpdatesOverview]: 'SW Updates Overview',
        [searchingSpecificUpdateKey]: 'Searching For a Specific Update',
        [gettingUpdateFilesKey]: 'Getting Update Files',
        [swUpdatesKey]: 'SW Updates',
        [helpSoftwareUpdateFaqQAKey]: [{
            Q: 'How will I know when a new update is available?',
            A: 'Keysight sends you email when an update is released for one of your products.'
        },
            {
                Q: 'How does Keysight determine which updates are available to me?',
                A: 'You have access to all updates for products that are covered by a support subscription. <br><br>   If your support subscription has expired, you can still get any updates that were released while your subscription was current.<br><br> If an update was released after your support subscription expired, it is not listed in your available updates. You must renew your support subscription before you can get that update. (In some cases, if an update was released shortly after your support subscription expired, Keysight may allow you to get the update as a courtesy.)'
            },
            {
                Q: 'Why don\'t I see the latest software update available?',
                A: 'If an update doesn’t appear in your list, check to see if your support subscription has expired. Software updates that were released after your support subscription expired are not in your list of available updates.'
            }
        ],
        [helpSoftwareUpdateErrorHandlingContentKey]: {
            section_one: 'Occasionally errors may occur while processing a request for licenses.',
            section_one_bullets: [
                'If your request does not go through, your entitlements will remain available to be redeemed.',
                'If your request is processed but any license files are not generated in the expected time, our Support Team will retrieve your licenses and email them to you. '
            ],
            section_two: 'You can click the <b>Contact</b> us link within the header to contact Keysight at any time for additional support on any issues or to submit feedback.'
        },
        [helpSoftwareUpdateOverviewContentKey]: {
            section_one: '<b>Software Updates</b> allows you to download software updates, get updated documentation and licenses, and request physical kits. This option is enabled when:',
            section_one_bullets: [
                'An update is available for any product associated to your account, and',
                'Your support is or was current when the update was released.'
            ],
            section_two: 'All updates are organized under software families. On this page, you can:',
            section_two_bullets: [
                ' <a class="navgation-link" href="#search"> Search</a> for a specific update in reference to an order number, purchase order, or product model.',
                'Use the arrows to display or hide all updates under a software family.',
                'Click the three-dot icon button to sort and filter the results. This option applies to families only at the top level and to updates within a given family in the nested table.',
                'Use the arrow keys at the bottom to navigate through pages of updates. You also can specify how many items to show on the page.',
                'Click on any update in the list to <a class="navgation-link" href="#update">  download update files.</a>'
            ]
        },
        [helpSoftwareUpdateSearchContentKey]: {
            section_one: 'The <b>Show Only Updates That Contain</b> field allows to search for updates that are related to an order number, purchase order or product model. Enter your search string and click <b>Search</b>. KSM display any updates where the order number, purchase order, or model contains the string.'
        },
        [helpSoftwareUpdateGettingFilesContentKey]: {
            section_one: 'When you click on an update, KSM displays all available documentation, downloadable files, external links/kits, and licenses for that update.',
            section_two: 'Depending on the update content, KSM can display up to four tables.',
            section_two_bullets: [
                {
                    bullet_point: '<b>Documentation</b> lets you download documents related to that update.',
                    sub_bullter_points: [
                        'Click the document icon or description to open the document on a new browser tab (a).'
                    ]
                },
                {
                    bullet_point: '<b>Software/Firmware</b> shows all software bits related to the update.',
                    sub_bullter_points: [
                        'Click the description to initiate the download.',
                        'You also can check the downloads you want (b), then click <b>Download Selected</b> (c) to download multiple files together.'
                    ]
                },
                {
                    bullet_point: '<b>External Links and Kits</b> lets you access external links and physical shipment kits.',
                    sub_bullter_points: [
                        'Click the envelope icon or description (d). ',
                        'For physical kits, a form allows you to provide shipping information. Enter your information, then click <b>Request Shipment</b>.'
                    ]
                },
                {
                    bullet_point: '<b>Licenses</b> provides access to updated licenses.',
                    sub_bullter_points: [
                        'Click the redeem icon or description to go to the process for obtaining licenses (e).'
                    ]
                }
            ]
        },
        
        [selectLicenseKey]: 'Select License',
        [myUpdatedLicensedProductsKey]: 'My Updated Licensed Products',
        [qtyToAssignKey]: 'Qty to Assign',
        [updateCannotBeRedeemedKey]: 'Update Cannot be Redeemed',
        [firstRedeemThenUpdateKey]: 'In order to redeem this update, first redeem the license for product(s):',
        [backToSoftwareUpdatesKey]: 'Back To Software Updates',
        
        [certificateKey]: 'Certificate',
        [licKey]: 'Lic.',
        [downloadLicenseKey]: 'Download License',
        [availableLicenseBrowserKey]: 'Your license is available in License Browser, which opens automatically when you launch Nemo Handy/Nemo  Walker Air application on your handset.',
        [requestAllAvailableLicensesKey]: 'Request all available licenses',
        [requestAvailableLicensesOrderKey]: 'Request available licenses within this order',
        [requestAvailableLicensesProductKey]: 'Request available licenses within this product',
        [numberOfProductsKey]: 'Number of Products',
        
        [enterpriseAgreementKey]: 'Enterprise Agreement',
        [remainingSubPoolKey]: 'Remaining Subscription Pool',
        [remixOpensOnKey]: 'Remix Opens On',
        [noActiveAgreementsKey]: 'There are no active agreements',
        [workOnCurrentPeriodKey]: 'Work on Current Period',
        [workOnNextPeriodKey]: 'Work on Next Period',
        [remixNextPeriodKey]: 'Remix Next Period',
        [remixCurrentPeriodKey]: 'Remix Current Period',
        [copyProductsAndHostsKey]: 'Copy Products and Hosts',
        [copyProductsOnlyKey]: 'Copy Products Only',
        [startFromScratchKey]: 'Start from Scratch',
        [requestAgainKey]: 'Request Again',
        [requestAgainTooltipKey]: 'Repeat prior license request',
        [getLicensesKey]: 'Get Licenses',
        [ELStartFromScratchWarning1Key]: 'You are about to start your product selection from scratch.',
        [ELStartFromScratchWarning2Key]: 'Once you select your first product, copying products/hosts from previous period will no longer be possible.',
        [ELStartFromScratchWarning3Key]: 'Please confirm that you would like to start from scratch.',
        [ELCopyProductsOnlyWarning1Key]: 'You are about to copy your product selections from the previous period to the new period. Please note that:',
        [ELCopyProductsOnlyWarning2Key]: 'Host assignments will not be copied.',
        [ELCopyProductsAndHostsWarningKey]: 'You are about to copy your product selections and host assignments from the previous period to the new period. Please note that:',
        [ELRequestAgainCopyProductsAndHostsWarningKey]: 'You are requesting to copy the previously issued product selection and host assignments listed below.  Please note that:',
        [ELCopyProductsWarning1Key]: 'This action can only be done once.',
        [ELCopyProductsWarning2Key]: 'You will be able to modify products and/or hosts before requesting your licenses.',
        [ELCopyProductsWarning3Key]: 'Click Confirm to proceed. Upon confirmation, do not close your window as the process may take a few seconds to complete.',
        [confirmKey]: 'Confirm',
        [ELCopyAlertTitleKey]: 'Copy process exceeded available subscription pool',
        [ELCopyProductsOnlyAlertMessageKey]: 'Your available subscription pool is not large enough to copy all products ' +
        'from the previous period. As a result, some products have not been copied. You can modify your product selections before requesting your licenses.',
        [ELCopyProductsAndHostsAlertMessageKey]: 'Your available subscription pool is not large enough to copy all products ' +
        'from the previous period. As a result, some products have not been copied. You can modify your product selections and host assignments before requesting your licenses.',
        
        [transportLicenseKey]: 'Transport License',
        [emailLicenseKey]: 'Email License',
        [uploadKeysightURLFileKey]: 'Upload a Keysight transport URL file. (Recommended)',
        [whereToFileURLFileKey]: 'Where do I find the .url file?',
        [currentHostIdKey]: 'Current Host ID',
        [sequenceNumberKey]: 'Sequence Number',
        [enterSelectLicensesKey]: 'Enter/Select Licenses',
        [enterLicensesKey]: 'Enter Licenses',
        [reviewAndSubmitKey]: 'Review and Submit',
        [TransportSelectDirections]: 'Select one or more licenses to transport or save',
        [TransportSearchDirections]: 'Add each of your licenses ids and confirmation codes, after that select one or more to transport or save to KSM',
        [TransportAssignDirections]: 'Select products to assign to host, assign them to a host and click Continue when ready to get your licenses',
        [licenseIdKey]: 'License ID',
        [confirmationCodeKey]: 'Confirmation Code',
        [addLicenseKey]: 'Add License',
        [saveToKeysightSoftwareManagerKey]: 'Save to KSM',
        [licenseBelowNotValidKey]: 'The licenses below are not valid',
        [licenseIdsKey]: 'License IDs',
        [transportNoMoreActionsKey]: 'All licenses included on the URL or entered have already been transported or returned, no more actions are available.',
        [licensesNotLinkedKSMProfileKey]: 'The licenses below are not linked to a KSM profile',
        [transportLicenseLogInTOKSMKey]: 'You can transport those licenses to another host now or, for saving to KSM, please login to Keysight Software Manager and add the corresponding entitlement certificate to your profile.',
        [TransportInvalidParamsErrorTitle]: 'The number of license ids and confirmation codes in URL is incorrect',
        [TransportInvalidParamsErrorMessage]: 'There are one or more missing parameters on the URL. Please check the URL and correct it. Each license id requires a confirmation code. KSM will allow you to work on the licenses correctly loaded from the URL.',
        [infoProvidedInvalidKey]: 'The information provided is invalid',
        [checkTryAgainKey]: 'Please check and try again.',
        [oneMoreLicensesNotAvailableKey]: 'One or more licenses are no longer available',
        [alreadyTransportedKey]: 'The following licenses have been already transported or saved to KSM and are no longer available',
        [ProductsAvailableToAssignKey]: 'Products Available to Assign',
        [returnLicenseKey]: 'Return License',
        [transportRemainingLicensesKey]: 'Transport More',
        [licensesAreSuccessfullySavedKey]: 'Licenses are successfully saved',
        [TransportSuccessBankedMessage]: 'Your licenses were saved to Keysight Software Manager. To assign your licenses to a different instrument or PC, please visit KSM using the link below.',
        [TransportURLFile1]: 'On the PC or instrument from which you are transporting the license\n' +
        '                                            (and on which\n' +
        '                                            the license has been deleted and will no longer be used), the\n' +
        '                                            transport URL file is\n' +
        '                                            stored in',
        [TransportURLFile2]: 'If you used Keysight License Manager (KLM) to create a transport URL\n' +
        '                                            file,\n' +
        '                                            you can find its URL files by choosing the Explore Transport\n' +
        '                                            Confirmation\n' +
        '                                            URLs item in keysight License Manager\'s Tools menu.',
        [TransportURLFile3]: 'If you received a transport URL file via e-mail from a Keysight\n' +
        '                                            representative,\n' +
        '                                            save it to a convenient location and browse to that location to\n' +
        '                                            upload it.',
        [TransportURLFile4]: 'Transport URL files have the extension \".url\"',
        
        [viewAndDownloadActiveLicensesKey]: 'View and Download Active Licenses',
        [myLicensesKey]: 'My Licenses',
        [assetKey]: 'Asset',
        [transactionKey]: 'Transaction',
        [checkoutDateKey]: 'Checkout Date',
        [noLicenseAvailableViewDownloadKey]: 'No licenses are available to view and download.',
        
        [backKey]: 'Back',
        [removeKey]: 'Remove',
        [addToCartKey]: 'Add to Cart',
        [qtyAvailableKey]: 'Qty Available',
        [enterProductNumberDescriptionKey]: 'Enter product number or description',
        [clientInfoKey]: 'Client Information',
        [detailedInfoSwaKey]: 'Detailed information about the software access license client',
        [reviewCompleteKey]: 'Review and Complete',
        [clientInfoOptionalKey]: 'Client Information (optional)',
        [companyInfoColonKey]: 'Company Info: ',
        [companyAssetInfoColonKey]: 'Company Asset Information: ',
        [poNumberColonKey]: 'PO Number: ',
        [clientNotesColonKey]: 'Client Notes: ',
        [companyInfoKey]: 'Company Info',
        [companyAssetInfoKey]: 'Company Asset Information',
        [clientNotesKey]: 'Client Notes',
        [deleteProductHostStep2Key]: 'To Delete Product(s) or Host(s) go to Step 2',
        [detailedInfoSwaClientKey]: 'Detailed information about software access Product client',
        [noKey]: 'No',
        [yesKey]: 'Yes',    
        
        [noLicensesCheckInKey]: 'No licenses are available to check in.',
        [selectHostBeginCheckInKey]: 'Select one or more hosts to begin the check in process',
        [selectHostsParenthesisKey]: 'Select Host(s)',
        [selectLicensesParenthesisKey]: 'Select License(s)',
        [checkInKey]: 'Check In',
        [customerKey]: 'Customer',
        [selectHostCheckInKey]: 'Select one or more Licenses from each Host to Check In',
        [expirationDateKey]: 'Expiration Date',
        [reviewCheckInLicensesKey]: 'Review and Check In License(s)',
        [sendConfirmationEmailKey]: 'Send Confirmation Email',
        [emailConfirmationKey]: 'Email Confirmation',
        [sendConfirmationEmailToSeparatedKey]: "Send confirmation email to (separated by commas):",
        [checkInSuccessKey]: 'You have successfully checked in licenses',
        [successExclamationKey]: 'Success!',
        [attentionExclamationKey]: 'Attention!',
        [checkInMoreLicensesKey]: 'Check In More Licenses',
        [confirmationEmailSentKey]: 'Confirmation email sent',
        [undoneCheckInKey]: 'This task cannot be undone once a license is checked in.',
        [sendEmailKey]: 'Send Email',
        
        [generateReportKey]: 'Generate Report',
        [selectReportTimeFrameKey]: 'Select a report timeframe and generate a report.',
        [createReportDataFormKey]: 'Create a report with data from',
        [allKey]: 'All',
        [checkedInKey]: 'Checked In',
        [hostAssetInfoKey]: 'Host Asset Information',
        [clientAssetInfoKey]: 'Client Asset Information',
        [checkInDateKey]: 'Check In Date',
        [daysOutKey]: 'Days Out',
        [exportKey]: 'Export',
        
        //software access help
        [aboutSoftwareAccessKey]: 'About Software Access',
        [helpSAAboutSectionKey]: {
            'section_one': 'KSM Software Access home page provides you links for the following options:',
            'section_one_bullets': [
                'Check Out Licenses',
                'Check In Licenses',
                'View and Download Licenses',
                'Software Usage Report',
                'Manage Host(s)'
            ]
        },
        
        [helpSANavigationSectionKey]: {
            'section_one_steps': [
                {
                    order: 1,
                    type: 'bullet',
                    step_prefix: 'You can go to KSM Home page by clicking on the leftmost ‘Home’ icon ',
                    step_suffix: ' at the top navigation menu.',
                    img: 'ksm-sa-home-button',
                },
                {
                    order: 2,
                    type: 'bullet',
                    step_prefix: 'You can go to any other KSM Modules by clicking on ',
                    step_suffix: ' at the top navigation menu.',
                    img: 'ksm-sa-arrow-button',
                }
            ]
        },
        
        [stepsToViewDownloadLicensesKey]: 'Steps to View and Download Licenses',
        [helpSADownloadStepsSectionKey]: {
            'section_one': 'The following process allows you to view and download your licenses.',
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'On the KSM home page, click on <b>‘View and Download Licenses’</b> in the Software Access Licenses module.'
                },
                {
                    order: 2,
                    type: '1',
                    step: 'View and Download Active Licenses page shows list of all Active licenses available to you for download.'
                },
                {
                    order: 3,
                    type: '1',
                    step: 'You can apply filters on columns Product Number, Description, Host ID, Alias, Asset, Transaction or Checkout Date.'
                },
                {
                    order: 4,
                    type: '1',
                    step_prefix: 'Download the desired License by clicking on the “Download” Icon ',
                    step_suffix: ' at the right end of the row.',
                    img: 'ksm-sa-download-button',
                },
                {
                    order: 5,
                    type: '1',
                    step: 'The License file and the installation instructions are downloaded.',
                }
            ]
        },
        
        [stepsToViewSoftwareUsageReportKey]: 'Steps to view Software Usage Report',
        [helpSAReportStepsSectionKey]: {
            'section_one': 'This report provides detail of licenses which are out for rental during the selected time-period.',
            'section_one_steps': [
                {
                    order: 1,
                    type: 'bullet',
                    step: 'To display the report, select Start and End dates, then click the <b>Generate Report</b> button.',
                },
                {
                    order: 2,
                    type: 'bullet',
                    step: 'A single row is displayed in the report for each license which is active in the selected time-period.',
                },
                {
                    order: 3,
                    type: 'bullet',
                    step: 'You can select one of the Tabs – <b>All, Checked Out or Checked In</b> to view only corresponding records.',
                }
            ]
        },
        [columnsDisplayedReportKey]: 'Columns Displayed in the Report',
        [helpSAReportColumnsSectionKey]: {
            'section_one': 'The columns displayed in the report are as follows:',
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: '<b>Transaction</b><br />Primary reference number for a license check-out transaction. Each check-out will have a Transaction number per Host.<br />' +
                        'This number appears in the subject line for the license delivery email.<br />You will also see this number in the monthly consumption billing report.',
                },
                {
                    order: 2,
                    type: '1',
                    step: '<b>Product Number</b><br />Keysight product number for the license option.',
                },
                {
                    order: 3,
                    type: '1',
                    step: '<b>Description</b><br />Description for the license option.',
                },
                {
                    order: 4,
                    type: '1',
                    step: '<b>Host ID</b><br />Host ID to which the Product is assigned.',
                },
                {
                    order: 5,
                    type: '1',
                    step: '<b>Alias</b><br />Host Alias entered by user, entered on the <b>Host Assignment</b> screen at the time the Product was assigned to Host.',
                },
                {
                    order: 6,
                    type: '1',
                    step: '<b>Host Asset Information</b><br />Entered on the <b>Host Assignment</b> screen at the time the Product was assigned to Host.',
                },
                {
                    order: 7,
                    type: '1',
                    step: '<b>Client Asset Information</b><br />Entered on the <b>Client Information</b> screen at the time the license was checked out.',
                },
                {
                    order: 8,
                    type: '1',
                    step: '<b>Check-out Date</b><br />Date the license was checked out.',
                },
                {
                    order: 9,
                    type: '1',
                    step: '<b>Check-in Date</b><br />Date the license was checked in. Blank if the license hasn’t been checked in yet.',
                },
                {
                    order: 10,
                    type: '1',
                    step: '<b>Expiration Date</b><br />Expiration date for the licenses. All licenses checked out from the Software Access Portal expire 18 months after check-out.<br />' +
                        'If a license expires without being checked in, the system automatically checks it in.',
                },
                {
                    order: 11,
                    type: '1',
                    step: '<b>Status</b><br />Shows whether the license is currently checked out or checked in.',
                },
                {
                    order: 12,
                    type: '1',
                    step: '<b>Days Out</b><br />Number of days the license was checked out during the reporting period.',
                },
                {
                    order: 13,
                    type: '1',
                    step: '<b>Customer, PO Number, Client Notes</b><br />Entered on the <b>Client Information</b> screen at the time the license was checked out.',
                }
            ]
        },
        
                [quickStepsToCheckoutKey]: 'Quick Steps to Check Out Licenses',
        [helpSACheckoutStepsSectionKey]: {
            'section_one': 'The following process allows you to Check out your licenses. The topics in this help page explain these steps in more detail.',
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'On the KSM home page, click <b>Check Out Licenses</b> in the <b>Software Access Licenses</b> module.'
                },
                {
                    order: 2,
                    type: '1',
                    step: 'On the <b>Check Out Licenses</b> page, enter a full or partial product number or description in the search field and click <b>Search</b>.',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'If you do not enter any product number or description, <b>Search</b> will return all available Products.',
                        },
                    ]
                },
                {
                    order: 3,
                    type: '1',
                    step: 'From the search result, add Products to your cart by clicking the cart icon.'
                },
                {
                    order: 4,
                    type: '1',
                    step: 'Continue to search and select products as needed. When you have made all your selections, click <b>Continue</b>.',
                },
                {
                    order: 5,
                    type: '1',
                    step: 'On the <b>Assign Products to Hosts</b> page:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Select all the products you want to assign to one host.',
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Change the quantity to assign if necessary.',
                        },
                    ]
                },
                {
                    order: 6,
                    type: '1',
                    step: 'KSM adds your assignment to the <b>My Assigned Products</b> table. At this point, you can:',
                    substeps: [
                        {
                            order: 1,
                            type: 'bullet',
                            step: 'Repeat steps 4 and 5 to assign products to additional hosts or come back and assign them later (selected products remain in your cart). ',
                        },
                    ]
                },
                {
                    order: 7,
                    type: '1',
                    step: 'Click <b>Continue</b> when you have made all desired assignments.',
                },
                {
                    order: 8,
                    type: '1',
                    step: 'On the <b>Client Information</b> page, enter the optional information if desired, then click <b>Continue</b>.',
                },
                {
                    order: 9,
                    type: '1',
                    step: 'On the Review and Complete page, review the company information (if any) and your product/host assignments. Click <b>Back</b> if you need to make changes. When the information is correct, click <b>Request License</b> and confirm the request.',
                },
                {
                    order: 10,
                    type: '1',
                    step: 'KSM displays notifications when your request is being processed and when it is complete.  You can either:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Assign more products and get all licenses when you are finished. Or,',
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Email or download the generated licenses now. If you choose to email the licenses, enter one or more addresses.',
                        },
                    ]
                },
            ]
        },
        [helpSACheckoutNavigationSectionKey]: {
            'section_one': 'You can use the options below to navigate through each screen:',
            'section_one_steps': [
                {
                    order: 1,
                    type: 'bullet',
                    step: 'Click the step number to move to a specific step. Navigation is disabled if more data is needed to go to that step.',
                    img: 'ksm-sa-checkout-stepper',
                },
                {
                    order: 2,
                    type: 'bullet',
                    step: 'You can navigate to other pages using the top navigation menu.',
                },
            ]
        },
        [helpSACheckoutSelectingProductsSectionKey]: {
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'On the <b>Check Out Licenses</b> page, enter a full or partial product number or description in the search field and click <b>Search</b>.<br /><b>Note:</b> If you don’t enter search criteria, KSM will display all available licenses. The list will be long and may take some time to load.',
                    img: 'ksm-sa-checkout-search',
                },
                {
                    order: 2,
                    type: '1',
                    step_prefix: 'KSM displays all products that match the search criteria. Click the cart icon ',
                    step_suffix: 'for each product you want.',
                    img: 'ksm-sa-checkout-cart-button',
                    img2: 'ksm-sa-checkout-cart'
                },
                {
                    order: 3,
                    type: '1',
                    step_prefix: 'KSM adds the products to the <b>My Cart</b> table. If you do not want a product in the table, click the trash icon ',
                    step_suffix: ' to delete it.',
                    img: 'ksm-sa-checkout-delete-button',
                    img2: 'ksm-sa-checkout-delete'
                },
                {
                    order: 4,
                    type: '1',
                    step: 'Continue to search and select products as needed. When you have made all your selections, click <b>Continue</b>.',
                },
            ]
        },
        [helpSACheckoutAssigningProductsSectionKey]: {
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'On the <b>Assign Products to Hosts</b> page:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Select all the products you want to assign to one host. Once a product is selected, any non-compatible host id type options will be greyed out.',
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Click <b>Assign Products to Hosts</b>',
                            img: 'ksm-sa-checkout-selected'
                        },
                    ]
                },
                {
                    order: 2,
                    type: '1',
                    step: 'In the <b>Assign Products to Host</b> dialog:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Enter the host ID. ',
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'You can hover over the <b>License Notifier Host ID</b> field to see the format required.',
                        },
                        {
                            order: 3,
                            type: 'a',
                            step: 'Enter metadata if desired.',
                        },
                        {
                            order: 4,
                            type: 'a',
                            step: 'Click <b>Save Host</b>.',
                            img: 'ksm-sa-checkout-assign-host-modal'
                        },
                    ]
                },
                {
                    order: 3,
                    type: '1',
                    step: 'KSM adds your assignment to the <b>My Assigned Products</b> table. At this point, you can:',
                    substeps: [
                        {
                            order: 1,
                            type: 'a',
                            step: 'Repeat steps 1 and 2 to assign products to additional hosts or come back and assign them later (selected products remain in your cart).',
                        },
                        {
                            order: 2,
                            type: 'a',
                            step: 'Click the trash icon in a row to delete that product from the host or in the header row to delete the assignment.',
                            img: 'ksm-sa-checkout-assigned-products'
                        },
                    ]
                },
                {
                    order: 4,
                    type: '1',
                    step: 'Click <b>Continue</b> when you have made all desired assignments.',
                },
            ]
        },
        [helpSACheckoutClientInformationSectionKey]: {
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'On the <b>Client Information</b> page, enter the following optional information if desired, then click <b>Continue</b>.',
                    substeps: [
                        {
                            order: 1,
                            type: 'bullet',
                            step: '<b>Company Info</b>',
                        },
                        {
                            order: 2,
                            type: 'bullet',
                            step: '<b>Company Asset Information</b>',
                        },
                        {
                            order: 3,
                            type: 'bullet',
                            step: '<b>PO Number</b>',
                        },
                        {
                            order: 4,
                            type: 'bullet',
                            step: '<b>Client Notes</b>',
                        },
                    ]
                },
            ]
        },
        [helpSACheckoutReviewSectionKey]: {
            'section_one': 'On the Review and Complete page, review the company information (if any) and your product/host assignments. Click <b>Back</b> if you need to make changes. When the information is correct, click <b>Request License</b> and confirm the request.',
            'section_two': 'KSM displays notifications when your request is being processed and when it is complete.  You can either:',
            'section_two_steps': [
                {
                    order: 1,
                    type: 'bullet',
                    step: 'Assign more products and get all licenses when you are finished.',
                },
                {
                    order: 1,
                    type: 'bullet',
                    step: 'Email or download the generated licenses now. If you choose to email the licenses, enter one or more addresses.',
                },
            ]
        },
        [helpSACheckoutQAKey]: [
            {
                'Q': 'How do I install my new license files? ',
                'A': 'Refer to the installation instructions that are included with the emailed or downloaded license files. If you still need help, please use the “Contact us” link in the header to request assistance. '
            },
            {
                'Q': 'I redeemed a license on the wrong host. How do I correct it?',
                'A': 'Please check the license back in and check out again.'
            },
            {
                'Q': 'I would like to see KSM in a different language. How do I change the language?',
                'A': 'KSM supports a few additional languages. Use the globe link on the header to change to the desired country. If that country’s language is not available, the text will display in English.'
            }
        ],

        [quickStepsCheckInKey]: 'Quick Steps to Check in Licenses',
        [helpSACheckinStepsSectionKey]: {
            'section_one': 'The following process allows you to Check In your licenses. The topics in this help page explain these steps in more detail.',
            'section_one_steps': [
                {
                    order: 1,
                    type: '1',
                    step: 'On the KSM home page, click <b>Check In Licenses</b> in the <b>Software Access Licenses</b> module.',
                },
                {
                    order: 2,
                    type: '1',
                    step: '<b>Check In Licenses</b> page shows a list of all Hosts that have licenses in Checked out status.',
                },
                {
                    order: 3,
                    type: '1',
                    step: 'You can apply a filter on columns Host ID, Alias, Company Asset Information, Transaction, PO Number, Checkout Date, Customer or Client Notes.',
                },
                {
                    order: 4,
                    type: '1',
                    step: 'Select Host(s) using the leftmost checkbox column. Click the Continue button to advance to the next screen.',
                },
                {
                    order: 5,
                    type: '1',
                    step: 'From the list of licenses currently checked out for this host(s), select all licenses you wish to check in. ' +
                        'If you want to select any other Host from the previous page, click on the <b>Back</b> Button. Else click Continue.',
                },
                {
                    order: 6,
                    type: '1',
                    step: 'Review the list of licenses to check-in. Use the Back button to go back to the previous screen and make changes if needed. To proceed click on the “Check In” button and confirm your selection.',
                },
                {
                    order: 7,
                    type: '1',
                    step: 'If you wish to receive a confirmation email, click the "Send Confirmation Email" button, and edit the email address as needed.',
                },
            ]
        },
        [helpSACheckinSelectHostsSectionKey]: {
            'section_one': 'When you click on the <b>Check In Licenses</b> module, KSM displays all Hosts that have licenses checked out from your KSM account.',
            'section_one_steps': [
                {
                    order: 1,
                    type: 'a',
                    step: 'Select the checkbox to the left of the host for which you wish to check in licenses.',
                },
                {
                    order: 2,
                    type: 'a',
                    step: 'Then click on “Continue”.',
                    img: 'ksm-sa-checkin-my-hosts'
                },
            ]
        },
        [helpSACheckinSelectLicensesSectionKey]: {
            'section_one': 'Review the list of licenses currently checked out for this host. Use the <b>Back</b> button to go back to the previous screen and select another Host if needed.',
            'section_one_steps': [
                {
                    order: 1,
                    type: 'a',
                    step: 'Using the Checkbox to the left of Product Number, select all licenses you wish to check in.',
                },
                {
                    order: 2,
                    type: 'a',
                    step: 'Click the <b>Continue</b> button to move to the next screen.',
                    img: 'ksm-sa-checkin-select-licenses'
                },
            ]
        },
        [helpSACheckinCheckinSectionKey]: {
            'section_one': 'Review the list of licenses to check-in. Use the Back button to go back to the previous screen and make changes if needed.',
            'section_one_steps': [
                {
                    order: 1,
                    type: 'a',
                    step: 'Click on the "Check In" button to check in the licenses, confirm on Prompt.',
                    img: 'ksm-sa-checkin',
                    img2: 'ksm-sa-checkin-are-you-sure'
                },
                {
                    order: 2,
                    type: 'a',
                    step: 'If you wish to receive a confirmation email, click the "Send Confirmation Email" button and edit the email address as needed.',
                    img: 'ksm-sa-checkin-success'
                },
            ]
        },
        [helpSACheckinNavigationSectionKey]: {
            'section_one_steps': [
                {
                    order: 1,
                    type: 'bullet',
                    step: 'Click the step number to move to a specific step. Navigation is disabled if more data is needed to go to that step.',
                    img: 'ksm-sa-checkin-stepper',
                },
                {
                    order: 2,
                    type: 'a',
                    step_prefix: 'You can navigate to other KSM pages using the top navigation menu. Click on the Arrow ',
                    step_suffix: ' to see all sub menu options.',
                    img: 'ksm-sa-arrow-button'
                },
            ]
        },
        [helpSACheckinQAKey]: [
            {
                'Q': 'I would like to see KSM in a different language. How do I change the language?',
                'A': 'KSM supports a few additional languages. Use the globe link on the header to change to the desired country. If that country’s language is not available, the text will display in English.'
            },
        ]
    },
}