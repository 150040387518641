import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import {getCookie, getLocaleInfo} from './utilities.js'
import config from './config.js';
import UserContext from './components/common/UserContext.js';
import {linkMappings, tileHeader} from "./LinkMappings";

// styles and fonts
import FontFaceObserver from 'fontfaceobserver';
import './assets/bootstrap/css/bootstrap-grid.min.css';
import './assets/kendo-custom-theme/kendo-custom-theme.css';
import './assets/fonts/fonts.css';
import './assets/css/App.css';

// react router
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';

// kendo react
import {loadMessages, LocalizationProvider} from '@progress/kendo-react-intl';
import {mainMessages} from "./assets/text/MultilingualText";

// pages
import SignIn from './views/SignIn.js';
import Welcome from './views/Welcome.js';
import ViewCertificate from './views/ViewCertificate.js';
import AddCertificate from './views/AddCertificate.js';
import RequestLicense from './views/RequestLicense.js';
import DeactivateLicense from './views/DeactivateLicense.js';
import RenewNemo from './views/RenewNemo.js';
import RenewStandalone from './views/RenewStandalone.js';
import RenewEesof from './views/RenewEesof.js';
import SyncLicense from './views/SyncLicense.js';
import ProductView from './views/ProductView.js';
import ExpiringLicenseSupportView from './views/ExpiringLicenseSupportView.js';
import RequestUpdateLicenses from './views/RequestUpdateLicenses.js';
import SoftwareUpdate from './views/SoftwareUpdate.js';
import SoftwareUpdateDetail from './views/SoftwareUpdateDetail.js';
import EADetailedView from './views/EADetailedView.js';
import EASummary from './views/EASummary.js';
import EARequestLicense from './views/EARequestLicense.js';
import EARequestAgain from './views/EARequestAgain.js';
import HostConfig from './views/HostConfig.js';
import TrialLicense from './views/TrialLicense.js';
import TransportKAL from "./views/TransportKAL";
import TransportCCL from "./views/TransportCCL";
import SADownload from "./views/SADownload";
import SACheckout from "./views/SACheckout";
import SACheckin from "./views/SACheckin";
import SAReport from "./views/SAReport";
import RedirectReload from "./components/common/RedirectReload";

// help pages
import WelcomeHelp from './views/WelcomeHelp.js';
import ViewCertificateHelp from './views/ViewCertificateHelp.js';
import AddCertificateHelp from './views/AddCertificateHelp.js';
import RequestLicenseHelp from './views/RequestLicenseHelp.js';
import DeactivateLicenseHelp from './views/DeactivateLicenseHelp.js';
import RenewNemoHelp from './views/RenewNemoHelp.js';
import RenewStandaloneHelp from './views/RenewStandaloneHelp.js';
import RenewEesofHelp from './views/RenewEesofHelp.js';
import SyncLicenseHelp from './views/SyncLicenseHelp.js';
import ProductViewHelp from './views/ProductViewHelp.js';
import SoftwareUpdateHelp from './views/SoftwareUpdateHelp.js';
import EASummaryHelp from './views/EASummaryHelp.js';
import EARequestLicenseHelp from './views/EARequestLicenseHelp.js';
import HostConfigHelp from './views/HostConfigHelp.js';
import TrialLicenseHelp from './views/TrialLicenseHelp.js';
import TransportCCLHelp from "./views/TransportCCLHelp";
import EADetailedViewHelp from "./views/EADetailedViewHelp";
import EARequestAgainHelp from './views/EARequestAgainHelp.js';
import TransportKALHelp from "./views/TransportKALHelp";

// error pages
import PageNotFound from './views/PageNotFound.js';
import PageError from './views/PageError.js';

// components
import ProtectedRoute from './components/sso/ProtectedRoute.js';
import SSORedirect from './components/sso/SSORedirect.js';
import Header from './components/common/Header.js';
import Footer from './components/common/Footer.js';
import Spinner from './components/common/Spinner.js';
import Expired from './components/common/Expired.js';
import PublicExpired from "./components/common/PublicExpired";
import SACheckoutHelp from "./views/SACheckoutHelp";
import SACheckinHelp from "./views/SACheckinHelp";
import SADownloadHelp from "./views/SADownloadHelp";
import SAReportHelp from "./views/SAReportHelp";
import NotFoundRoute from "./components/sso/NotFoundRoute";
import TermsAndConditions from "./components/common/TermsAndConditions";

const defaultState = {
    loading: true
};

loadMessages(mainMessages['en'], 'en');


function App() {
    const [fontIsLoading, setFontIsLoading] = useState(defaultState.loading);
    const [accessToken, setAccessToken] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [sessionExpired, setSessionExpired] = useState(false);
    const [publicSessionExpired, setPublicSessionExpired] = useState(false);

    let localeInfo = getLocaleInfo();
    const agLocale = localeInfo['agLocale'];
    const [siteLanguageDefault] = useState('en');
    const [siteLanguage, setSiteLanguage] = useState(localeInfo['language']);

    const agrlCookie = getCookie('AGRL'); // kcom auth string
    const userCredentialsCookie = getCookie('USER_CREDENTIALS'); // kcom auth valid/invalid

    const [mainMenu, setMainMenu] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [lacFlag, setLacFlag] = useState('');
    const [paramAccess, setParamAccess] = useState('');
    const [restrictedFlag, setRestrictedFlag] = useState(false);
    const [showRestrictedPopup, setShowRestrictedPopup] = useState(false);

    const localAccessToken = "LOCAL_ACCESS_TOKEN";
    const localUserName = "LOCAL_USER";

    const helpLink = '/help'
    let location = useLocation();
    const history = useHistory();

    const graphik = new FontFaceObserver('Graphik LG Web');
    const graphikBold = new FontFaceObserver('Graphik LG Web', {style: 'bold'});


    // Axios Error Response Interceptor : 401
    // If token is invalid, send to refresh flow and retry request once
    let refreshingToken = null;
    axios.interceptors.response.use(undefined,
        async (error) => {
            console.log("error", error, error.config, error.response, error.status);
            const errorConfig = error.config;
            const retryCheck = error.response.config.url.includes(config.sso.JWT_REFRESH_URL)

            // Skip refresh token request, retry attempts to avoid infinite loops
            if (error.response && error.response.status === 401 && !retryCheck) {
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                };
                let data = {}
                if (lacFlag === "Y") {
                    data['lac_flag'] = lacFlag
                }
                refreshingToken = refreshingToken ?
                    refreshingToken :
                    axios.post(
                        config.sso.JWT_REFRESH_URL,
                        data,
                        {headers: headers, withCredentials: true}
                    )
                        .catch(() => {
                            console.log("ERROR: interceptor refresh has failed", error);

                            //anonymous access token pages
                            if (location.pathname.toLowerCase() === "/reactivate" ||
                                location.pathname.toLowerCase() === "/transport" ||
                                location.pathname.toLowerCase() === "/trial-license") {
                                setPublicSessionExpired(true)
                            } else {
                                setSessionExpired(true);
                            }
                            return Promise.reject(error);
                        })
                let res = await refreshingToken;
                refreshingToken = null;
                if (res) {
                    setAccessToken(res['data']['access']);
                    errorConfig.headers['Authorization'] = 'Bearer ' + res.data.access;
                }
                return axios.request(errorConfig);
            } else if (error.response && error.response.status === 401 && retryCheck) {
                console.log('Retry: Session time out')
                if (location.pathname.toLowerCase() === "/reactivate" ||
                    location.pathname.toLowerCase() === "/transport" ||
                    location.pathname.toLowerCase() === "/trial-license") {
                    setPublicSessionExpired(true)
                } else {
                    setSessionExpired(true);
                }
                return Promise.reject(error);
            } else {
                return Promise.reject(error);
            }
        })

    useEffect(() => {
        setFirstName(new URLSearchParams(window.location.search).get("first") || '');
        setLastName(new URLSearchParams(window.location.search).get("last") || '');
        setUserEmail(new URLSearchParams(window.location.search).get("username") || '');
        setLacFlag(new URLSearchParams(window.location.search).get("lacFlag") || '');
        setParamAccess(new URLSearchParams(window.location.search).get("access") || '');
    }, [])

    // global event listener will disconnect a session if started in another tab
    window.addEventListener('storage', (event) => {
        if (event.key === 'logout') {
            setAccessToken('');
            setUserName('');
            setUserEmail('');
        }
    });

    /*
     * logout() logs user out and sets access token to null
    */
    const logout = () => {
        window.localStorage.setItem('logout', Date.now());
        history.push('/sso-redirect');
    }

    // load fonts before page to prevent fout (flash of unstyled text)
    useEffect(() => {
        const timeout = 5000
        Promise.all([
            graphik.load(null, timeout),
            graphikBold.load(null, timeout)
        ])
            .catch((error) => {
                console.log("ERROR: Loading Graphik Font", error)
            })
            .finally(() => {
                setFontIsLoading(false)
            })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        /*
         * verify(paramAccess) verifies if the jwt from query string param is valid
         * @param {paramAccess} the json web token
        */
        const verify = () => {
            let headers = {'Content-Type': 'application/json'};
            let data = {'token': paramAccess};

            axios.post(
                config.sso.JWT_VERIFY_URL,
                data,
                {headers: headers}
            )
                .then((response) => {
                    // set access token with paramAccess from query string param
                    if (response.status === 200) {
                        setAccessToken(paramAccess);
                        setUserName(firstName + ' ' + lastName);
                        setParamAccess('')

                        if (mainMenu.length === 0) {
                            getMainMenu(paramAccess, true, true, false);
                        }

                        if (lacFlag === "Y") {
                            document.cookie = "AGRL=lacagrl; SameSite=None; Secure";
                            document.cookie = "USER_CREDENTIALS=VALID; SameSite=None; Secure";
                        }
                    }
                    const pathname = location.pathname.toLowerCase()
                    if (pathname !== '/') {
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    console.log("ERROR: JWT Verification", error);
                    setIsLoading(false);
                    logout();
                });
        }

        // handle initial page load with jwt in query string param
        if (!fontIsLoading && paramAccess) {
            // remove access token from query string param
            const location = window.location
            let url = location.pathname
            // keep all params that are not related to sso
            if (location.search !== '') {
                let params = location.search.slice(1).split("&")

                let paramsList = []
                params.forEach(param => {
                    if (param !== '') {
                        paramsList.push(param.split("="))
                    }
                })
                params = ''
                const ignore_params = ['access', 'first', 'last', 'username', 'lacFlag']
                paramsList.forEach(param => {
                    if (!ignore_params.includes(param[0])) {
                        params += '&' + param.join('=')
                    }
                })
                if (params !== '') {
                    url += '?' + params.slice(1)
                }
            }
            window.history.replaceState(null, null, url);
            // verify paramAccess
            verify()
        }
    }, [fontIsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        /*
         * slientRefresh(paramAccess) will call the refresh api to silently
         * refresh the jwt access token
         * @param {paramAccess} the jwt access token from params
         * @param {lifetime} the json web token's lifetime
        */
        const silentRefresh = (paramAccess) => {
            let decoded;

            try {
                decoded = jwtDecode(paramAccess);
            } catch (error) {
                console.log("ERROR: JWT Decode", error);
                logout();
            }

            // time until expiration = ((jwt unix time - 60s) * 1000) - current time
            let expiration = new Date((decoded.exp - 60) * 1000) - new Date();

            setTimeout(() => {
                refresh();
            }, expiration);

            return () => clearTimeout(expiration);
        }

        /*
         * refresh() refreshes the jwt once the jwt has expired
        */
        const refresh = () => {
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            };
            let data = {}
            if (lacFlag === "Y") {
                data['lac_flag'] = lacFlag
            }
            axios.post(
                config.sso.JWT_REFRESH_URL,
                data,
                {headers: headers, withCredentials: true}
            )
                .then((response) => {
                    if (response.status === 200 && response.data.access) {
                        setAccessToken(response.data.access);
                    }
                })
                .catch((error) => {
                    console.log("ERROR: JWT Refresh", error);
                    setSessionExpired(true);
                });
        }

        // handles subsequent page loads with access token in memory
        if (accessToken) {
            // start timer for access token's silent refresh
            silentRefresh(accessToken);
        }
    }, [accessToken]); // eslint-disable-line react-hooks/exhaustive-deps


    // useEffect(() => {
    //     console.log("app language", siteLanguage, siteLanguageDefault);
    // }, [siteLanguage, siteLanguageDefault]);

    function getMainMenu(accessToken, header, getItarPrompt = false, acceptItarPrompt = false) {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        if (!header) {
            setIsLoading(true)
        }

        axios.get(
            config.welcome_screen.CARDS_ENDPOINT,
            {headers: headers, params: {header: header}}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];

                    if (data.length === 0) {
                        history.push("add-certificate");
                    } else {
                        data.forEach((card, cardIndex) => {
                            // remove all zwsp in title
                            const title = card["TITLE"].replace(/[\u200B-\u200D\uFEFF]/g, '');
                            card.icon = tileHeader[title].icon
                            card.titleKey = tileHeader[title].titleKey
                            // remove all zwsp in submenus
                            card["SUBMENU"].forEach((item) => {
                                item.title = item["TITLE"].replace(/[\u200B-\u200D\uFEFF]/g, '');
                                if (item.hasOwnProperty('DISPLAY_VALUE')) {
                                    item.displayValue = item["DISPLAY_VALUE"].toString()
                                }

                                if (item.hasOwnProperty('ACTIVE_AGREEMENTS')) {
                                    item.activeValue = item["ACTIVE_AGREEMENTS"].toString()
                                }

                                if (item.hasOwnProperty('EXPIRED_AGREEMENTS')) {
                                    item.expiredValue = item['EXPIRED_AGREEMENTS'].toString()
                                }

                                // format submenu based on language
                                item.actionLink = linkMappings[item.title].actionLink
                                if (item.actionLink === '') {
                                    item.actionLink = item["ACTION_LINK"]
                                    if (lacFlag === 'Y') {
                                        item.actionLink = '/'
                                    }
                                }

                                item.tooltipKey = linkMappings[item.title].tooltipKey
                                item.titleKey = linkMappings[item.title].subMenuTitleKey
                            });
                        })

                        // remove cards that are spelled incorrectly
                        setMainMenu(data);

                        //get ITAR prompts
                        if (getItarPrompt) {
                            getAcknowledgement(accessToken);
                        }

                        //close itar prompt
                        if (acceptItarPrompt) {
                            setShowRestrictedPopup(false);
                        }

                        if (!getItarPrompt && !header) {
                            setIsLoading(false)
                        }
                    }
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Main Menu Data", error);
                if (acceptItarPrompt) {
                    setShowRestrictedPopup(false);
                }
                if (!header) {
                    history.push('/error');
                    setIsLoading(false)
                }
            })
    }

    // get itar acknowledgement prompt info
    const [prompt, setPrompt] = useState({});

    function getAcknowledgement(paramAccess) {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + paramAccess
        };
        axios.get(
            config.prompts.GET_PROMPTS + '?prompt_name=ITAR-EXTERNAL',
            {headers: headers}
        )
            .then((response) => {
                let data = response.data || [];
                if (Object.keys(data).length !== 0) {
                    setPrompt(data[0]);
                    let decoded = jwtDecode(paramAccess);
                    // User has restricted entitlements but has not accepted popup
                    if (decoded?.restricted_popup === "TRUE") {
                        setShowRestrictedPopup(true);
                    }
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Terms and Conditions Acknowledgement", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    //get updated/cached main menu data
    useEffect(() => {
        const pathname = location.pathname.toLowerCase()
        if (pathname !== '/' &&
            pathname !== '/sign-in' &&
            pathname !== '/sso-redirect' &&
            pathname !== '/error'
        ) {
            if (process.env.REACT_APP_LOCAL) {
                getMainMenu(localAccessToken, true);
            } else {
                if (accessToken) {
                    getMainMenu(accessToken, true);
                }
            }
        }
    }, [location, accessToken]);

    // Grab ITAR Flag from access token and set to control flag
    useEffect(() => {
        try {
            if (accessToken) {
                let decoded = jwtDecode(accessToken);
                // User has restricted entitlements but has not accepted popup
                if (decoded?.restricted_popup === "TRUE") setRestrictedFlag(true)
            }
        } catch (error) {
            console.log("ERROR: JWT Decode", error);
        }
    }, [accessToken]);

    if ((paramAccess && isLoading) || fontIsLoading) {
        return (
            <Spinner/>
        );
    }

    return (
        <UserContext.Provider
            value={{
                userName: process.env.REACT_APP_LOCAL ? localUserName : userName,
                userEmail: userEmail,
                agLocale: agLocale,
                agrlCookie: agrlCookie,
                userCredentialsCookie: userCredentialsCookie,
                siteLanguage: siteLanguage,
                siteLanguageDefault: siteLanguageDefault,
                lacFlag: lacFlag,
                accessToken: process.env.REACT_APP_LOCAL ? localAccessToken : accessToken,
                setAccessToken: setAccessToken,
                timeout: 3 * 60 * 1000 - 500, //3 minutes - 500 ms
                mainMenu: mainMenu,
                restrictedFlag,
                setRestrictedFlag,
                setShowRestrictedPopup,
            }}
        >
            <LocalizationProvider language={siteLanguage}>
                {sessionExpired && <Expired/>}
                {publicSessionExpired && <PublicExpired/>}
                {showRestrictedPopup && <TermsAndConditions
                    prompt={prompt}
                    setRestrictedFlag={setRestrictedFlag}
                    setShowRestrictedPopup={setShowRestrictedPopup}
                    setAccessToken={setAccessToken}
                    lacFlag={lacFlag}
                    getMainMenu={getMainMenu}
                    setIsLoading={setIsLoading}
                />}
                <Header setSiteLanguage={setSiteLanguage}/>
                <Switch>

                    {/*General Pages*/}
                    <ProtectedRoute
                        exact={true}
                        path='/'
                        component={Welcome}
                        getMainMenu={getMainMenu}
                        isLoading={isLoading}
                    />
                    <Route
                        exact={true}
                        path='/help'
                        render={(props) => (
                            <WelcomeHelp {...props}/>
                        )}
                    />
                    <Route
                        exact={true}
                        path='/sign-in'
                        render={(props) => (
                            <SignIn {...props}/>
                        )}
                    />
                    <Route
                        exact={true}
                        path='/sign-in/help'
                        render={(props) => (
                            <WelcomeHelp {...props}/>
                        )}
                    />
                    <Route
                        exact={true}
                        path='/sso-redirect'
                        render={(props) => (
                            <SSORedirect {...props}/>
                        )}
                    />
                    <Route
                        exact={true}
                        path='/error'
                        render={(props) => (
                            <PageError {...props}/>
                        )}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={'/reload'}
                        component={RedirectReload}
                    />

                    {/*ENTITLEMENT MANAGEMENT*/}
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['Add New Certificate'].actionLink}
                        component={AddCertificate}
                        getMainMenu={getMainMenu}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['Add New Certificate'].actionLink + helpLink}
                        render={(props) => (
                            <AddCertificateHelp {...props}/>
                        )}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['View My Certificates'].actionLink}
                        component={ViewCertificate}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['View My Certificates'].actionLink + helpLink}
                        render={(props) => (
                            <ViewCertificateHelp {...props}/>
                        )}
                    />

                    {/*LICENSE MANAGEMENT*/}
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['Request New Licenses'].actionLink}
                        component={RequestLicense}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['Request New Licenses'].actionLink + helpLink}
                        render={(props) => (
                            <RequestLicenseHelp {...props}/>
                        )}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['Deactivate Licenses'].actionLink}
                        component={DeactivateLicense}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['Deactivate Licenses'].actionLink + helpLink}
                        render={(props) => (
                            <DeactivateLicenseHelp {...props}/>
                        )}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['Sync Licenses'].actionLink}
                        component={SyncLicense}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['Sync Licenses'].actionLink + helpLink}
                        render={(props) => (
                            <SyncLicenseHelp {...props}/>
                        )}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['Renew Support For My Nemo Licenses'].actionLink}
                        component={RenewNemo}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['Renew Support For My Nemo Licenses'].actionLink + helpLink}
                        render={(props) => (
                            <RenewNemoHelp {...props}/>
                        )}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['Renew Support For My Standalone Software Subscriptions'].actionLink}
                        component={RenewStandalone}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['Renew Support For My Standalone Software Subscriptions'].actionLink + helpLink}
                        render={(props) => (
                            <RenewStandaloneHelp {...props}/>
                        )}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['Renew My EESOF EDA Licenses'].actionLink}
                        component={RenewEesof}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['Renew My EESOF EDA Licenses'].actionLink + helpLink}
                        render={(props) => (
                            <RenewEesofHelp {...props}/>
                        )}
                    />

                    {/*SOFTWARE UPDATES*/}
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['Download Software Updates'].actionLink}
                        component={SoftwareUpdate}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['Download Software Updates'].actionLink + '-detail'}
                        component={SoftwareUpdateDetail}
                    />
                    <ProtectedRoute
                        exact={true}
                        path='/request-update-licenses'
                        component={RequestUpdateLicenses}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['Download Software Updates'].actionLink + helpLink}
                        render={(props) => (
                            <SoftwareUpdateHelp {...props}/>
                        )}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['Download Software Updates'].actionLink + '-detail' + helpLink}
                        render={(props) => (
                            <Redirect to={linkMappings['Download Software Updates'].actionLink + helpLink + '#update'}/>
                        )}
                    />
                    <Route
                        exact={true}
                        path='/request-update-licenses/help'
                        render={(props) => (
                            <Redirect to={linkMappings['Download Software Updates'].actionLink + helpLink + '#update'}/>
                        )}
                    />

                    {/*MY LICENSES & HOSTS*/}
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['View Licenses by Product or Host'].actionLink}
                        component={ProductView}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['View Licenses by Product or Host'].actionLink + helpLink}
                        render={(props) => (
                            <ProductViewHelp {...props}/>
                        )}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['View Expiring Licenses and Support'].actionLink}
                        component={ExpiringLicenseSupportView}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['Host Management'].actionLink}
                        component={HostConfig}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['Host Management'].actionLink + helpLink}
                        render={(props) => (
                            <HostConfigHelp {...props}/>
                        )}
                    />

                    {/*SOFTWARE ACCESS LICENSES*/}
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['Check Out Licenses'].actionLink}
                        component={SACheckout}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['Check Out Licenses'].actionLink + helpLink}
                        render={(props) => (
                            <SACheckoutHelp {...props}/>
                        )}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['Check In Licenses'].actionLink}
                        component={SACheckin}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['Check In Licenses'].actionLink + helpLink}
                        render={(props) => (
                            <SACheckinHelp {...props}/>
                        )}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['View/Download Licenses'].actionLink}
                        component={SADownload}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['View/Download Licenses'].actionLink + helpLink}
                        render={(props) => (
                            <SADownloadHelp {...props}/>
                        )}
                    />
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['Software Usage Report'].actionLink}
                        component={SAReport}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['Software Usage Report'].actionLink + helpLink}
                        render={(props) => (
                            <SAReportHelp {...props}/>
                        )}
                    />

                    {/*ENTERPRISE AGREEMENTS*/}
                    <ProtectedRoute
                        exact={true}
                        path={linkMappings['Manage My Enterprise Agreements'].actionLink}
                        component={EASummary}
                    />
                    <Route
                        exact={true}
                        path={linkMappings['Manage My Enterprise Agreements'].actionLink + helpLink}
                        render={(props) => (
                            <EASummaryHelp {...props}/>
                        )}
                    />
                    <ProtectedRoute
                        exact={true}
                        path='/ea-detailed-view'
                        component={EADetailedView}
                    />
                    <Route
                        exact={true}
                        path='/ea-detailed-view/help'
                        render={(props) => (
                            <EADetailedViewHelp {...props}/>
                        )}
                    />
                    <ProtectedRoute
                        exact={true}
                        path='/ea-request-license'
                        component={EARequestLicense}
                    />
                    <Route
                        exact={true}
                        path='/ea-request-license/help'
                        render={(props) => (
                            <EARequestLicenseHelp {...props}/>
                        )}
                    />
                    <ProtectedRoute
                        exact={true}
                        path='/ea-request-again'
                        component={EARequestAgain}
                    />
                    <Route
                        exact={true}
                        path='/ea-request-again/help'
                        render={(props) => (
                            <EARequestAgainHelp {...props}/>
                        )}
                    />

                    {/*Anonymous Pages*/}
                    <Route
                        exact={true}
                        path='/trial-license'
                        component={TrialLicense}
                    />
                    <Route
                        exact={true}
                        path='/trial-license/help'
                        render={(props) => (
                            <TrialLicenseHelp {...props}/>
                        )}
                    />
                    <Route
                        exact={true}
                        path='/reactivate'
                        render={(props) => (
                            <TransportKAL {...props}/>
                        )}
                    />
                    <Route
                        exact={true}
                        path='/reactivate/help'
                        render={(props) => (
                            <TransportKALHelp {...props}/>
                        )}
                    />
                    <Route
                        exact={true}
                        path='/transport'
                        render={(props) => (
                            <TransportCCL {...props}/>
                        )}
                    />
                    <Route
                        exact={true}
                        path='/transport/help'
                        render={(props) => (
                            <TransportCCLHelp {...props}/>
                        )}
                    />
                    {/*Default non page found*/}
                    <NotFoundRoute/>
                </Switch>
                <Footer/>
            </LocalizationProvider>
        </UserContext.Provider>
    );
}

export default App;