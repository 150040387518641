import React from 'react';

// kendo react
import {CloseIcon, GearsIcon, InfoIcon, SuccessIcon, WarningErrorIcon} from "./icons";

/**
 * returns styled success, error, and warning alerts
 * @param props
 *      type: success, error, or warning
 *      showHandler: if passed, close button is shown and showHandler will be called on close button onClick.
 *      this custom method should change the show state of the Alert message.
 *      title: bolded top text
 *      message: body text
 * @returns {JSX.Element}
 * @constructor
 */
function Alert(props) {
    let {
        type,
        showHandler,
        title,
        message
    } = props;

    let color;
    let background;
    let icon;
    let fontSize;

    switch (type) {
        case 'success':
            color = 'var(--color-alert-success-dark)';
            background = 'var(--color-alert-success-light)';
            icon = <SuccessIcon size={'large'} style={{
                'color': 'var(--color-alert-success-base)'
            }}/>;
            break;
        case 'error':
            color = 'var(--color-alert-danger-dark)';
            background = 'var(--color-alert-danger-light)';
            icon = <WarningErrorIcon size={'large'} style={{
                'color': 'var(--color-alert-danger-base)'
            }}/>;
            break;
        case 'error2':
            color = 'var(--color-alert-danger-dark)';
            background = 'var(--color-alert-danger-light)';
            icon = <WarningErrorIcon size={'large'} style={{
                'color': 'var(--color-alert-danger-base)'
            }}/>;
            break;
        case 'warning':
            color = 'var(--color-alert-warning-dark)';
            background = 'var(--color-alert-warning-light)';
            icon = <WarningErrorIcon size={'large'} style={{
                'color': 'var(--color-alert-warning-base)'
            }}/>;
            break;
        case 'system':
            color = 'var(--color-alert-warning-dark)';
            background = 'var(--color-alert-warning-light)';
            icon = <GearsIcon size={'xlarge'} style={{
                'color': 'var(--color-alert-warning-base)'
            }}/>;
            break;
        default:
            color = 'var(--keysight-dark-purple)';
            background = 'var(--keysight-light-purple)';
            icon = <InfoIcon size={'large'} style={{
                'color': 'var(--keysight-purple)'
            }}/>;
    }

    return (
        <>
            <div
                style={{
                    background: background,
                    color: color,
                    border: 'none',
                    borderRadius: '0.5rem',
                    display: 'flex',
                    padding: '1rem',
                    width: 'auto',
                }}
            >
                <div>
                    {icon}
                </div>
                <div
                    style={{
                        'alignItems': 'center',
                        'display': 'flex',
                        'wordBreak': 'normal',
                        'width': '100%'
                    }}
                >
                    <div>
                        <div
                            style={{
                                'marginBottom': '0.25rem',
                                'marginLeft': '1rem',
                                'marginRight': '1rem',
                                'fontWeight': '600',
                                'fontSize': 'var(--kendo-font-size, inherit)'
                            }}
                        >
                            {title}
                        </div>
                        <div
                            style={{
                                'marginLeft': '1rem',
                                'marginRight': '1rem',
                                'fontSize': 'var(--kendo-font-size, inherit)'
                            }}
                        >
                            {message}
                        </div>
                    </div>
                </div>
                {showHandler && (
                    <div
                        style={{
                            alignSelf: 'start',
                            cursor: 'pointer'
                        }}
                    >
                        <CloseIcon
                            size={"medium"}
                            onClick={showHandler}
                            style={{cursor: 'pointer'}}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default Alert;